import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { setGameMode } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { isBattleBonusMode } from '../../utils';
import ViewContainer from '../components/container';
import {
  BATTLE_BNS_CHARA_ICON_MAX,
  REELS_FRAME_ADJUSTMENT,
  SLOTS_BB_ICON,
  SLOTS_BB_ICON_BLANK,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  eventManager,
} from '../config';

class BattleBonusCharaWindow extends ViewContainer {
  private bbiconBlankTex = PIXI.Texture.from(ResourceTypes.bBiconBlank);

  private bbiconBlank: PIXI.Sprite;

  private bbIcon: PIXI.Sprite[] = [];

  constructor() {
    super();
    this.bbiconBlank = this.initBbiconBlank();
    this.bbIcon = this.initBbicon();

    this.addChild(this.bbiconBlank);
    for (let i = 0; i < BATTLE_BNS_CHARA_ICON_MAX; i++) {
      this.addChild(this.bbIcon[i]);
    }

    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
    eventManager.on(EventTypes.BATTLE_BONUS_SET_ICON, (round, icon) => {
      this.setBattleIcon(round, icon);
    });
    eventManager.on(EventTypes.BATTLE_BONUS_ROUND1, () => {
      this.battleBonusRound();
    });
  }

  private onModeChange(settings: { mode: GameMode }): void {
    if (isBattleBonusMode(settings.mode)) {
      this.bbiconBlank.visible = true;
    } else {
      this.bbiconBlank.visible = false;
      this.resetBbIcon();
    }
  }

  private battleBonusRound(): void {
    if (isBattleBonusMode(setGameMode())) {
      this.resetBbIcon();
    }
  }

  private resetBbIcon(): void {
    for (let i = 0; i < BATTLE_BNS_CHARA_ICON_MAX; i++) {
      this.bbIcon[i].texture = this.bbiconBlankTex;
      this.bbIcon[i].visible = false;
    }
  }

  private initBbiconBlank(): PIXI.Sprite {
    const bbIconBlank = new PIXI.Sprite(this.bbiconBlankTex);
    bbIconBlank.anchor.set(0.5, 1);
    bbIconBlank.position.set(SLOTS_CONTAINER_WIDTH / 2, SLOTS_BB_ICON_BLANK);
    bbIconBlank.zIndex = 10;
    bbIconBlank.visible = false;

    return bbIconBlank;
  }

  private initBbicon(): PIXI.Sprite[] {
    const bbIcon: PIXI.Sprite[] = [];
    for (let i = 0; i < BATTLE_BNS_CHARA_ICON_MAX; i++) {
      bbIcon[i] = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.bBiconRivalattack));
      bbIcon[i].anchor.set(0.5, 1);
      bbIcon[i].position.set(i * 242 + 130, SLOTS_BB_ICON);
      bbIcon[i].zIndex = 10;
      bbIcon[i].visible = false;
    }
    return bbIcon;
  }

  private setPosition(frame: PIXI.Sprite): void {
    frame.anchor.set(0.5, 1);
    frame.position.set(
      SLOTS_CONTAINER_WIDTH / 2, //
      SLOTS_CONTAINER_HEIGHT + REELS_FRAME_ADJUSTMENT,
    );
    frame.zIndex = 10;
  }

  private setBattleIcon(round: number, icon: ResourceTypes) {
    // const index = setCurrentBonus().currentRound;
    // console.log('setBattleIcon round', round, 'icon', icon);
    this.bbIcon[round].texture = PIXI.Texture.from(icon);
    this.bbIcon[round].visible = true;
  }
}

export default BattleBonusCharaWindow;
