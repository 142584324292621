import _ from 'lodash';
import * as PIXI from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import SlotMachine from '..';
import { ISongs, audioSpriteVolume, mappedAudioSprites } from '../../config';
import { EventTypes, ISettledBet } from '../../global.d';
import { setBetAmount, setCurrency, setGameMode, setIsBigWinAnim, setIsDuringBigWinLoop } from '../../gql/cache';
import BgmControl from '../../slotMachine/bgmControl/bgmControl';
import { formatNumber, isBaseGameMode, isBattleBonusMode, normalizeCoins, showCurrency } from '../../utils';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import {
  APPLICATION_FPS,
  BASE_WIN_AMOUNT_LIMIT,
  BASE_WIN_COUNT_UP_MULTIPLIER,
  BASE_WIN_TITLE_SCALE,
  BATTLE_BONUS_WIN_COUNT_UP_MULTIPLIER,
  BIG_WIN_AMOUNT_LIMIT,
  BIG_WIN_COUNT_UP_MULTIPLIER,
  BIG_WIN_END_DURATION,
  BIG_WIN_TITLE_SCALE,
  DOUBLE_WIN_AMOUNT_LIMIT,
  GREAT_WIN_AMOUNT_LIMIT,
  MAXIMUM_FRACTION_DIGITS,
  MEGA_WIN_AMOUNT_LIMIT,
  MINIMUM_FRACTION_DIGITS,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  WIN_CHECK_TIME_DURATION_ON_FREE_SPIN,
  WinStages,
  Z_INDEX_WIN_COUNT_UP_MESSAGE,
  eventManager,
  winValueStyles,
} from '../config';

class WinCountUpMessage extends PIXI.Container {
  public winValue = 0.0;

  public winCountUpAnimation: Animation | null = null;

  private winTitle = new PIXI.Text(
    this.winValue.toLocaleString('en-EN', {
      minimumFractionDigits: MINIMUM_FRACTION_DIGITS,
      maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
    }),
    winValueStyles,
  );

  constructor() {
    super();
    this.winTitle.resolution = 1;
    this.winTitle.y = SLOTS_CONTAINER_HEIGHT - 100;
    this.winTitle.x = SLOTS_CONTAINER_WIDTH / 2;
    this.winTitle.anchor.set(0.5, 0.5);
    this.winTitle.visible = false;
    this.addChild(this.winTitle);
    eventManager.addListener(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION, this.skipWinCountUpAnimation.bind(this));
    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.startWinAnimation.bind(this));
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.onStartSpinAnimation.bind(this));
    eventManager.addListener(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE, this.hideWinCountUpMessage.bind(this));

    eventManager.addListener(EventTypes.WIN_TITLE_DISABLE, this.winTitleDisable.bind(this));
    this.zIndex = Z_INDEX_WIN_COUNT_UP_MESSAGE;
  }

  private hideWinCountUpMessage(): void {
    this.winTitle.visible = false;
  }

  private onStartSpinAnimation(): void {
    this.winCountUpAnimation?.skip();
  }

  private startWinAnimation(nextResult: ISettledBet): void {
    this.createWinAnimation(nextResult).start();
  }

  private skipWinCountUpAnimation() {
    this.winCountUpAnimation?.skip();
  }

  public createWinAnimation(nextResult: ISettledBet): Animation {
    const betAmount = normalizeCoins(setBetAmount());
    const winAmount = normalizeCoins(nextResult.bet.result.winCoinAmount);
    const stage = this.countStage(betAmount, winAmount);

    const animationChain = new AnimationChain({
      proceedNextAnimationOnSkip: true,
    });
    if (stage <= WinStages.BaseWin) {
      const baseWinAnimation = this.createBaseWinAnimation(winAmount, betAmount);
      baseWinAnimation.addOnStart(() => {
        AudioHowl.play({ type: ISongs.Win_Loop, stopPrev: true });
      });
      baseWinAnimation.addOnComplete(() => {
        AudioHowl.stop({ type: ISongs.Win_Loop });
        AudioHowl.play({ type: ISongs.Win_End, stopPrev: true });
      });
      baseWinAnimation.addOnSkip(() => {
        this.setWinValue(winAmount);
        AudioHowl.stop({ type: ISongs.Win_Loop });
        AudioHowl.play({ type: ISongs.Win_End, stopPrev: true });
      });
      animationChain.appendAnimation(baseWinAnimation);
    } else {
      animationChain.addOnStart(() => {
        eventManager.emit(EventTypes.SHOW_COINS);
      });
      animationChain.addOnSkip(() => {
        eventManager.emit(EventTypes.HIDE_COINS);
      });
      animationChain.addOnComplete(() => {
        eventManager.emit(EventTypes.HIDE_COINS);
      });
      if (stage >= WinStages.BigWin) {
        const bigWinAnimationGroup = new AnimationGroup();
        const bigWinAnimation = this.createBigWinAnimation(winAmount, betAmount, stage);
        const bigWinBgmChain = new AnimationChain();
        const bigWinStartDelayAnimation = Tween.createDelayAnimation(mappedAudioSprites[ISongs.Win_Loop].duration);
        bigWinStartDelayAnimation.addOnComplete(() => {
          if (isBaseGameMode(setGameMode())) {
            // TODO
          }
        });

        bigWinBgmChain.addOnComplete(() => {
          setIsDuringBigWinLoop(true);
          AudioHowl.play({ type: ISongs.Win_Loop, stopPrev: true });
        });

        bigWinBgmChain.addOnSkip(() => {
          if (isBaseGameMode(setGameMode())) {
            // TODO
          }
          setIsDuringBigWinLoop(true);
          AudioHowl.play({ type: ISongs.Win_Loop, stopPrev: true });
        });

        bigWinBgmChain.appendAnimation(bigWinStartDelayAnimation);

        bigWinAnimationGroup.addAnimation(bigWinAnimation);
        bigWinAnimationGroup.addAnimation(bigWinBgmChain);
        animationChain.appendAnimation(bigWinAnimationGroup);
      }
    }
    if (stage > 1) {
      const bigWinEndDelay = Tween.createDelayAnimation(mappedAudioSprites[ISongs.Win_End].duration);
      bigWinEndDelay.addOnStart(() => {
        setIsDuringBigWinLoop(false);
        AudioHowl.stop({ type: ISongs.Win_Loop });
      });
      bigWinEndDelay.addOnSkip(() => {});
      bigWinEndDelay.addOnComplete(() => {});
      animationChain.appendAnimation(bigWinEndDelay);
    } else {
      const winEndDelay = Tween.createDelayAnimation(WIN_CHECK_TIME_DURATION_ON_FREE_SPIN);
      animationChain.appendAnimation(winEndDelay);
    }
    if (!isBattleBonusMode(setGameMode())) {
      const fadeOutAnimation = new Tween({
        propertyBeginValue: 1,
        target: 0,
        object: this.winTitle,
        // eslint-disable-next-line no-restricted-properties
        easing: (n) => Math.pow(n, 8),
        property: TweenProperties.ALPHA,
        duration: BIG_WIN_END_DURATION,
      });

      fadeOutAnimation.addOnStart(() => {
        eventManager.emit(EventTypes.HANDLE_START_FADE_ANIMATION, stage);
        eventManager.emit(EventTypes.COUNT_UP_END);
      });
      fadeOutAnimation.addOnSkip(() => {
        eventManager.emit(EventTypes.HANDLE_SKIP_FADE_ANIMATION);
      });
      animationChain.appendAnimation(fadeOutAnimation);
    } else {
      const fadeOutAnimation = new Tween({
        propertyBeginValue: 1,
        target: 1,
        object: this.winTitle,
        // eslint-disable-next-line no-restricted-properties
        easing: (n) => Math.pow(n, 8),
        property: TweenProperties.ALPHA,
        duration: 1,
      });

      fadeOutAnimation.addOnStart(() => {
        eventManager.emit(EventTypes.HANDLE_START_FADE_ANIMATION, stage);
        eventManager.emit(EventTypes.COUNT_UP_END);
      });
      fadeOutAnimation.addOnSkip(() => {
        eventManager.emit(EventTypes.HANDLE_SKIP_FADE_ANIMATION);
      });
      animationChain.appendAnimation(fadeOutAnimation);
    }
    animationChain.addOnStart(() => {
      if (winAmount > 0) {
        this.winTitle.alpha = 1;
        this.winTitle.visible = true;
      }
    });
    animationChain.addOnComplete(() => {
      eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
      eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
      eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
      eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
      eventManager.emit(EventTypes.SKIP_ALL_WIN_ANIMATIONS);
      this.clean();
    });
    animationChain.addOnSkip(() => {
      this.clean();
      eventManager.emit(EventTypes.SKIP_ALL_WIN_ANIMATIONS);
    });
    this.winCountUpAnimation = animationChain;
    return animationChain;
  }

  private winTitleDisable(): void {
    this.winTitle.visible = false;
    this.winTitle.scale.set(1, 1);
    this.setWinValue(0);
  }

  private clean(): void {
    setIsDuringBigWinLoop(false);
    AudioHowl.stop({ type: ISongs.Win_Loop });

    if (!isBattleBonusMode(setGameMode())) {
      this.winTitle.visible = false;
      this.winTitle.scale.set(1, 1);
      this.setWinValue(0);
    }
    this.winCountUpAnimation = null;
  }

  private createBaseWinAnimation(win: number, bet: number): Animation {
    const baseWinAnimation = new AnimationGroup({});
    let duration = 0;
    let countUpAnimation;
    if (isBattleBonusMode(setGameMode())) {
      duration = (win / bet / (BATTLE_BONUS_WIN_COUNT_UP_MULTIPLIER * APPLICATION_FPS)) * 1000;
      countUpAnimation = new Tween({
        propertyBeginValue: 0,
        target: win,
        object: this,
        property: TweenProperties.WIN_VALUE,
        update: this.setWinValue.bind(this),
        duration,
      });
    } else {
      duration = (win / bet / (BASE_WIN_COUNT_UP_MULTIPLIER * APPLICATION_FPS)) * 1000;
      countUpAnimation = new Tween({
        propertyBeginValue: 0,
        target: Math.min(win, bet * BASE_WIN_AMOUNT_LIMIT),
        object: this,
        property: TweenProperties.WIN_VALUE,
        update: this.setWinValue.bind(this),
        duration,
      });
    }
    const scaleXAnimation = new Tween({
      object: this.winTitle.scale,
      propertyBeginValue: 1,
      target: BASE_WIN_TITLE_SCALE,
      property: TweenProperties.X,
      duration,
    });
    const scaleYAnimation = new Tween({
      object: this.winTitle.scale,
      propertyBeginValue: 1,
      target: BASE_WIN_TITLE_SCALE,
      property: TweenProperties.Y,
      duration,
    });
    baseWinAnimation.addAnimation(scaleXAnimation);
    baseWinAnimation.addAnimation(scaleYAnimation);
    baseWinAnimation.addAnimation(countUpAnimation);
    return baseWinAnimation;
  }

  private createBigWinAnimation(win: number, bet: number, stage: WinStages): Animation {
    const bigWinAnimation = new AnimationChain();
    const countUpAnimationGroup = new AnimationGroup({});
    bigWinAnimation.addOnStart(() => {
      BgmControl.fadeOutAll(1000);
      setIsBigWinAnim(true);
      if (SlotMachine.getInstance().getReSpinBonus()) {
        AudioHowl.fadeOut(100, ISongs.RP_SE_Loop);
      }
      AudioHowl.play({ type: ISongs.BGM_BigWinCountLoop, stopPrev: true });
      eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, true);
    });
    bigWinAnimation.addOnSkip(() => {
      AudioHowl.fadeOut(500, ISongs.BGM_BigWinCountLoop);
      if (SlotMachine.getInstance().getReSpinBonus()) {
        this.rpSeLoopPlay();
      }
      BgmControl.fadeInBase(3000);
      if (stage > WinStages.BigWin) {
        eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
      }
    });
    bigWinAnimation.addOnComplete(() => {
      AudioHowl.fadeOut(500, ISongs.BGM_BigWinCountLoop);
      BgmControl.fadeInBase(3000);
      if (stage > WinStages.BigWin) {
        eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
      }
    });
    const duration =
      (Math.min(win / bet, BIG_WIN_AMOUNT_LIMIT) / (BIG_WIN_COUNT_UP_MULTIPLIER * APPLICATION_FPS)) * 1000;
    const countUpAnimation = new Tween({
      propertyBeginValue: 0,
      target: Math.min(win, bet * BIG_WIN_AMOUNT_LIMIT),
      object: this,
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration,
    });
    countUpAnimation.addOnSkip(() => {
      setIsBigWinAnim(false);
      this.setWinValue(Math.min(win, bet * BIG_WIN_AMOUNT_LIMIT));
    });
    countUpAnimation.addOnComplete(() => {
      setIsBigWinAnim(false);
      if (SlotMachine.getInstance().getReSpinBonus()) {
        this.rpSeLoopPlay();
      }
    });
    const scaleXAnimation = new Tween({
      object: this.winTitle.scale,
      propertyBeginValue: 1,
      target: BIG_WIN_TITLE_SCALE,
      property: TweenProperties.X,
      duration,
    });
    const scaleYAnimation = new Tween({
      object: this.winTitle.scale,
      propertyBeginValue: 1,
      target: BIG_WIN_TITLE_SCALE,
      property: TweenProperties.Y,
      duration,
    });
    countUpAnimationGroup.addAnimation(scaleXAnimation);
    countUpAnimationGroup.addAnimation(scaleYAnimation);
    countUpAnimationGroup.addAnimation(countUpAnimation);
    bigWinAnimation.appendAnimation(countUpAnimationGroup);
    return bigWinAnimation;
  }

  private rpSeLoopPlay(): void {
    if (AudioHowl.isPlaying(ISongs.RP_SE_Loop)) {
      AudioHowl.fadeIn(100, ISongs.RP_SE_Loop, audioSpriteVolume[ISongs.RP_SE_Loop]);
    } else {
      AudioHowl.play({ type: ISongs.RP_SE_Loop });
    }
  }

  private countStage(bet: number, win: number): WinStages {
    const multiplier = win / bet;

    if (isBattleBonusMode(setGameMode())) {
      return WinStages.None;
    }

    if (multiplier < DOUBLE_WIN_AMOUNT_LIMIT) {
      return WinStages.None;
    }
    if (multiplier >= DOUBLE_WIN_AMOUNT_LIMIT && multiplier < BASE_WIN_AMOUNT_LIMIT) {
      return WinStages.BaseWin;
    }
    if (multiplier >= BASE_WIN_AMOUNT_LIMIT && multiplier < BIG_WIN_AMOUNT_LIMIT) {
      return WinStages.BigWin;
    }
    if (multiplier >= BIG_WIN_AMOUNT_LIMIT && multiplier < MEGA_WIN_AMOUNT_LIMIT) return WinStages.MegaWin;
    if (multiplier >= MEGA_WIN_AMOUNT_LIMIT && multiplier < GREAT_WIN_AMOUNT_LIMIT) return WinStages.GreatWin;
    return WinStages.EpicWin;
  }

  public setWinValue(winValue: number): void {
    this.winValue = winValue < 0 ? 0 : winValue;
    this.winTitle.text = `${formatNumber(setCurrency(), winValue, showCurrency(setCurrency()))}`;
  }
}

export default WinCountUpMessage;
