import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';

import AudioHowl from '@phoenix7dev/play-music';
import { Loader, ProgressBar } from '@phoenix7dev/shared-components';

import {
  LOADER_SPRITE_TEXTURES,
  LOADER_TEXTURES,
  SPINE_LOADER_TEXTURES,
  audioSprite,
  audioSpriteVolume,
} from '../../config';
import {
  BonusStatus,
  EventTypes,
  GameMode,
  IAuthInput,
  IBonus,
  ISettledBet,
  IUserBalance,
  UserBonus,
  reelSets,
} from '../../global.d';
import {
  setBattleBonusRounds,
  setBetAmount,
  setBonuses,
  setBrokenGame,
  setBrokenGameReelSet,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setCurrentBonusId,
  setCurrentFreeSpinsTotalWin,
  setGameMode,
  setIsAuthorized,
  setIsMobile,
  setIsSoundOn,
  setIsSuspended,
  setPrevReelsPosition,
  setProgress,
  setReelSetId,
  setSkipIntroScreen,
  setSlotConfig,
  setUserLastBetResult,
  setWinAmount,
} from '../../gql/cache';
import client, { webSocketClient } from '../../gql/client';
import { IConfig, ISlotHistoryData } from '../../gql/d';
import { authGql } from '../../gql/mutation';
import {
  ReelSetType,
  configGql,
  getBonuses,
  getProgressGql,
  getSlotGql,
  getUserBonuses,
  getUserGql,
  slotBetGql,
  slotHistoryGql,
  userBonusBetsGql,
} from '../../gql/query';
import { ResourceTypes } from '../../resources.d';
import { eventManager } from '../../slotMachine/config';
import { ISlotData } from '../../slotMachine/d';
import {
  findSubstituteCoinAmount,
  getGameModeByReelSetId,
  isFreeSpinsMode,
  isMobileDevice,
  isReSpinMode,
  loadPixiAssets,
  parseQuery,
  wait,
} from '../../utils';
import Resources from '../../utils/resources';

import styles from './loadScreen.module.scss';

const LoadScreen: React.FC = () => {
  const { data } = useQuery<{
    progress: { status: number; wasLoaded?: boolean };
  }>(getProgressGql);

  const { data: config } = useQuery<IConfig>(configGql);
  const { isSoundOn } = config!;
  const [isShowContent, setShowContent] = useState(true);
  const { progress } = data!;

  const [getAuth] = useMutation<
    { auth: { sessionId: string } },
    { input: Omit<IAuthInput, 'slotId' | 'lng' | 'home'> }
  >(authGql, {
    onCompleted({ auth: { sessionId } }) {
      const { slotId } = parseQuery<IAuthInput>();
      setSlotConfig({
        ...setSlotConfig(),
        id: slotId,
        sessionId,
      });
      setIsAuthorized(!!data);
      webSocketClient.close(false);
    },
  });

  useEffect(() => {
    const getUserBalance = async () => {
      const userBalance = await client.query<{ user: IUserBalance }>({
        query: getUserGql,
        fetchPolicy: 'network-only',
      });
      setCurrency(userBalance.data.user.balance.currency);
    };
    const getLastBet = async () => {
      const betsData = await client.query<{ bets: ISlotHistoryData }>({
        query: slotHistoryGql,
        variables: {
          input: { last: 1, filter: { slotId: setSlotConfig().id } },
        },
        fetchPolicy: 'network-only',
      });
      if (betsData.data.bets.edges[0]) {
        setUserLastBetResult(betsData.data.bets.edges[0].node);
      }
    };
    const getPurchasableBonuses = async () => {
      const bonusData = await client.query<{ bonuses: IBonus[] }>({
        query: getBonuses,
        variables: { input: { purchasable: true, slotId: setSlotConfig().id } },
        fetchPolicy: 'network-only',
      });
      setBonuses(bonusData.data.bonuses);
    };
    const checkBonusGame = async () => {
      const activeUserBonusData = await client.query<{
        userBonuses: UserBonus[];
      }>({
        query: getUserBonuses,
        variables: {
          input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
        },
        fetchPolicy: 'network-only',
      });

      const freeSpinsBonus = activeUserBonusData.data.userBonuses.find((ub) => ub.bonus.type === 'PRE_LOADED');
      const reSpinsBonus = activeUserBonusData.data.userBonuses.find((ub) => ub.bonus.type === 'SPECIAL_ROUND');

      if (reSpinsBonus) {
        let gameMode = GameMode.RESPIN_1;
        switch (setUserLastBetResult().reelSetId) {
          case reelSets[GameMode.REGULAR]:
            gameMode = GameMode.RESPIN_1;
            break;
          case reelSets[GameMode.RESPIN_1]:
            gameMode = GameMode.RESPIN_2;
            break;
          case reelSets[GameMode.RESPIN_2]:
            gameMode = GameMode.RESPIN_3;
            break;
          case reelSets[GameMode.RESPIN_3]:
            gameMode = GameMode.RESPIN_4;
            break;
        }
        setGameMode(gameMode);
        setCurrentBonusId(reSpinsBonus.id);
      }

      if (freeSpinsBonus) {
        const rounds = freeSpinsBonus!.data.battleBonusRounds.length;
        const round = freeSpinsBonus!.data.battleBonusRounds.length;

        const totalRound = freeSpinsBonus!.data.battleBonusRounds.length - freeSpinsBonus!.rounds;
        let currentRound = 0;
        if (totalRound > 0) {
          currentRound = (totalRound - 1) % 6;
        }
        setBrokenGame(true);
        // console.log('rounds', rounds, 'totalRound', totalRound);
        setCurrentBonus({
          ...freeSpinsBonus,
          isActive: true,
          rounds: rounds,
          currentRound: currentRound,
          totalRounds: totalRound,
        });
        setBattleBonusRounds(freeSpinsBonus.data.battleBonusRounds);
        setCurrentFreeSpinsTotalWin(0);
        const userBonusBetsData = await client.query<{
          bets: ISlotHistoryData;
        }>({
          query: userBonusBetsGql,

          variables: {
            input: {
              filter: {
                userBonusId: setCurrentBonus().id,
              },
            },
          },
          fetchPolicy: 'network-only',
        });

        // const round1 = userBonusBetsData.data.bets.pageInfo.count % 6;
        setCurrentBonus({
          ...setCurrentBonus(),
          // currentRound: round1,
          // rounds: userBonusBetsData.data.bets.pageInfo.count,
        });

        if (userBonusBetsData.data.bets.pageInfo.count === 0) {
          const lastBetRes = { ...setUserLastBetResult() };
          lastBetRes.reelSetId = reelSets[GameMode.BATTLE_BONUS]; // TODO
          lastBetRes.result = {
            ...lastBetRes.result,
            reelPositions: [0, 0, 0],
          };
        }

        if (setCurrentBonus().id != '') {
          const res = await client.query<{
            userBonuses: UserBonus[];
          }>({
            query: getUserBonuses,
            variables: { input: { id: setCurrentBonus().id } },
            fetchPolicy: 'network-only',
          });
          const { betId } = res.data.userBonuses[0];
          const bet = await client.query<ISettledBet>({
            query: slotBetGql,
            variables: { input: { id: betId } },
            fetchPolicy: 'network-only',
          });

          setBrokenGameReelSet(bet.data.bet.reelSetId);
          setPrevReelsPosition(bet.data.bet.result.reelPositions);
        }
      } else {
        if (isFreeSpinsMode(getGameModeByReelSetId(setUserLastBetResult().reelSetId))) {
          // for current mode is normal or high chance and prev spin is freespin
          const lastBet = await client.query<ISettledBet>({
            query: slotBetGql,
            variables: { input: { id: setUserLastBetResult().id } },
            fetchPolicy: 'network-only',
          });
          const bet = await client.query<ISettledBet>({
            query: slotBetGql,
            variables: { input: { id: lastBet.data.bet.userBonus.betId } },
            fetchPolicy: 'network-only',
          });
          const lastBetRes = { ...setUserLastBetResult() };
          lastBetRes.reelSetId = bet.data.bet.reelSetId;
          lastBetRes.result = {
            ...lastBetRes.result,
            reelPositions: bet.data.bet.result.reelPositions,
          };
          setUserLastBetResult(lastBetRes);
        }
      }
    };

    const getSlotData = async () => {
      const slotData = await client.query<{ slot: ISlotData }>({
        query: getSlotGql,
        variables: { input: { id: setSlotConfig().id } },
        fetchPolicy: 'network-only',
      });
      const { slot } = slotData.data;

      const lines = slot.lines.map((_, index) => index);
      setSlotConfig({
        ...setSlotConfig(),
        clientSettings: slot.clientSettings,
        icons: slot.icons,
        reels: slot.reels,
        winLines: slot.lines,
        lines: slot.lines,
        lineSet: slot.lineSets[0],
        settings: slot.settings,
      });
      // todo add logic to pick gamemode and reelsetid
      setReelSetId(
        slot.reels.find((reelSet) => reelSet.type === ReelSetType.DEFAULT)?.id || reelSets[GameMode.REGULAR],
      );
      if (!isReSpinMode(setGameMode())) {
        setGameMode(GameMode.REGULAR);
      }

      let coinValue;
      let coinAmount;
      if (setBrokenGame()) {
        const currentBonus = setCurrentBonus();
        coinValue = currentBonus.coinValue;
        coinAmount = currentBonus.coinAmount;
      } else {
        const lastBetCoinAmount = setUserLastBetResult().id ? setUserLastBetResult().coinAmount : 1;
        coinAmount = findSubstituteCoinAmount(lastBetCoinAmount, slot.clientSettings.coinAmounts.default);
        coinValue = slot.clientSettings.coinValues.find((elem) => elem.code === setCurrency())?.variants[0];
      }
      setCoinValue(coinValue);
      setCoinAmount(coinAmount);
      setWinAmount(setUserLastBetResult().result.winCoinAmount);
      setBetAmount(coinAmount * slot.lineSets[0].coinAmountMultiplier);
    };

    setShowContent(true);
    new Loader({ asynchronous: false })
      .stage(20, 'auth', async (stage) => {
        setIsMobile(isMobileDevice());
        const { token, clientId } = parseQuery<Omit<IAuthInput, 'slotId' | 'lng'>>();
        await getAuth({ variables: { input: { token, clientId } } });

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(40, 'bonuses', async (stage) => {
        await getUserBalance();
        await getPurchasableBonuses();
        await getLastBet();
        await checkBonusGame();
        await getSlotData();

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(60, 'loadPixiAssets', async (stage) => {
        await loadPixiAssets([...LOADER_TEXTURES, ...LOADER_SPRITE_TEXTURES, ...SPINE_LOADER_TEXTURES(setIsMobile())]);
        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(80, 'loadAudio', async (stage) => {
        AudioHowl.initialize(audioSprite, audioSpriteVolume, true, isSoundOn, setIsSuspended).then(() => {
          eventManager.emit(
            EventTypes.SOUND_INITIALIZED,
            AudioHowl.isRestricted && !(!AudioHowl.restrictionChangedOnIntroScreen && !setIsSoundOn()),
          );
        });
      })
      .onComplete(async () => {
        setProgress({
          ...setProgress(),
          status: 100,
        });
        eventManager.on(EventTypes.POST_RENDER, () => {
          setProgress({
            ...setProgress(),
            wasLoaded: setSkipIntroScreen(),
          });
          setShowContent(false);
        });
      })
      .load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isShowContent) return null;
  return (
    <div className={styles.loadScreenWrapper}>
      <div className={styles.logo}>
        <img
          draggable="false"
          alt="logo"
          src={Resources.getSource(ResourceTypes.logo)}
          className={styles.companyLogo}
        />
      </div>
      <ProgressBar
        className={styles.progressBar}
        type="line"
        trailWidth={2}
        trailColor="#000000"
        strokeWidth={2}
        strokeColor="#fcf7cd"
        percent={progress?.status || 0}
      />
    </div>
  );
};

export default LoadScreen;
