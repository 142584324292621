import { IAddOptions, LoaderResource } from 'pixi.js';
import * as PIXI from 'pixi.js';

import { GameMode } from '../global.d';
import { ResourceTypes } from '../resources.d';
import Resources from '../utils/resources';

import { SlotId, SlotIdH, SlotIdI } from './config';

export enum SymbolAnimationType {
  DEFAULT,
  SPRITE,
  SPINE,
}
export const MAPPED_SYMBOLS: Record<SlotId, ResourceTypes> = {
  [SlotId.J]: ResourceTypes.symbolAGold7,
  [SlotId.K]: ResourceTypes.symbolBRed7,
  [SlotId.A]: ResourceTypes.symbolDHero,
  [SlotId.B]: ResourceTypes.symbolEHeroine,
  [SlotId.C]: ResourceTypes.symbolFRival,
  [SlotId.D]: ResourceTypes.symbolGArms,
  [SlotId.E]: ResourceTypes.symbolHLow1,
  [SlotId.F]: ResourceTypes.symbolILow2,
  [SlotId.G]: ResourceTypes.symbolJLow3,
  [SlotId.H]: ResourceTypes.symbolCRespin,
  [SlotId.I]: ResourceTypes.symbolGArmsRe,
};
export const MAPPED_BLURRED_SYMBOLS: Record<SlotId, ResourceTypes> = {
  [SlotId.J]: ResourceTypes.symbolAGold7,
  [SlotId.K]: ResourceTypes.symbolBRed7,
  [SlotId.A]: ResourceTypes.blurSymbolDHero,
  [SlotId.B]: ResourceTypes.blurSymbolEHeroine,
  [SlotId.C]: ResourceTypes.blurSymbolFRival,
  [SlotId.D]: ResourceTypes.blurSymbolGArms,
  [SlotId.E]: ResourceTypes.blurSymbolHLow1,
  [SlotId.F]: ResourceTypes.blurSymbolILow2,
  [SlotId.G]: ResourceTypes.blurSymbolJLow3,
  [SlotId.H]: ResourceTypes.symbolCRespin,
  [SlotId.I]: ResourceTypes.symbolGArmsRe,
};

export const MAPPED_SYMBOLS_STOP_ANIMATIONS: {
  [key in SlotId]?: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  };
} = {
  [SlotId.J]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_A_Gold7_announce',
  },
  [SlotId.K]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_B_Red7_announce',
  },
  [SlotId.H]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_C_Respin_announce',
  },
  [SlotId.A]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_D_Hero_announce',
  },
  [SlotId.B]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_E_Heroine_announce',
  },
  [SlotId.C]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_F_Rival_announce',
  },
  [SlotId.I]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_G_ArmsRe_announce',
  },
};

export type StopAnimationRen = {
  type: SymbolAnimationType;
  src?: string;
  animation?: string;
};

export const MAPPED_SYMBOLS_STOP_ANIMATIONS_H_REN: {
  [key in GameMode]: StopAnimationRen;
} = {
  [GameMode.REGULAR]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_C_Respin_announce',
  },
  [GameMode.RESPIN_1]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_C_Respin_announce2',
  },
  [GameMode.RESPIN_2]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_C_Respin_announce3',
  },
  [GameMode.RESPIN_3]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_C_Respin_announce4',
  },
};

export const MAPPED_SYMBOLS_STOP_ANIMATIONS_I_REN: {
  [key in GameMode]: StopAnimationRen;
} = {
  [GameMode.REGULAR]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_G_ArmsRe_announce',
  },
  [GameMode.RESPIN_1]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_G_ArmsRe_announce2',
  },
  [GameMode.RESPIN_2]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_G_ArmsRe_announce3',
  },
  [GameMode.RESPIN_3]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_G_ArmsRe_announce4',
  },
};

export const MAPPED_SYMBOLS_ANIMATIONS: {
  [key in SlotId]: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  };
} = {
  [SlotId.J]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_A_Gold7_win',
  },
  [SlotId.K]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_B_Red7_win',
  },
  [SlotId.H]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_C_Respin_win',
  },
  [SlotId.A]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_D_Hero_win',
  },
  [SlotId.B]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_E_Heroine_win',
  },
  [SlotId.C]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_F_Rival_win',
  },
  [SlotId.D]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_G_Arms_win',
  },
  [SlotId.E]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_H_Low1_win',
  },
  [SlotId.F]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_I_Low2_win',
  },
  [SlotId.G]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_J_Low3_win',
  },
  [SlotId.I]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_G_ArmsRe_win',
  },
};

export const LOADER_TEXTURES: IAddOptions[] = [
  ResourceTypes.reelFrameBase,
  ResourceTypes.bBiconBlank,
  ResourceTypes.symbolAGold7,
  ResourceTypes.symbolBRed7,
  ResourceTypes.symbolDHero,
  ResourceTypes.symbolEHeroine,
  ResourceTypes.symbolCRespin,
  ResourceTypes.symbolFRival,
  ResourceTypes.symbolHLow1,
  ResourceTypes.symbolILow2,
  ResourceTypes.symbolJLow3,
  ResourceTypes.symbolGArms,
  ResourceTypes.symbolGArmsRe,
  ResourceTypes.backgroundBase,
  ResourceTypes.introBg,
  ResourceTypes.intro01,
  ResourceTypes.intro02,
  ResourceTypes.intro03,
  ResourceTypes.intro04,
  ResourceTypes.buttonOk,
  ResourceTypes.blurSymbolDHero,
  ResourceTypes.blurSymbolEHeroine,
  ResourceTypes.blurSymbolFRival,
  ResourceTypes.blurSymbolHLow1,
  ResourceTypes.blurSymbolILow2,
  ResourceTypes.blurSymbolJLow3,
  ResourceTypes.blurSymbolGArms,
  ResourceTypes.buyFeatureBtn,
  ResourceTypes.buyFeatureBtnNotActive,
  ResourceTypes.buyFeatureBtnHover,
  ResourceTypes.buyFeatureBtnPressed,
  ResourceTypes.buyFeatureInput,
  ResourceTypes.backdrop,
  ResourceTypes.buyFeaturePopup,
  ResourceTypes.buyFeaturePopupConfirm,
  ResourceTypes.buyFeatureCancelBtn,
  ResourceTypes.buyFeatureCancelBtnHover,
  ResourceTypes.buyFeatureCancelBtnPressed,
  ResourceTypes.buyFeatureOkBtn,
  ResourceTypes.buyFeatureOkBtnNotActive,
  ResourceTypes.buyFeatureOkBtnHover,
  ResourceTypes.buyFeatureOkBtnPressed,
  ResourceTypes.buyFeatureMinusBtn,
  ResourceTypes.buyFeatureMinusBtnHover,
  ResourceTypes.buyFeatureMinusBtnNotActive,
  ResourceTypes.buyFeatureMinusBtnPressed,
  ResourceTypes.buyFeaturePlusBtn,
  ResourceTypes.buyFeaturePlusBtnHover,
  ResourceTypes.buyFeaturePlusBtnNotActive,
  ResourceTypes.buyFeaturePlusBtnPressed,
  ResourceTypes.buyFeatureRed7,
  ResourceTypes.buyFeatureGold7,
  ResourceTypes.bBiconHeroattackHigh,
  ResourceTypes.bBiconHeroattackLow,
  ResourceTypes.bBiconHeroattackMed,
  ResourceTypes.bBiconHeroattackSp,
  ResourceTypes.bBiconRivalattack,
  ResourceTypes.reelBack,
  ResourceTypes.logo,
  ResourceTypes.reelFrameBb,
  ResourceTypes.buyFeatureBbBtn,
  ResourceTypes.buyFeatureBbBtnNotActive,
  ResourceTypes.buyFeatureBbBtnHover,
  ResourceTypes.buyFeatureBbBtnPressed,
  ResourceTypes.buyFeatureHbbBtn,
  ResourceTypes.buyFeatureHbbBtnHover,
  ResourceTypes.buyFeatureHbbBtnNotActive,
  ResourceTypes.buyFeatureHbbBtnPressed,
  ResourceTypes.backgroundBb,
  ResourceTypes.spEd,
  ResourceTypes.messageBannerBb,
  ResourceTypes.messageBannerBbIcon,
  ResourceTypes.battleResultBg,
  ResourceTypes.battleResultBgSp,
].map((resource) => {
  return { name: resource, url: Resources.getSource(resource) };
});

export const SPINE_ATLASES: IAddOptions[] = [
  {
    name: 'spine_atlas',
    url: '/animations/desktop/symbol/spine.atlas',
    loadType: LoaderResource.LOAD_TYPE.XHR,
    xhrType: LoaderResource.XHR_RESPONSE_TYPE.TEXT,
  },
];

export const generateTexturePath = (before: string, after: string, isMobile = false): string => {
  return `${before}/${isMobile ? 'mobile' : 'desktop'}/${after}`;
};
export const SPINE_LOADER_TEXTURES = (isMobile: boolean): IAddOptions[] => {
  return [
    {
      name: 'symbol_all',
      url: generateTexturePath('/animations', 'Symbol_all/Symbol_all.json', isMobile),
    },
    {
      name: 'Nearmiss_Bonus',
      url: generateTexturePath('/animations', 'Nearmiss_Bonus/Nearmiss_Bonus.json', isMobile),
    },
    {
      name: 'Nearmiss_Respin',
      url: generateTexturePath('/animations', 'Nearmiss_Respin/Nearmiss_Respin.json'),
    },
    {
      name: 'Battle_Start',
      url: generateTexturePath('/animations', 'Battle_Start/Battle_Start.json', isMobile),
    },
    {
      name: 'BattleBonus',
      url: generateTexturePath('/animations', 'BattleBonus/BattleBonus.json', isMobile),
    },
    {
      name: 'BattleBonus_back',
      url: generateTexturePath('/animations', 'BattleBonus_back/BattleBonus_back.json', isMobile),
    },
    {
      name: 'BattleBonus_R6',
      url: generateTexturePath('/animations', 'BattleBonus_R6/BattleBonus_R6.json', isMobile),
    },
    {
      name: 'BattleBonus_R6_back',
      url: generateTexturePath('/animations', 'BattleBonus_R6_back/BattleBonus_R6_back.json', isMobile),
    },
    {
      name: 'Basegame_characterwindow',
      url: generateTexturePath('/animations', 'Basegame_characterwindow/Basegame_characterwindow.json', isMobile),
    },
    {
      name: 'coinsAnimation',
      url: generateTexturePath('/animations', 'coins/coins.json', isMobile),
    },
  ];
};

export const LOADER_SPRITE_TEXTURES: IAddOptions[] = [
  {
    name: 'buttonsSprite',
    url: '/images/buttons/buttons.json',
  },
  {
    name: 'infoBtnSprite',
    url: '/images/infoBtn/infomation.json',
  },
  {
    name: 'loaderSprite',
    url: '/images/loader/loader.json',
  },
  {
    name: 'phoenixMovie',
    url: '/movie/phoenix.mp4',
    loadType: LoaderResource.LOAD_TYPE.XHR,
  },
];
