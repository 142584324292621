import * as PIXI from 'pixi.js';

import { SlotId } from '../../config';
import { Events } from '../../global.d';

import TypedEmitter from './typedEmitter';

export * from './textStyles';
export const eventManager = new PIXI.utils.EventEmitter() as TypedEmitter<Events>;
window.eventManager = eventManager; // Trick to proxy eventManager to cheats file

export enum SlotMachineState {
  IDLE,
  SPIN,
  STOP,
  JINGLE,
  WINNING,
  // eslint-disable-next-line prettier/prettier
}
// APPLICATION SETTINGS
export const APPLICATION_TRANSPARENT = false;
export const APPLICATION_FPS = 60;

// WIN SETTINGS
export const WIN_ANIMATION_INTERVAL = 1_000;
export const WIN_ANIMATION_DELAY_TIME_DURATION = 2_000;
export const WIN_CHECK_TIME_DURATION_ON_FREE_SPIN = 1_000;

// BASE WIN SETTINGS
export const DOUBLE_WIN_AMOUNT_LIMIT = 2;
export const BASE_WIN_AMOUNT_LIMIT = 10;
export const BASE_WIN_TITLE_SCALE = 1.2;
export const BASE_WIN_COUNT_UP_MULTIPLIER = 0.03;
export const BATTLE_BONUS_WIN_COUNT_UP_MULTIPLIER = 0.5;

// BIG WIN SETTINGS
export const BIG_WIN_TITLE = 'BIG WIN!';
export const BIG_WIN_AMOUNT_LIMIT = 25;
export const BIG_WIN_TITLE_SCALE = 1.3;
export const BIG_WIN_ZOOM_TITLE_DURATION = 500;
export const BIG_WIN_COUNT_UP_MULTIPLIER = 0.0265;
export const BIG_WIN_END_DURATION = 1000;

// MEGA WIN SETTINGS
export const MEGA_WIN_TITLE = 'MEGA WIN!';
export const MEGA_WIN_AMOUNT_LIMIT = 50;
export const MEGA_WIN_TITLE_SCALE = 1.4;
export const MEGA_WIN_ZOOM_TITLE_DURATION = 550;
export const MEGA_WIN_COUNT_UP_MULTIPLIER = 0.1;

// GREAT WIN SETTINGS
export const GREAT_WIN_TITLE = 'GREAT WIN!';
export const GREAT_WIN_AMOUNT_LIMIT = 100;
export const GREAT_WIN_TITLE_SCALE = 1.5;
export const GREAT_WIN_ZOOM_TITLE_DURATION = 600;
export const GREAT_WIN_COUNT_UP_MULTIPLIER = 0.2;

// EPIC WIN SETTINGS
export const EPIC_WIN_TITLE = 'EPIC WIN!';
export const EPIC_WIN_TITLE_SCALE = 1.6;
export const EPIC_WIN_ZOOM_TITLE_DURATION = 650;
export const EPIC_WIN_COUNT_UP_MULTIPLIER = 0.5;

// WIN SLOT ANIMATION SETTINGS
export const WIN_SLOT_ANIMATION_SCALE = 1.15;
export const WIN_SLOT_ANIMATION_DURATION = 600;
export const TURBO_SPIN_WIN_SLOT_ANIMATION_COEFFICIENT = 1;
export const WIN_SLOT_TINT_COLOR = 0x888888;
export const SPRITE_ANIMATION_FPS = 30;

// GENERAL SLOT MACHINE SETTINGS
export const REELS_AMOUNT = 3;
export const SLOTS_PER_REEL_AMOUNT = 3;
export const REEL_WIDTH = 410;
export const SLOT_WIDTH = 410;
export const SLOT_HEIGHT = 240;
export const SLOT_SCALE = 1.0;
export const SHOW_ALL_LINES_ON_WIN = true;
export const SLOTS_CONTAINER_HEIGHT = SLOT_HEIGHT * SLOTS_PER_REEL_AMOUNT;
export const SLOTS_CONTAINER_WIDTH = REEL_WIDTH * REELS_AMOUNT;
export const SHOW_LINES_NUMBER_CONTAINER = false;
export const LINES_NUMBER_CONTAINER_WIDTH = 30;
export const MINIMUM_SPIN_SLOTS_AMOUNT = 50;
export const GAME_CONTAINER_HEIGHT = SLOTS_CONTAINER_HEIGHT;
export const GAME_CONTAINER_WIDTH = SHOW_LINES_NUMBER_CONTAINER
  ? SLOTS_CONTAINER_WIDTH + 2 * LINES_NUMBER_CONTAINER_WIDTH
  : SLOTS_CONTAINER_WIDTH;
export const SLOTS_BACKGROUND_HEIGHT = SLOTS_CONTAINER_HEIGHT + 20;
export const SLOTS_BACKGROUND_WIDTH = SLOTS_CONTAINER_WIDTH;

export const SLOTS_BASE_GAME_ADJUST = 35;
export const SLOTS_BATTLE_GAME_ADJUST = 30;

// BATTLE BONUS UPPER COLOR WINDOW
export const BATTLE_BNS_UPPER_COLOR_POS_X = -610;
export const BATTLE_BNS_UPPER_COLOR_POS_Y = 120;

export const SP_ENDING_ROUND = 20;

// BATTLE BONUS Chara ICON
export const BATTLE_BNS_UPPER_BACKGROUND_POS_Y = -255;
export const BATTLE_BNS_UPPER_BACKGROUND_HEIGHT = 250;
export const BATTLE_BNS_UPPER_BACKGROUND_WIDTH = SLOTS_CONTAINER_WIDTH;

export const SLOTS_BB_ICON_BLANK = -21;
export const SLOTS_BB_ICON = -11;
export const BATTLE_BNS_CHARA_ICON_MAX = 5;

// REELS FRAME SETTINGS
export const REELS_FRAME_ADJUSTMENT = 32;

// ANTICIPATION SETTINGS
export const ANTICIPATION_ENABLE = true;
export const ANTICIPATION_SYMBOLS_ID = [SlotId.J, SlotId.K];
export const ANTICIPATION_START_SYMBOLS_AMOUNT = [2, 2];
export const ANTICIPATION_END_SYMBOLS_AMOUNT = [];
export const ANTICIPATION_RESPIN_REEL = 1;

export const ANTICIPATION_DELAY = 0;
export const ANTICIPATION_DURATION = 3000;
export const ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT = 10;
export const ANTICIPATION_REEL_FORMULA = (t: number): number => 1 - (1 - t) * (1 - t);

export const ANTICIPATION_SLOTS_TINT = 0x7f7f7f;

// BATTLE BONUS MAIN  WINDOW
export const BATTLE_BONUS_MAIN_POS_X = -614;
export const BATTLE_BONUS_MAIN_POS_Y = -350;

// BATTLE BONUS MAIN  WINDOW
export const BATTLE_BONUS_R6_MAIN_POS_X = 0;
export const BATTLE_BONUS_R6_MAIN_POS_Y = -470;

// BASE GAME CHARA WINDOW
export const BASE_GAME_CHARA_WINDOW_POS_X = SLOTS_CONTAINER_WIDTH / 2;
export const BASE_GAME_CHARA_WINDOW_POS_Y = -125;

// REEL STOP SOUND SETTINGS
export const BONUS_SYMBOLS_ID = [
  [SlotId.J, SlotId.J, SlotId.J],
  [SlotId.K, SlotId.K, SlotId.K],
];

export const GOLD_BONUS_SYMBOLS_ID = [[SlotId.J, SlotId.J, SlotId.J]];

export const RED_BONUS_SYMBOLS_ID = [[SlotId.K, SlotId.K, SlotId.K]];

export const BATTLE_BONUS_MESSAGE_ICON_SCALE = 0.75;
export const BATTLE_BONUS_MESSAGE_ICON_POS = [BATTLE_BONUS_MESSAGE_ICON_SCALE * SLOT_WIDTH * -1 - 70, 70];

// SAFE AREA SETTINGS
export const SAFE_AREA_DESKTOP_BOTTOM_PADDING = 0;
export const SAFE_AREA_MOBILE_BOTTOM_PADDING = 0;
export const SAFE_AREA_LANDSCAPE_WIDTH = 1750;
export const SAFE_AREA_LANDSCAPE_HEIGHT = 1080;
export const SAFE_AREA_LANDSCAPE_PIVOT_X = -255;
export const SAFE_AREA_LANDSCAPE_PIVOT_Y = -285; // -160;
export const SAFE_AREA_PORTRAIT_WIDTH = SLOTS_CONTAINER_WIDTH + 50; // 498 * 1230/463
export const SAFE_AREA_PORTRAIT_HEIGHT = SLOTS_CONTAINER_HEIGHT * 2; // 800 * 1230/463
export const SAFE_AREA_PORTRAIT_PIVOT_X = -25; // 18 * 1230/463
export const SAFE_AREA_PORTRAIT_PIVOT_Y = -SLOTS_CONTAINER_HEIGHT / 2; // 246 * 1230/463

// REELS SETTINGS
export const BASE_SPIN_TIME = 2000;
export const TURBO_SPIN_TIME = 1000;
export const ADDITIONAL_SPIN_TIME_PER_REEL = 300;
export const TURBO_ADDITIONAL_SPIN_TIME_PER_REEL = 100;
export const TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL = 25;
export const SPIN_REEL_ANIMATION_DELAY_PER_REEL = 50;
// export const PERSISTENT_SHARP_SYMBOLS_ID = [SlotId.SC1, SlotId.WL, SlotId.MS1];
export const BASE_REEL_BLUR_VALUE = 18;
export const TURBO_REEL_BLUR_VALUE = 24;
export enum ReelState {
  IDLE,
  STARTING,
  ROLLING,
  ENDING,
}

// SPIN ANIMATION SETTINGS
export const BASE_REEL_STARTING_DURATION = 500;
export const BASE_REEL_ROLLING_DURATION = 1250;
export const BASE_REEL_PHOENIX_ROLLING_DURATION = 2000;
export const BASE_REEL_PHOENIX_ROLLING_DURATION_TURBO = 3000;
export const BASE_REEL_ANIMATION_ROLLING_DURATION = 1000;
export const BASE_REEL_ANIMATION_ROLLING_DURATION_TURBO = 2000;
export const BASE_REEL_ROLLING_SPEED = 0.025;
export const BASE_REEL_ENDING_DURATION = 250;
export const FAKE_ROLLING_DURATION = 12_000;
export const FAKE_REPLAY_ROLLING_DURATION = 60_000;
export const TURBO_REEL_STARTING_DURATION = 125;
export const TURBO_REEL_ROLLING_DURATION = 100;
export const TURBO_REEL_ROLLING_SPEED = 0.05;
export const TURBO_REEL_ENDING_DURATION = 250;
export const FORCE_STOP_SPIN_ANIMATION_DURATION = 250;
export const FORCE_STOP_SPIN_PER_EACH_DURATION = 50;
export const REEL_STARTING_SLOTS_AMOUNT = 3;
export const REEL_MYSTERY_SLOTS_AMOUNT = 120;
export const REEL_ENDING_SLOTS_AMOUNT = 3;
export const BASE_REEL_STARTING_FORMULA = (t: number): number => 3 * t * t * (1.4 * t - 0.4);
export const BASE_REEL_ENDING_FORMULA = (t: number): number => 1 - (1 - t) * (1 - t);
// (t - 1) * (t - 1) * ((0.5 + 1) * (t - 1) + 0.5) + 1;
export const BASE_RESPIN_ARMS_RE_CHANGE_DURATION = 4000;

// TINT CONTAINER SETTINGS
export const TINT_CONTAINER_WIDTH = REEL_WIDTH;
export const TINT_CONTAINER_HEIGHT = SLOTS_BACKGROUND_HEIGHT;
export const TINT_BACKGROUND_COLOR = 0x000000;
export const TINT_ALPHA = 0.3;

// PAY_TABLE SETTINGS
export const PAY_TABLE_WIDTH = REEL_WIDTH;
export const PAY_TABLE_HEIGHT = SLOT_HEIGHT * 0.75;
export const PAY_TABLE_BACKGROUND_COLOR = 0x000000;
export const PAY_TABLE_NUMBER_COLOR = 0xffffff;
export const PAY_TABLE_TEXT_COLOR = 0xf6e87e;

// COINS ANIMATIONS SETTINGS
export const COINS_AMOUNT_PER_SEC = 8;
export const SINGLE_COIN_ANIMATION_DURATION = 2500;
export const MINIMUM_FRACTION_DIGITS = 2;
export const MAXIMUM_FRACTION_DIGITS = 2;

export const WIN_ANIM_START_DELAY = 500;

// FREE SPINS
export const FREE_SPINS_FADE_OUT_DURATION = 1000;
export const FREE_SPINS_FADE_IN_DURATION = 1000;
export const FREE_SPINS_TIME_OUT_BANNER = 3500;

export const WIN_CHECK_TIME_DURATION_ON_FREESPIN = 1_000;
export const BIG_WIN_CHECK_TIME_DURATION_ON_FREESPIN = 3_000;

export const BB_BANNER_FADEOUT_TIME = 1000;

// WIN STAGES
export enum WinStages {
  None,
  BaseWin,
  BigWin,
  MegaWin,
  GreatWin,
  EpicWin,
}

// LINES SETTINGS
type Position = {
  [key: number]: {
    x: number;
    y: number;
  };
};
export const POSITION: Position = {
  0: {
    x: REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  1: {
    x: REEL_WIDTH + REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  2: {
    x: REEL_WIDTH * 2 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  3: {
    x: REEL_WIDTH * 3 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  4: {
    x: REEL_WIDTH * 4 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  5: {
    x: REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  6: {
    x: REEL_WIDTH + REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  7: {
    x: REEL_WIDTH * 2 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  8: {
    x: REEL_WIDTH * 3 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  9: {
    x: REEL_WIDTH * 4 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  10: {
    x: REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
  11: {
    x: REEL_WIDTH + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
  12: {
    x: REEL_WIDTH * 2 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
  13: {
    x: REEL_WIDTH * 3 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
  14: {
    x: REEL_WIDTH * 4 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
  15: {
    x: REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 3 + SLOT_HEIGHT / 2,
  },
  16: {
    x: REEL_WIDTH + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 3 + SLOT_HEIGHT / 2,
  },
  17: {
    x: REEL_WIDTH * 2 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 3 + SLOT_HEIGHT / 2,
  },
  18: {
    x: REEL_WIDTH * 3 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 3 + SLOT_HEIGHT / 2,
  },
  19: {
    x: REEL_WIDTH * 4 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 3 + SLOT_HEIGHT / 2,
  },
};

// LINE NUMBERS CONTAINER SETTINGS
export const LINES_NUMBER_CONTAINER_HEIGHT = SLOTS_CONTAINER_HEIGHT;
export const LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X = 5;
export const RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X = 0;
export const LEFT_LINES_NUMBERS_CONTAINER_POSITIONS = [
  { id: 0, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 0 },
  { id: 1, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 35 },
  { id: 2, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 70 },
  { id: 3, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 105 },
  { id: 4, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 140 },
  { id: 5, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 175 },
  { id: 6, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 210 },
  { id: 7, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 245 },
  { id: 8, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 280 },
  { id: 9, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 315 },
  { id: 10, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 350 },
  { id: 11, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 385 },
];
export const RIGHT_LINES_NUMBERS_CONTAINER = [
  { id: 12, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 0 },
  { id: 13, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 32 },
  { id: 14, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 64 },
  { id: 15, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 96 },
  { id: 16, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 128 },
  { id: 17, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 160 },
  { id: 18, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 192 },
  { id: 19, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 224 },
  { id: 20, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 256 },
  { id: 21, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 288 },
  { id: 22, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 320 },
  { id: 23, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 352 },
  { id: 24, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 384 },
];

// BUY FEATURE BTN
export const FEATURE_BTN_CONTAINER_POSITION_Y = 250;
export const FEATURE_BTN_CONTAINER_POSITION_X = -140;
export const FEATURE_BTN_LANDSCAPE_WIDTH = 156;
export const FEATURE_BTN_LANDSCAPE_HEIGHT = 100;

export const FEATURE_BTN_PORTRAIT_WIDTH = 156; // 344;
export const FEATURE_BTN_PORTRAIT_HEIGHT = 100; // 274;

// BUY FEATURE POPUP
export const FEATURE_POPUP_BASE_POS_X = 0;
export const FEATURE_POPUP_BASE_POS_Y = 75;
export const FEATURE_POPUP_POS_X = 15;
export const FEATURE_POPUP_POS_Y = -65;
export const FEATURE_POPUP_TITLE_POS_X = -10;
export const FEATURE_POPUP_TITLE_POS_Y = 640;
export const FEATURE_POPUP_BB_TITLE_POS_X = 125;
export const FEATURE_POPUP_BB_TITLE_POS_Y = 120;
export const FEATURE_POPUP_HBB_TITLE_POS_X = 650;
export const FEATURE_POPUP_HBB_TITLE_POS_Y = FEATURE_POPUP_BB_TITLE_POS_Y;
export const FEATURE_POPUP_BB_COST_POS_X = 125;
export const FEATURE_POPUP_BB_COST_POS_Y = 300;
export const FEATURE_POPUP_HBB_COST_POS_X = 650;
export const FEATURE_POPUP_HBB_COST_POS_Y = FEATURE_POPUP_BB_COST_POS_Y;
export const FEATURE_POPUP_BB_BTN_POS_X = 125;
export const FEATURE_POPUP_BB_BTN_POS_Y = 80;
export const FEATURE_POPUP_HBB_BTN_POS_X = 650;
export const FEATURE_POPUP_HBB_BTN_POS_Y = FEATURE_POPUP_BB_BTN_POS_Y;

export const FEATURE_POPUP_CANCEL_BTN_POS_Y = 500;
export const FEATURE_POPUP_CANCEL_BTN_POS_X = 300;
export const FEATURE_POPUP_OK_BTN_WIDTH = 282;
export const FEATURE_POPUP_OK_BTN_POS_Y = 500;
export const FEATURE_POPUP_OK_BTN_POS_X = 700;
export const FEATURE_POPUP_AMOUNT_TEXT_POS_X = 400;
export const FEATURE_POPUP_AMOUNT_TEXT_POS_Y = 410;
export const FEATURE_POPUP_MINUS_BTN_WIDTH = 70;
export const FEATURE_POPUP_MINUS_BTN_HEIGHT = FEATURE_POPUP_MINUS_BTN_WIDTH;
export const FEATURE_POPUP_MINUS_BTN_POS_Y = FEATURE_POPUP_AMOUNT_TEXT_POS_Y - 4;
export const FEATURE_POPUP_MINUS_BTN_POS_X = FEATURE_POPUP_OK_BTN_POS_X + 10 - FEATURE_POPUP_MINUS_BTN_WIDTH;
export const FEATURE_POPUP_PLUS_BTN_WIDTH = FEATURE_POPUP_MINUS_BTN_WIDTH;
export const FEATURE_POPUP_PLUS_BTN_HEIGHT = FEATURE_POPUP_MINUS_BTN_WIDTH;
export const FEATURE_POPUP_PLUS_BTN_POS_Y = FEATURE_POPUP_MINUS_BTN_POS_Y;
export const FEATURE_POPUP_PLUS_BTN_POS_X = FEATURE_POPUP_OK_BTN_POS_X + FEATURE_POPUP_OK_BTN_WIDTH - 10;
export const FEATURE_POPUP_INPUT_WIDTH = 260;
export const FEATURE_POPUP_INPUT_HEIGHT = 50;
export const FEATURE_POPUP_INPUT_POS_Y = FEATURE_POPUP_AMOUNT_TEXT_POS_Y + 7;
export const FEATURE_POPUP_INPUT_POS_X = FEATURE_POPUP_OK_BTN_POS_X + FEATURE_POPUP_OK_BTN_WIDTH / 2;
export const FEATURE_POPUP_BET_VALUE_POS_Y = FEATURE_POPUP_AMOUNT_TEXT_POS_Y;
export const FEATURE_POPUP_BET_VALUE_POS_X = FEATURE_POPUP_OK_BTN_POS_X + FEATURE_POPUP_OK_BTN_WIDTH / 2;
export const FEATURE_POPUP_MAX = 40;

// BUY FEATURE CONFIRM
export const FEATURE_POPUP_CONFIRM_POS_X = -35;
export const FEATURE_POPUP_CONFIRM_POS_Y = 75;
export const FEATURE_POPUP_CONFIRM_TITLE_POS_X = 650;
export const FEATURE_POPUP_CONFIRM_TITLE_POS_Y = 50;
export const FEATURE_POPUP_CONFIRM_BATTLE_TITLE_POS_X = FEATURE_POPUP_CONFIRM_TITLE_POS_X;
export const FEATURE_POPUP_CONFIRM_BATTLE_TITLE_POS_Y = 110;
export const FEATURE_POPUP_CONFIRM_POSITION_Y = -98;
export const FEATURE_POPUP_CONFIRM_POSITION_X = 50;
export const FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POS_Y = 260;
export const FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POS_Y = 330;
export const FEATURE_POPUP_CONFIRM_CANCEL_BTN_POS_Y = 430;
export const FEATURE_POPUP_CONFIRM_OK_BTN_POS_Y = 430;

export const RESPIN_WAIT_TIME = 2000;
export const RESPIN1_QUICK_START_WAITE = 5000;
export const RESPIN_OTHER_QUICK_START_WAITE = 2500;

// BB or HBB TRIGGER JINGLE DELAY
export const BB_TRIGGER_DELAY = 1400;
export const HBB_TRIGGER_DELAY = 3650;

export const BATTLE_START_ANIM_TIME = 3000;

// PHOENIX
export const PHOENIX_POX_X = -600;
export const PHOENIX_POX_Y = -200;

// Z_INDEX
export const Z_INDEX_BATTLE_BONUS_BACK = 0;
export const Z_INDEX_REELS_BACKGROUND = 1;
export const Z_INDEX_BATTLE_BNS_UPPER_BACKGROUND_CONTAINER = 2;
export const Z_INDEX_GAME_VIEW = 3;
export const Z_INDEX_SLOTS_STOP_DISPLAY = 4;
export const Z_INDEX_BATTLE_BONUS_PANEL = 5;
export const Z_INDEX_BASE_GAME_CHARA_WINDOW = 6;
export const Z_INDEX_BATTLE_BONUS_CONTAINER = 7;
export const Z_INDEX_BATTLE_START = 8;
export const Z_INDEX_REELS_FRAME = 9;
export const Z_INDEX_MINI_PAY_TABLE = 10;
export const Z_INDEX_WIN_SLOTS_CONTAINER = 11;
export const Z_INDEX_BATTLE_BONUS_R6 = 12;
export const Z_INDEX_GAME_TITLE = 13;
export const Z_INDEX_NEARMISS = 14;
export const Z_INDEX_COINS_ANIMATION = 15;
export const Z_INDEX_WIN_LABEL = 16;
export const Z_INDEX_WIN_COUNT_UP_MESSAGE = 17;
export const Z_INDEX_RESPIN_START = 18;
export const Z_INDEX_RESPIN_MESSAGE = 19;
export const Z_INDEX_BUY_FEATURE_BTN = 10;
export const Z_INDEX_BACKDROP = 21;
export const Z_INDEX_BUY_FEATURE_POPUP = 22;
export const Z_INDEX_BUY_FEATURE_POPUP_CONFIRM = 23;
export const Z_INDEX_BANNER = 50;
export const Z_INDEX_PHOENIX = 999;
export const Z_INDEX_REPLAY = 1000;
