import * as PIXI from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import SlotMachine from '..';
import { ISongs } from '../../config';
import { EventTypes, GameMode } from '../../global.d';
import { setGameMode } from '../../gql/cache';
import i18n from '../../i18next';
import { updateTextScale } from '../../utils';
import AnimationChain from '../animations/animationChain';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { Z_INDEX_RESPIN_MESSAGE, eventManager } from '../config';

import {
  RESPIN_MESSAGE_DELAY_DURATION,
  RESPIN_MESSAGE_FADEOUT_DURATION,
  RESPIN_MESSAGE_POSITION_X,
  RESPIN_MESSAGE_POSITION_Y,
  reSpinMessageStyle1,
  reSpinMessageStyle2,
  reSpinMessageStyle3,
  reSpinMessageStyle4,
} from './config';

class ReSpinMessage extends ViewContainer {
  private text: PIXI.Text;

  private animation?: AnimationChain;

  constructor() {
    super();
    this.text = new PIXI.Text('RESPIN!', reSpinMessageStyle1);
    this.text.resolution = 1;
    this.text.anchor.set(0.5);
    this.text.x = RESPIN_MESSAGE_POSITION_X;
    this.text.y = RESPIN_MESSAGE_POSITION_Y;
    this.text.visible = false;
    this.addChild(this.text);
    eventManager.once(EventTypes.HANDLE_SKIP_RESPIN_MESSAGE, this.skip.bind(this));

    eventManager.addListener(EventTypes.CREATE_RESPIN_MESSAGE, this.startRetrigger.bind(this));
    this.zIndex = Z_INDEX_RESPIN_MESSAGE;

    eventManager.on(EventTypes.RESIZE, this.resize.bind(this));
  }

  private startRetrigger(): void {
    let style = reSpinMessageStyle1;

    this.text.visible = true;
    let spinPhrase = ISongs.RP1_Start;

    switch (setGameMode()) {
      case GameMode.RESPIN_1:
        spinPhrase = ISongs.RP1_Start;
        style = reSpinMessageStyle1;
        break;
      case GameMode.RESPIN_2:
        spinPhrase = ISongs.RP2_Start;
        style = reSpinMessageStyle2;
        break;
      case GameMode.RESPIN_3:
        spinPhrase = ISongs.RP3_Start;
        style = reSpinMessageStyle3;
        break;
      case GameMode.RESPIN_4:
        spinPhrase = ISongs.RP4_Start;
        style = reSpinMessageStyle4;
        break;
      default:
        style = reSpinMessageStyle1;
        break;
    }
    this.text.style = style;
    AudioHowl.play({ type: spinPhrase });

    this.text.alpha = 1;
    this.animation = new AnimationChain();
    const delay = Tween.createDelayAnimation(RESPIN_MESSAGE_DELAY_DURATION);

    this.animation.appendAnimation(delay);

    const fadeOut = new Tween({
      object: this.text,
      target: 0,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 1,
      duration: RESPIN_MESSAGE_FADEOUT_DURATION,
    });
    this.animation.appendAnimation(fadeOut);

    this.animation.addOnComplete(() => {
      this.handleDestroy();
      this.text.visible = false;

      if (setGameMode() === GameMode.RESPIN_1) {
        setTimeout(() => {
          eventManager.emit(EventTypes.ARMS_RE_CHANGE);
        }, 0);
      }
    });

    this.animation.addOnSkip(() => {
      this.handleDestroy();
      this.text.visible = false;
    });

    this.animation.start();
  }

  private skip() {
    this.animation?.skip();
  }

  private handleDestroy(): void {
    eventManager.removeAllListeners(EventTypes.HANDLE_SKIP_RESPIN_MESSAGE);
  }

  private resize = (_width: number, _height: number): void => {
    this.text.scale.set(1.0, 1.0);

    if (this.text.width > SlotMachine.getInstance().gameView.frame.width - 100) {
      updateTextScale(this.text, SlotMachine.getInstance().gameView.frame.width - 100, 1000);
    }
  };
}

export default ReSpinMessage;
