import * as PIXI from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs } from '../../config';
import { BattleRoundScenario, BgmSoundTypes, EventTypes, GameMode, Round6 } from '../../global.d';
import {
  setBattleBonusAnimSkip,
  setBattleBonusBgmStop,
  setBattleBonusRounds,
  setCurrency,
  setCurrentBonus,
  setCurrentFreeSpinsTotalWin,
  setGameMode,
  setIsContinueAutoSpinsAfterFeature,
  setIsSpEnding,
} from '../../gql/cache';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { destroySpine, formatNumber, isBattleBonusMode, normalizeCoins, showCurrency } from '../../utils';
import Animation from '../animations/animation';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import BgmControl from '../bgmControl/bgmControl';
import {
  BASE_GAME_CHARA_WINDOW_POS_X,
  BASE_GAME_CHARA_WINDOW_POS_Y,
  BATTLE_BONUS_R6_MAIN_POS_X,
  BATTLE_BONUS_R6_MAIN_POS_Y,
  SP_ENDING_ROUND,
  SlotMachineState,
  Z_INDEX_BATTLE_BONUS_R6,
  eventManager,
} from '../config';

import { BattleRound6AnimTypes } from './config';

type BattleAnimType = {
  animationName: string;
  attackSoundName: ISongs | undefined;
  attackSoundDelay: number | undefined;
  attackSoundName2: ISongs | undefined;
  attackSoundDelay2: number | undefined;
  damageSoundName: ISongs | undefined;
  damageSoundDelay: number | undefined;
  isHeroAttack: boolean | undefined;
  isCountUp: boolean;
  fadeOutDelay: number | undefined;
  revival: boolean;
};

type BattleRound6AnimationType = Record<BattleRound6AnimTypes, { animation: BattleAnimType[] }>;

export const BattleAttackR6Animation: BattleRound6AnimationType = {
  Rival_Attack_Sp_End: {
    animation: [
      {
        animationName: 'Tiltup_Rival',
        attackSoundName: ISongs.BB_Rival,
        attackSoundDelay: 3500,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: undefined,
        damageSoundDelay: undefined,
        isHeroAttack: undefined,
        isCountUp: false,
        fadeOutDelay: undefined,
        revival: false,
      },
      {
        animationName: 'Rival_attack_Sp',
        attackSoundName: ISongs.BB_Atk6,
        attackSoundDelay: 0,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: undefined,
        damageSoundDelay: undefined,
        isHeroAttack: false,
        isCountUp: false,
        fadeOutDelay: undefined,
        revival: false,
      },
      {
        animationName: 'Rival_win',
        attackSoundName: undefined,
        attackSoundDelay: 0,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: ISongs.BB_Damage3,
        damageSoundDelay: 0,
        isHeroAttack: false,
        isCountUp: false,
        fadeOutDelay: 100,
        revival: false,
      },
    ],
  },
  Rival_Attack_End: {
    animation: [
      {
        animationName: 'Tiltup_Rival',
        attackSoundName: ISongs.BB_Rival,
        attackSoundDelay: 3500,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: undefined,
        damageSoundDelay: undefined,
        isHeroAttack: undefined,
        isCountUp: false,
        fadeOutDelay: undefined,
        revival: false,
      },
      {
        animationName: 'Rival_attack',
        attackSoundName: ISongs.BB_Atk3,
        attackSoundDelay: 500,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: undefined,
        damageSoundDelay: undefined,
        isHeroAttack: false,
        isCountUp: false,
        fadeOutDelay: undefined,
        revival: false,
      },
      {
        animationName: 'Rival_win',
        attackSoundName: undefined,
        attackSoundDelay: 0,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: ISongs.BB_Damage3,
        damageSoundDelay: 0,
        isHeroAttack: false,
        isCountUp: false,
        fadeOutDelay: 100,
        revival: false,
      },
    ],
  },
  Rival_Attack_Sp_Continue: {
    animation: [
      {
        animationName: 'Tiltup_Rival',
        attackSoundName: ISongs.BB_Rival,
        attackSoundDelay: 3500,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: undefined,
        damageSoundDelay: undefined,
        isHeroAttack: undefined,
        isCountUp: false,
        fadeOutDelay: undefined,
        revival: false,
      },
      {
        animationName: 'Rival_attack_Sp',
        attackSoundName: ISongs.BB_Atk6,
        attackSoundDelay: 0,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: undefined,
        damageSoundDelay: undefined,
        isHeroAttack: false,
        isCountUp: false,
        fadeOutDelay: undefined,
        revival: false,
      },
      {
        animationName: 'Rival_win',
        attackSoundName: undefined,
        attackSoundDelay: 0,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: ISongs.BB_Damage3,
        damageSoundDelay: 0,
        isHeroAttack: false,
        isCountUp: false,
        fadeOutDelay: 100,
        revival: false,
      },
      {
        animationName: 'Heroine_win',
        attackSoundName: undefined,
        attackSoundDelay: 0,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: undefined,
        damageSoundDelay: undefined,
        isHeroAttack: undefined,
        isCountUp: true,
        fadeOutDelay: undefined,
        revival: true,
      },
    ],
  },
  Rival_Attack_Continue: {
    animation: [
      {
        animationName: 'Tiltup_Rival',
        attackSoundName: ISongs.BB_Rival,
        attackSoundDelay: 3500,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: undefined,
        damageSoundDelay: undefined,
        isHeroAttack: undefined,
        isCountUp: false,
        fadeOutDelay: undefined,
        revival: false,
      },
      {
        animationName: 'Rival_attack',
        attackSoundName: ISongs.BB_Atk3,
        attackSoundDelay: 500,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: undefined,
        damageSoundDelay: undefined,
        isHeroAttack: false,
        isCountUp: false,
        fadeOutDelay: undefined,
        revival: false,
      },
      {
        animationName: 'Rival_win',
        attackSoundName: undefined,
        attackSoundDelay: 0,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: ISongs.BB_Damage3,
        damageSoundDelay: 0,
        isHeroAttack: false,
        isCountUp: false,
        fadeOutDelay: 100,
        revival: false,
      },
      {
        animationName: 'Heroine_win',
        attackSoundName: undefined,
        attackSoundDelay: 0,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: undefined,
        damageSoundDelay: undefined,
        isHeroAttack: undefined,
        isCountUp: true,
        fadeOutDelay: undefined,
        revival: true,
      },
    ],
  },
  Rival_Attack_Sp_Avert: {
    animation: [
      {
        animationName: 'Tiltup_Rival',
        attackSoundName: ISongs.BB_Rival,
        attackSoundDelay: 3500,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: undefined,
        damageSoundDelay: undefined,
        isHeroAttack: undefined,
        isCountUp: false,
        fadeOutDelay: undefined,
        revival: false,
      },
      {
        animationName: 'Rival_attack_Sp',
        attackSoundName: ISongs.BB_Atk6,
        attackSoundDelay: 0,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: undefined,
        damageSoundDelay: undefined,
        isHeroAttack: false,
        isCountUp: false,
        fadeOutDelay: undefined,
        revival: false,
      },
      {
        animationName: 'Hero_dodge',
        attackSoundName: ISongs.BB_Hero,
        attackSoundDelay: 100,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: undefined,
        damageSoundDelay: undefined,
        isHeroAttack: true,
        isCountUp: true,
        fadeOutDelay: 100,
        revival: false,
      },
    ],
  },
  Rival_Attack_Avert: {
    animation: [
      {
        animationName: 'Tiltup_Rival',
        attackSoundName: ISongs.BB_Rival,
        attackSoundDelay: 3500,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: undefined,
        damageSoundDelay: undefined,
        isHeroAttack: undefined,
        isCountUp: false,
        fadeOutDelay: undefined,
        revival: false,
      },
      {
        animationName: 'Rival_attack',
        attackSoundName: ISongs.BB_Atk3,
        attackSoundDelay: 0,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: undefined,
        damageSoundDelay: undefined,
        isHeroAttack: false,
        isCountUp: false,
        fadeOutDelay: undefined,
        revival: false,
      },
      {
        animationName: 'Hero_dodge',
        attackSoundName: ISongs.BB_Hero,
        attackSoundDelay: 100,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: undefined,
        damageSoundDelay: undefined,
        isHeroAttack: true,
        isCountUp: true,
        fadeOutDelay: 100,
        revival: false,
      },
    ],
  },
  HERO_Attack: {
    animation: [
      {
        animationName: 'Tiltup_Hero',
        attackSoundName: ISongs.BB_Hero,
        attackSoundDelay: 3500,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: undefined,
        damageSoundDelay: undefined,
        isHeroAttack: undefined,
        isCountUp: false,
        fadeOutDelay: undefined,
        revival: false,
      },
      {
        animationName: 'Hero_attack',
        attackSoundName: ISongs.BB_Atk3,
        attackSoundDelay: 500,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: undefined,
        damageSoundDelay: undefined,
        isHeroAttack: true,
        isCountUp: false,
        fadeOutDelay: undefined,
        revival: false,
      },
      {
        animationName: 'Hero_win',
        attackSoundName: undefined,
        attackSoundDelay: 0,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: ISongs.BB_Damage3,
        damageSoundDelay: 0,
        isHeroAttack: true,
        isCountUp: true,
        fadeOutDelay: 100,
        revival: false,
      },
    ],
  },
  HERO_Attack_Sp: {
    animation: [
      {
        animationName: 'Tiltup_Hero',
        attackSoundName: ISongs.BB_Hero,
        attackSoundDelay: 4500,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: undefined,
        damageSoundDelay: undefined,
        isHeroAttack: undefined,
        isCountUp: false,
        fadeOutDelay: undefined,
        revival: false,
      },
      {
        animationName: 'Hero_attack_Sp',
        attackSoundName: ISongs.BB_Atk5,
        attackSoundDelay: 150,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: undefined,
        damageSoundDelay: undefined,
        isHeroAttack: true,
        isCountUp: false,
        fadeOutDelay: undefined,
        revival: false,
      },
      {
        animationName: 'Hero_win',
        attackSoundName: undefined,
        attackSoundDelay: 0,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: ISongs.BB_Damage3,
        damageSoundDelay: 0,
        isHeroAttack: true,
        isCountUp: true,
        fadeOutDelay: 100,
        revival: false,
      },
    ],
  },
  Special_Ending: {
    animation: [
      {
        animationName: 'Sp_ED',
        attackSoundName: ISongs.BB_Hero,
        attackSoundDelay: 200,
        attackSoundName2: ISongs.BB_Rival,
        attackSoundDelay2: 1000,
        damageSoundName: undefined,
        damageSoundDelay: 1500,
        isHeroAttack: undefined,
        isCountUp: false,
        fadeOutDelay: undefined,
        revival: false,
      },
      {
        animationName: 'Sp_ED_loop',
        attackSoundName: undefined,
        attackSoundDelay: undefined,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: undefined,
        damageSoundDelay: undefined,
        isHeroAttack: undefined,
        isCountUp: false,
        fadeOutDelay: undefined,
        revival: false,
      },
      {
        animationName: 'Sp_ED_loop',
        attackSoundName: undefined,
        attackSoundDelay: undefined,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: undefined,
        damageSoundDelay: undefined,
        isHeroAttack: undefined,
        isCountUp: false,
        fadeOutDelay: undefined,
        revival: false,
      },
      {
        animationName: 'Sp_ED_loop',
        attackSoundName: undefined,
        attackSoundDelay: undefined,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: undefined,
        damageSoundDelay: undefined,
        isHeroAttack: undefined,
        isCountUp: false,
        fadeOutDelay: undefined,
        revival: false,
      },
    ],
  },
};

class BattleBonusR6 extends PIXI.Container {
  private battleBonus: SpineAnimation | undefined;

  private battleType: BattleRound6AnimTypes;

  private battleBonusResultAnimationData: string;

  private attackIndex: number;

  private isResult: boolean;

  private isSlotStopped: boolean;

  private spEndSprite: PIXI.Sprite;

  private countUpFix: boolean;

  private fix: boolean;

  private soundDelay: Animation | null = null;

  private damageSoundDelay: Animation | null = null;

  constructor() {
    super();

    this.battleType = BattleRound6AnimTypes.RIVAL_ATTACK_END;
    this.battleBonusResultAnimationData = '';
    this.attackIndex = 0;
    this.battleBonus = undefined;
    this.isResult = false;
    this.isSlotStopped = false;
    this.spEndSprite = this.initSpEndSprite();
    this.countUpFix = false;
    this.fix = false;

    this.zIndex = Z_INDEX_BATTLE_BONUS_R6;

    eventManager.addListener(EventTypes.BATTLE_BONUS_INTRO_START, this.battleBonusAnimStart.bind(this));

    eventManager.addListener(EventTypes.BATTLE_BONUS_R6_INTRO_START, this.battleBonusR6AnimStart.bind(this));

    eventManager.addListener(EventTypes.BATTLE_BONUS_RESULT_END, this.battleBonusResultEnd.bind(this));

    eventManager.addListener(EventTypes.BATTLE_BONUS_RESULT, this.battleBonusResult.bind(this));

    eventManager.on(EventTypes.SLOT_MACHINE_STATE_CHANGE, this.onSlotMachineStateChange.bind(this));

    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));

    eventManager.addListener(EventTypes.BATTLE_BONUS_ANIM_END, this.battleBonusAnimEnd.bind(this));

    eventManager.addListener(EventTypes.HANDLE_UPDATE_BATTLE_BNS_GET, () => {
      if (this.battleBonus != undefined && this.battleBonus.getSpine().visible) {
        this.countUpFix = true;
        this.chkFix();
      }
    });

    eventManager.addListener(EventTypes.BATTLE_START_ANIM_START, this.BattleStartAnimStart.bind(this));

    eventManager.on(EventTypes.SET_IS_SLOTS_STOPPED, (isSlotStopped: boolean) => {
      if (setCurrentBonus().totalRounds % 6 === 0) {
        if (isBattleBonusMode(setGameMode()) && isSlotStopped) {
          this.isSlotStopped = true;
          setBattleBonusAnimSkip(true);
          this.chkSkipAnim();
          eventManager.emit(EventTypes.BATTLE_BONUS_ANIM_SKIP);
          this.soundDelay?.skip();
          this.damageSoundDelay?.skip();
        }
      }
    });
  }

  private initSpEndSprite(): PIXI.Sprite {
    const spEndSprite = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.spEd));
    spEndSprite.visible = false;

    return spEndSprite;
  }

  private initBattleBonusAnim(): void {
    this.battleBonus = new SpineAnimation({}, PIXI.Loader.shared.resources.BattleBonus_R6.spineData!);
    this.battleBonus!.getSpine().pivot.set(0.5, 0.5);
    this.battleBonus?.getSpine().position.set(BASE_GAME_CHARA_WINDOW_POS_X, BASE_GAME_CHARA_WINDOW_POS_Y);
    this.battleBonus!.setAnimation('Hero_attack', false);
    this.battleBonus!.getSpine().visible = false;
    // this.baseCharacterWindow!.spine.stateData.setMix(
    //   'idle',
    //   'characternearmiss',
    //   0.1,
    // );
    // this.baseCharacterWindow!.spine.stateData.setMix(
    //   'characternearmiss',
    //   'idle',
    //   0.1,
    // );

    this.battleBonus!.addOnComplete(() => {
      // console.log(
      //   'addOnComplete',
      //   performance.now(),
      //   'this.battleType',
      //   this.battleType,
      //   'this.attackIndex',
      //   this.attackIndex,
      // );

      // console.log('addOnComplete', performance.now());
      if (!this.isResult) {
        this.battleBonus!.setAnimation('Intro', false);
      } else {
        if (BattleAttackR6Animation[this.battleType].animation.length - 1 >= this.attackIndex) {
          this.battleBonusResultAnimationData =
            BattleAttackR6Animation[this.battleType].animation[this.attackIndex].animationName;

          if (BattleAttackR6Animation[this.battleType].animation[this.attackIndex].revival) {
            this.dummyResult();
            return;
          }

          this.bgmFade();

          if (
            this.battleBonusResultAnimationData === 'Tiltup_Hero' ||
            this.battleBonusResultAnimationData === 'Tiltup_Rival'
          ) {
            eventManager.emit(EventTypes.BATTLE_BONUS_BACK_R6_START, 'Tiltup_back');
          } else {
            eventManager.emit(EventTypes.BATTLE_BONUS_BACK_R6_END);
          }

          this.battleBonus!.setAnimation(this.battleBonusResultAnimationData, false);
          const isHeroAttack = BattleAttackR6Animation[this.battleType].animation[this.attackIndex].isHeroAttack;
          if (isHeroAttack != undefined) {
            eventManager.emit(EventTypes.BATTLE_BONUS_BACK_START, isHeroAttack);
          }

          const isCountUp = BattleAttackR6Animation[this.battleType].animation[this.attackIndex].isCountUp;
          if (isCountUp) {
            eventManager.emit(EventTypes.SET_STATE, SlotMachineState.JINGLE);
            // eventManager.emit(EventTypes.BATTLE_BONUS_COUNT_UP_START);
          }

          const attackSound = BattleAttackR6Animation[this.battleType].animation[this.attackIndex].attackSoundDelay;

          if (attackSound != undefined) {
            this.soundDelay = Tween.createDelayAnimation(attackSound);
            const sound = BattleAttackR6Animation[this.battleType].animation[this.attackIndex].attackSoundName;
            this.soundDelay.addOnComplete(() => {
              if (sound != undefined) {
                AudioHowl.play({
                  type: sound,
                });
              }
            });
            this.soundDelay.start();
          }

          this.phrasePlay(
            BattleAttackR6Animation[this.battleType].animation[this.attackIndex].attackSoundDelay2,
            BattleAttackR6Animation[this.battleType].animation[this.attackIndex].attackSoundName2,
          );

          const damageSound = BattleAttackR6Animation[this.battleType].animation[this.attackIndex].damageSoundDelay;

          if (damageSound != undefined) {
            this.damageSoundDelay = Tween.createDelayAnimation(damageSound);

            const sound = BattleAttackR6Animation[this.battleType].animation[this.attackIndex].damageSoundName;
            if (sound != undefined) {
              this.damageSoundDelay.addOnComplete(() => {
                AudioHowl.play({
                  type: sound,
                });
              });
            }
            this.damageSoundDelay.start();
          }

          if (this.battleType === BattleRound6AnimTypes.SPECIAL_ENDING) {
            eventManager.emit(EventTypes.BATTLE_BONUS_SP_BACK_START);

            if (BattleAttackR6Animation[this.battleType].animation.length - 1 === this.attackIndex) {
              eventManager.emit(EventTypes.SET_STATE, SlotMachineState.IDLE);
            }
          }

          this.attackIndex += 1;
        } else {
          this.attackIndex = 0;
          this.isResult = false;
          if (this.battleBonusResultAnimationData === 'Sp_ED') {
            eventManager.emit(EventTypes.BATTLE_BONUS_SP_BACK_END);
            this.battleBonus!.getSpine().visible = false;
            this.spEndSprite.visible = true;
          }

          if (this.battleType != BattleRound6AnimTypes.SPECIAL_ENDING) {
            this.fix = true;
          }

          if (
            this.battleType === BattleRound6AnimTypes.RIVAL_ATTACK_SP_END ||
            this.battleType === BattleRound6AnimTypes.RIVAL_ATTACK_END
          ) {
            this.countUpFix = true;
            // eventManager.emit(EventTypes.SET_STATE, SlotMachineState.IDLE);
          }
          this.chkFix();
        }
      }
    });

    this.battleBonus.getSpine().pivot.set(BATTLE_BONUS_R6_MAIN_POS_X, BATTLE_BONUS_R6_MAIN_POS_Y);
  }

  private dummyResult(): void {
    const callback = () => {
      // console.log(
      //   'addOnComplete',
      //   performance.now(),
      //   'this.battleType',
      //   this.battleType,
      //   'this.attackIndex',
      //   this.attackIndex,
      // );

      this.battleBonus!.setAnimation('Heroine_win', false);
      this.battleBonusResultAnimationData = 'Heroine_win';
      this.attackIndex = 4;
      AudioHowl.fadeOut(100, ISongs.BB_End);
      AudioHowl.play({
        type: ISongs.BB_Hero,
      });
      eventManager.emit(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER);
      eventManager.emit(EventTypes.SET_STATE, SlotMachineState.JINGLE);
    };
    const delay = Tween.createDelayAnimation(1000);
    delay.addOnComplete(() => {
      callback();
    });
    const battleNum = String(setCurrentBonus().totalRounds / 6);
    const delay1 = Tween.createDelayAnimation(0);
    delay1.addOnComplete(() => {
      eventManager.emit(EventTypes.SET_SLOT_BUSY_DISABLE);
      if (!setIsContinueAutoSpinsAfterFeature()) {
        eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, {
          totalWin: `${formatNumber(
            setCurrency(),
            normalizeCoins(setCurrentFreeSpinsTotalWin()),
            showCurrency(setCurrency()),
          )} `,
          bonusStreak: '', // TODO bonusStreakOrdinal,
          preventDefaultDestroy: true,
          callback,
          title: i18n.t('battleBonusTotalWinTitle'),
          battleText: i18n.t('battle'),
          battleNum: battleNum,
        });
      } else {
        eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, {
          totalWin: `${formatNumber(
            setCurrency(),
            normalizeCoins(setCurrentFreeSpinsTotalWin()),
            showCurrency(setCurrency()),
          )}`,
          bonusStreak: '', // TODO bonusStreakOrdinal,,
          preventDefaultDestroy: true,
          onInitCallback: () => delay.start(),
          title: i18n.t('battleBonusTotalWinTitle'),
          battleText: i18n.t('battle'),
          battleNum: battleNum,
        });
      }
    });
    delay1.start();
  }

  private chkFix(): void {
    if (this.fix && this.countUpFix) {
      eventManager.emit(EventTypes.SET_STATE, SlotMachineState.IDLE);
      this.fix = false;
      this.countUpFix = false;
    }
  }

  private battleBonusR6AnimStart(): void {
    eventManager.emit(EventTypes.WIN_TITLE_DISABLE);
    if (this.battleBonus === undefined) {
      this.initBattleBonusAnim();
      this.addChild(this.battleBonus!.spine);
      this.addChild(this.spEndSprite);
    }

    const scenario = (setBattleBonusRounds()[setCurrentBonus().totalRounds - 1] as Round6).scenario;

    const battleSet = Math.floor(setCurrentBonus().totalRounds / 6);

    if (
      (scenario === BattleRoundScenario.scenario_1 || scenario === BattleRoundScenario.scenario_2) &&
      battleSet >= SP_ENDING_ROUND
    ) {
      setIsSpEnding(true);
      this.battleBonus!.setAnimation('Intro_Sp', false);
      this.battleBonusResultAnimationData = 'Intro_Sp';
      this.battleType = BattleRound6AnimTypes.SPECIAL_ENDING;
      BgmControl.playBgm(BgmSoundTypes.BASE);
      eventManager.emit(EventTypes.BATTLE_BONUS_BACK_R6_START, 'idle_Sp');
      this.attackIndex = 0;
    } else {
      BgmControl.playBgm(BgmSoundTypes.BB2);
      this.battleBonus!.setAnimation('Intro', false);
      this.battleType = BattleRound6AnimTypes.HERO_ATTACK;
      eventManager.emit(EventTypes.BATTLE_BONUS_BACK_R6_START, 'idle');
      this.attackIndex = 0;
    }
    this.battleBonus!.start();

    this.battleBonus!.getSpine().visible = true;
    this.isResult = false;
  }

  private battleBonusResultEnd(scenario: BattleRoundScenario) {
    this.isResult = true;

    if (this.battleType != BattleRound6AnimTypes.SPECIAL_ENDING) {
      this.battleType = this.chgScenarioToBattleType(scenario);
    }

    this.chkSkipAnim();
  }

  private chkSkipAnim(): void {
    if (this.isSlotStopped && this.isResult) {
      this.isSlotStopped = false;
      if (this.attackIndex >= 3 || this.battleType === BattleRound6AnimTypes.SPECIAL_ENDING) {
        return;
      }

      this.attackIndex = 2;
      this.battleBonusResultAnimationData =
        BattleAttackR6Animation[this.battleType].animation[this.attackIndex].animationName;

      this.battleBonus!.setAnimation(this.battleBonusResultAnimationData, false);
      AudioHowl.stop({ type: ISongs.BB_Push });

      const attackSound = BattleAttackR6Animation[this.battleType].animation[this.attackIndex].attackSoundDelay;
      const isCountUp = BattleAttackR6Animation[this.battleType].animation[this.attackIndex].isCountUp;
      if (attackSound != undefined) {
        const soundDelay = Tween.createDelayAnimation(attackSound);
        const sound = BattleAttackR6Animation[this.battleType].animation[this.attackIndex].attackSoundName;
        soundDelay.addOnComplete(() => {
          if (sound != undefined) {
            AudioHowl.play({
              type: sound,
            });
          }
          if (isCountUp) {
            eventManager.emit(EventTypes.SET_STATE, SlotMachineState.JINGLE);
            // eventManager.emit(EventTypes.BATTLE_BONUS_COUNT_UP_START);
          }
        });
        this.phrasePlay(
          BattleAttackR6Animation[this.battleType].animation[this.attackIndex].damageSoundDelay,
          BattleAttackR6Animation[this.battleType].animation[this.attackIndex].damageSoundName,
        );

        soundDelay.start();
      }

      this.bgmFade();

      const isHeroAttack = BattleAttackR6Animation[this.battleType].animation[this.attackIndex].isHeroAttack;
      if (isHeroAttack != undefined) {
        eventManager.emit(EventTypes.BATTLE_BONUS_BACK_START, isHeroAttack);
      }

      this.attackIndex += 1;
    }
  }

  private phrasePlay(phraseDelay: number | undefined, phrase: ISongs | undefined): void {
    if (phraseDelay != undefined) {
      const soundDelay = Tween.createDelayAnimation(phraseDelay);
      soundDelay.addOnComplete(() => {
        if (phrase != undefined) {
          AudioHowl.play({
            type: phrase,
          });
        }
      });
      soundDelay.start();
    }
  }

  private bgmFade(): void {
    const fadeOutDelay = BattleAttackR6Animation[this.battleType].animation[this.attackIndex].fadeOutDelay;
    if (fadeOutDelay != undefined) {
      const bgmFadeOutDelay = Tween.createDelayAnimation(fadeOutDelay);
      bgmFadeOutDelay.addOnComplete(() => {
        BgmControl.fadeOutAll(1500);
      });
      bgmFadeOutDelay.start();

      const bgmStopDelay = Tween.createDelayAnimation(fadeOutDelay + 1500);
      bgmStopDelay.addOnComplete(() => {
        BgmControl.stopBgm();
      });
      bgmStopDelay.start();
      setBattleBonusBgmStop(true);
    }
  }

  private battleBonusAnimStart(): void {
    if (this.battleBonus != undefined) {
      destroySpine(this.battleBonus!);
      this.battleBonus = undefined;
      eventManager.emit(EventTypes.BATTLE_BONUS_BACK_END);
      eventManager.emit(EventTypes.BATTLE_BONUS_BACK_R6_END);
    }
  }

  private chgScenarioToBattleType(scenario: BattleRoundScenario): BattleRound6AnimTypes {
    let battleType = BattleRound6AnimTypes.RIVAL_ATTACK_SP_END;
    switch (scenario) {
      case BattleRoundScenario.scenario_1:
        battleType = BattleRound6AnimTypes.RIVAL_ATTACK_SP_END;
        break;
      case BattleRoundScenario.scenario_2:
        battleType = BattleRound6AnimTypes.RIVAL_ATTACK_END;
        break;
      case BattleRoundScenario.scenario_3:
        battleType = BattleRound6AnimTypes.RIVAL_ATTACK_SP_CONTINUE;
        break;
      case BattleRoundScenario.scenario_4:
        battleType = BattleRound6AnimTypes.RIVAL_ATTACK_CONTINUE;
        break;
      case BattleRoundScenario.scenario_5:
        battleType = BattleRound6AnimTypes.RIVAL_ATTACK_SP_AVERT;
        break;
      case BattleRoundScenario.scenario_6:
        battleType = BattleRound6AnimTypes.RIVAL_ATTACK_AVERT;
        break;
      case BattleRoundScenario.scenario_7:
        battleType = BattleRound6AnimTypes.HERO_ATTACK;
        break;
      case BattleRoundScenario.scenario_8:
        battleType = BattleRound6AnimTypes.HERO_ATTACK_SP;
        break;
      case BattleRoundScenario.scenario_9:
        battleType = BattleRound6AnimTypes.SPECIAL_ENDING;
        break;
    }

    return battleType;
  }

  private battleBonusResult(point: number): void {
    if (this.battleBonus != undefined) {
      destroySpine(this.battleBonus!);
      this.battleBonus = undefined;
    }
  }

  private onSlotMachineStateChange(state: SlotMachineState): void {
    if (state === SlotMachineState.SPIN) {
      this.isSlotStopped = false;
      setBattleBonusAnimSkip(false);
    } else if (state === SlotMachineState.IDLE) {
      setBattleBonusAnimSkip(false);
    }
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
  }): void {
    if (isBattleBonusMode(settings.mode)) {
      // this.initBattleBonusAnim();
    } else {
      if (this.battleBonus != undefined) {
        destroySpine(this.battleBonus!);
      }
      this.spEndSprite.visible = false;
      eventManager.emit(EventTypes.BATTLE_BONUS_BACK_END);
      eventManager.emit(EventTypes.BATTLE_BONUS_BACK_R6_END);
    }
  }

  private BattleStartAnimStart(): void {
    if (this.battleBonus != undefined) {
      this.battleBonus!.getSpine().visible = false;
    }
  }

  private battleBonusAnimEnd(): void {
    if (this.battleBonus != undefined) {
      destroySpine(this.battleBonus!);
      this.battleBonus!.getSpine().visible = false;
    }
  }
}
export default BattleBonusR6;
