import * as PIXI from 'pixi.js';

import { MessageBattleBonusBannerProps } from '../../global';
import { ResourceTypes } from '../../resources.d';
import { isMobileDevice, updateTextScale } from '../../utils';
import { BATTLE_BONUS_MESSAGE_ICON_SCALE, Z_INDEX_BANNER } from '../config';

import { BaseMessageBanner } from './baseMessageBanner';
import {
  bannerHeroTextStyle,
  bannerRivalTextStyle,
  btnTextStyle,
  descriptionTextStyle,
  titleTextStyle,
} from './textStyles';

export class MessageBattleBonusBanner extends BaseMessageBanner {
  protected props: MessageBattleBonusBannerProps;

  private title?: PIXI.Text;

  private description?: PIXI.Text;

  private btn?: PIXI.Text;

  private vsIcon?: PIXI.Sprite;

  private you?: PIXI.Text;

  private rival?: PIXI.Text;

  constructor(props: MessageBattleBonusBannerProps) {
    super(props);
    this.props = props;
    this.zIndex = Z_INDEX_BANNER;
  }

  public init(): MessageBattleBonusBanner {
    super.init();

    this.title = this.initTitle(this.props.title);
    this.description = this.initDescription(this.props.description);
    this.btn = this.initBtn(this.props.btnText);
    this.vsIcon = this.initVsIcon();
    this.you = this.inityouText(this.props.youText);
    this.rival = this.initRivalText(this.props.rivalText);
    this.addChild(this.title, this.description, this.btn, this.vsIcon, this.you, this.rival);
    return this;
  }

  private initTitle(titleText: string): PIXI.Text {
    const title = new PIXI.Text(titleText, titleTextStyle);
    title.resolution = 1;
    title.anchor.set(0.5, 0);
    title.y = 70;

    updateTextScale(title, this.banner!.width - 100, 450);

    return title;
  }

  private initDescription(descriptionText: string): PIXI.Text {
    const description = new PIXI.Text(descriptionText, descriptionTextStyle);
    description.resolution = 1;
    description.anchor.set(0.5, 0.5);
    description.y = 260;

    updateTextScale(description, this.banner!.width - 100, 450);

    return description;
  }

  private initBtn(btnText: string): PIXI.Text {
    const btn = new PIXI.Text(btnText, btnTextStyle);
    btn.resolution = 1;
    btn.anchor.set(0.5, 0);
    btn.y = 322;

    updateTextScale(btn, this.banner!.width - 100, 450);
    return btn;
  }

  private initVsIcon(): PIXI.Sprite {
    const sprite = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.messageBannerBbIcon));
    sprite.scale.set(BATTLE_BONUS_MESSAGE_ICON_SCALE);
    sprite.y = 430;
    sprite.x = -sprite.width / 2;
    // sprite.x = -400;

    return sprite;
  }

  private inityouText(btnText: string): PIXI.Text {
    const description = new PIXI.Text(btnText, bannerHeroTextStyle);
    description.resolution = 1;
    description.anchor.set(0.5, 0);
    description.x = -205;
    description.y = 605;

    return description;
  }

  private initRivalText(btnText: string): PIXI.Text {
    const description = new PIXI.Text(btnText, bannerRivalTextStyle);
    description.resolution = 1;
    description.anchor.set(0.5, 0);
    description.x = 225;
    description.y = 605;

    return description;
  }
}
