import _ from 'lodash';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs, ReelStopSndType, SlotId } from '../../config';
import { EventTypes, GameMode } from '../../global.d';
import { setAnticipationSlotId, setGameMode, setIsAnticipation, setStopReel } from '../../gql/cache';
import { getStopReel } from '../../utils';
import Animation from '../animations/animation';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  ADDITIONAL_SPIN_TIME_PER_REEL,
  ANTICIPATION_SLOTS_TINT,
  BASE_REEL_ENDING_DURATION,
  BASE_REEL_ENDING_FORMULA,
  BASE_REEL_ROLLING_DURATION,
  BASE_REEL_ROLLING_SPEED,
  BASE_REEL_STARTING_DURATION,
  BASE_REEL_STARTING_FORMULA,
  FAKE_ROLLING_DURATION,
  MINIMUM_SPIN_SLOTS_AMOUNT,
  REEL_ENDING_SLOTS_AMOUNT,
  REEL_STARTING_SLOTS_AMOUNT,
  REEL_WIDTH,
  ReelState,
  SLOT_HEIGHT,
  SPIN_REEL_ANIMATION_DELAY_PER_REEL,
  SlotMachineState,
  TURBO_ADDITIONAL_SPIN_TIME_PER_REEL,
  TURBO_REEL_ENDING_DURATION,
  TURBO_REEL_ROLLING_DURATION,
  TURBO_REEL_ROLLING_SPEED,
  TURBO_REEL_STARTING_DURATION,
  TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL,
  eventManager,
} from '../config';
import { Slot } from '../slot/slot';
import SpinAnimation from '../spin/spin';

import { IReel } from './d';

class Reel implements IReel {
  public id: number;

  public state: ReelState;

  public data: SlotId[];

  public container: ViewContainer;

  public position = 0;

  public previousPosition = 0;

  public spinAnimation: SpinAnimation | null = null;

  public slots: Slot[] = [];

  public animator: () => void = this.reelAnimator.bind(this);

  public isPlaySoundOnStop = false;

  public isTurboSpin = false;

  public size: number;

  public stopSoundSymbolNo = 0;

  public isLongSpin = false;

  public anticipationAnimation: Animation | undefined;

  constructor(id: number, data: SlotId[], startPosition: number) {
    this.id = id;
    this.data = data;
    this.size = data.length;
    this.state = ReelState.IDLE;
    this.container = new ViewContainer();
    this.container.width = REEL_WIDTH;
    this.container.x = id * REEL_WIDTH;
    this.container.y = 0;
    this.container.sortableChildren = true;
    this.createSlots();
    this.position = this.size - startPosition;
    eventManager.addListener(EventTypes.ANTICIPATION_STARTS, this.onAnticipationStart.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_START, this.onAnticipationAnimationStarts.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_END, this.resetSlotsTint.bind(this));
    eventManager.addListener(EventTypes.REELS_STOPPED, this.onReelsStopped.bind(this));
    eventManager.addListener(EventTypes.REMOVE_ANTICIPATION_TINT, this.resetSlotsTint.bind(this));
  }

  public init(data: SlotId[], position: number): void {
    this.data = data;
    this.size = data.length;
    this.createSlots();
    this.position = position;
  }

  public clean(): void {
    this.container.removeChildren();
    this.slots = [];
  }

  private onAnticipationAnimationStarts(): void {
    setIsAnticipation(true);
    this.slots.forEach((slot) => {
      if (
        !(slot.slotId === SlotId.H || slot.slotId === SlotId.I) &&
        !setAnticipationSlotId().some((id) => id === slot.slotId)
      ) {
        slot.tint = ANTICIPATION_SLOTS_TINT;
      }
    });
  }

  private onAnticipationStart(index: number): void {
    setIsAnticipation(true);
    if (this.id !== index) return;
    this.isLongSpin = true;
    AudioHowl.play({ type: ISongs.LongSpin, stopPrev: true });
  }

  private onReelsStopped(): void {
    this.resetSlotsTint();
  }

  private resetSlotsTint(): void {
    _.forEach(this.slots, (slot) => {
      slot.tint = 0xffffff;
    });
  }

  private createSlots(): void {
    for (let i = 0; i < this.data.length; i++) {
      const slotId = this.data[i % this.data.length];
      const slot = new Slot(i, slotId);
      this.slots.push(slot);
      this.container.addChild(slot);
    }
  }

  public getTarget(expected: number): number {
    if (expected - this.position > MINIMUM_SPIN_SLOTS_AMOUNT) {
      return expected;
    }
    let amount = expected - this.position;
    while (amount < MINIMUM_SPIN_SLOTS_AMOUNT) amount += this.data.length;
    return amount + this.position;
  }

  private getRollingDuration(gameMode: GameMode): number {
    const stopReel = getStopReel(this.id);
    if (this.isTurboSpin) {
      return TURBO_REEL_ROLLING_DURATION + stopReel * TURBO_ADDITIONAL_SPIN_TIME_PER_REEL;
    }

    return BASE_REEL_ROLLING_DURATION + stopReel * ADDITIONAL_SPIN_TIME_PER_REEL;
  }

  public createSpinAnimation(isTurboSpin: boolean | undefined): SpinAnimation {
    this.position %= this.data.length;
    this.isTurboSpin = !!isTurboSpin;
    const rollingSpeed = isTurboSpin ? TURBO_REEL_ROLLING_SPEED : BASE_REEL_ROLLING_SPEED;
    const rollingTime = this.getRollingDuration(setGameMode());
    const target = this.position + Math.round(rollingTime * rollingSpeed);

    // console.log(
    //   'this.id',
    //   this.id,
    //   'target',
    //   target,
    //   'this.position',
    //   this.position,
    //   'rollingTime',
    //   rollingTime,
    //   'rollingSpeed',
    //   rollingSpeed,
    // );

    const id = getStopReel(this.id);

    const starting = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: this.position,
      target: this.position + REEL_STARTING_SLOTS_AMOUNT,
      easing: BASE_REEL_STARTING_FORMULA,
      delay: isTurboSpin ? TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL : SPIN_REEL_ANIMATION_DELAY_PER_REEL,
      duration: isTurboSpin
        ? TURBO_REEL_STARTING_DURATION + SPIN_REEL_ANIMATION_DELAY_PER_REEL * this.id
        : BASE_REEL_STARTING_DURATION + SPIN_REEL_ANIMATION_DELAY_PER_REEL * this.id,
    });
    starting.addOnStart(() => {
      setStopReel({ reelPos: 0, time: 0 });
      this.changeState(ReelState.STARTING);
      // console.log(
      //   'this.id',
      //   this.id,
      //   'starting Start ',
      //   performance.now(),
      //   'delay',
      //   isTurboSpin
      //     ? TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL +
      //         SPIN_REEL_ANIMATION_DELAY_PER_REEL * id
      //     : SPIN_REEL_ANIMATION_DELAY_PER_REEL +
      //         SPIN_REEL_ANIMATION_DELAY_PER_REEL * id,
      // );
    });
    starting.addOnComplete(() => {
      // console.log('this.id', this.id, 'starting Complete ', performance.now());
    });
    const fakeRolling = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: this.position + REEL_STARTING_SLOTS_AMOUNT,
      target: this.position + REEL_STARTING_SLOTS_AMOUNT + Math.round(FAKE_ROLLING_DURATION * rollingSpeed),
      duration: FAKE_ROLLING_DURATION,
    });
    fakeRolling.addOnStart(() => {
      this.changeState(ReelState.ROLLING);
      // console.log(
      //   'this.id',
      //   this.id,
      //   'fakeRolling Start ',
      //   performance.now(),
      //   'propertyBeginValue',
      //   this.position + REEL_STARTING_SLOTS_AMOUNT,
      //   'target',
      //   this.position +
      //     REEL_STARTING_SLOTS_AMOUNT +
      //     Math.round(FAKE_ROLLING_DURATION * rollingSpeed),
      // );
    });
    fakeRolling.addOnComplete(() => {
      // console.log(
      //   'this.id',
      //   this.id,
      //   'fakeRolling Complete ',
      //   performance.now(),
      // );
    });
    const rolling = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: this.position + REEL_STARTING_SLOTS_AMOUNT,
      target: target - REEL_ENDING_SLOTS_AMOUNT,
      duration: rollingTime,
    });
    rolling.addOnStart(() => {
      if (setStopReel().time === 0) {
        // console.log('rolling ', this.id);
        setStopReel({ reelPos: this.id, time: performance.now() });
      } else {
        // console.log(
        //   '1.this.id',
        //   this.id,
        //   setStopReel().time - performance.now(),
        //   'rollingTime',
        //   rolling.duration,
        //   'rollingTime tyo',
        //   rolling.duration + (setStopReel().time - performance.now()),
        // );

        rolling.duration = rolling.duration + (setStopReel().time - performance.now());
      }
      // console.log(
      //   'this.id',
      //   this.id,
      //   'rolling Start ',
      //   performance.now(),
      //   'target',
      //   target,
      //   'rollingTime',
      //   rolling.duration,
      // );
    });
    rolling.addOnComplete(() => {
      // console.log('this.id', this.id, 'rolling Complete ', performance.now());
    });

    const ending = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: target - REEL_ENDING_SLOTS_AMOUNT,
      target,
      easing: BASE_REEL_ENDING_FORMULA,
      duration: isTurboSpin ? TURBO_REEL_ENDING_DURATION : BASE_REEL_ENDING_DURATION,
    });
    ending.addOnStart(() => {
      this.changeState(ReelState.ENDING);

      // console.log(
      //   'this.id',
      //   this.id,
      //   'ending Start ',
      //   performance.now(),
      //   'propertyBeginValue',
      //   target - REEL_ENDING_SLOTS_AMOUNT,
      //   'target',
      //   target,
      //   'BASE_REEL_ENDING_FORMULA',
      //   BASE_REEL_ENDING_FORMULA,
      // );
    });
    ending.addOnComplete(() => {
      this.changeState(ReelState.IDLE);
      this.onReelStop();
      // console.log('this.id', this.id, 'ending Complete ', performance.now());
    });
    this.spinAnimation = new SpinAnimation({
      startingAnimation: starting,
      fakeRollingAnimation: fakeRolling,
      rollingAnimation: rolling,
      endingAnimation: ending,
    });
    return this.spinAnimation;
  }

  private onReelEnding(previousState: ReelState, newState: ReelState): void {}

  private onReelStop(): void {
    this.anticipationAnimation?.skip();
    this.anticipationAnimation = undefined;
    if (this.isLongSpin) {
      AudioHowl.stop({ type: ISongs.LongSpin });
    }
    this.isLongSpin = false;

    if (this.isPlaySoundOnStop) {
      const stopSoundList = [ISongs.BonusStop_1, ISongs.BonusStop_2, ISongs.BonusStop_BB, ISongs.BonusStop_HBB];
      if (
        this.stopSoundSymbolNo >= ReelStopSndType.BonusStop1 &&
        this.stopSoundSymbolNo <= ReelStopSndType.HyperBattleBonusStop
      ) {
        AudioHowl.play({
          type: stopSoundList[this.stopSoundSymbolNo - ReelStopSndType.BonusStop1],
          stopPrev: true,
        });
      } else {
        const stopSoundList = [ISongs.SFX_UI_SpinStop, ISongs.SFX_UI_SpinStop, ISongs.SFX_UI_SpinStop];
        AudioHowl.play({
          // type: ISongs.SFX_UI_SpinStop,
          type: stopSoundList[Math.floor(Math.random() * stopSoundList.length)],
          stopPrev: true,
        });
      }
      this.isPlaySoundOnStop = false;
      this.stopSoundSymbolNo = 0;
    }
  }

  private onReelIdle(previousState: ReelState, newState: ReelState): void {
    if (previousState === ReelState.ENDING) {
      eventManager.emit(EventTypes.REEL_STOPPED, this.id);
      const reelStopSlots = this.getReelStopSlots(Math.round(this.position));
      _.forEach(reelStopSlots, (slot) => {
        slot.onSlotStopped();
      });

      if (this.id === 1) {
        eventManager.emit(EventTypes.SET_STATE, SlotMachineState.STOP);
      }
    }
  }

  public stopReel(endingDuration: number): void {
    this.spinAnimation!.getStarting().end();
    this.spinAnimation!.getFakeRolling().end();
    this.spinAnimation!.getRolling().end();
    this.spinAnimation!.getEnding().duration = endingDuration;
  }

  private getReelStopSlots(position: number): Array<Slot> {
    const slots: Array<Slot> = [];
    const top = this.slots.length - ((position % this.slots.length) + 1);
    const middle = position % this.slots.length === 0 ? 0 : this.slots.length - (position % this.slots.length);
    const bottom = (this.slots.length - ((position % this.slots.length) - 1)) % this.slots.length;
    const extra = (this.slots.length - ((position % this.slots.length) - 2)) % this.slots.length;
    slots.push(this.slots[top]);
    slots.push(this.slots[middle]);
    slots.push(this.slots[bottom]);
    slots.push(this.slots[extra]);
    return slots;
  }

  private onReelRolling(previousState: ReelState, newState: ReelState): void {}

  private onReelStarting(previousState: ReelState, newState: ReelState): void {}

  public changeState(newState: ReelState): void {
    const previousState = this.state;
    this.state = newState;
    if (newState === ReelState.IDLE) {
      this.onReelIdle(previousState, ReelState.IDLE);
    }
    if (newState === ReelState.ROLLING) {
      this.onReelRolling(previousState, ReelState.ROLLING);
    }
    if (newState === ReelState.STARTING) {
      this.onReelStarting(previousState, ReelState.STARTING);
    }
    if (newState === ReelState.ENDING) {
      this.onReelEnding(previousState, ReelState.ENDING);
    }
  }

  public reelAnimator(): void {
    this.previousPosition = this.position;
    // Update symbol positions on reel.
    for (let j = 0; j < this.slots.length; j++) {
      const slot = this.slots[j];
      slot.y = ((this.position + j + 2) % this.slots.length) * SLOT_HEIGHT - SLOT_HEIGHT;
      slot.toggleBlur(this.state === ReelState.ROLLING);
    }
  }
}

export default Reel;
