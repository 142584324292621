import { useMutation } from '@apollo/client';
import * as _ from 'lodash';
import React, { useEffect } from 'react';

import {
  EventTypes,
  FeatureTypes,
  IBonus,
  IBuyBonusesInput,
} from '../../global.d';
import {
  setBonuses,
  setCoinAmount,
  setCoinValue,
  setCurrentBonus,
  setCurrentBonusId,
} from '../../gql/cache';
import { buyBonusGql } from '../../gql/mutation';
import { isStoppedGql } from '../../gql/query';
import { eventManager } from '../../slotMachine/config';

const BuyFeature: React.FC = () => {
  const [buyBonus, { client }] = useMutation(buyBonusGql, {
    onCompleted(data: { buyBonus: { id: string } }) {
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: false,
        },
      });
      setCurrentBonusId(data.buyBonus.id);
      eventManager.emit(EventTypes.START_BUY_FEATURE_ROUND);
    },
  });

  useEffect(() => {
    eventManager.on(EventTypes.HANDLE_BUY_BONUS, (bonusId: string) => {
      const bonuses = setBonuses();
      const bonus = _.chain(bonuses)
        .filter(
          (bonus) =>
            bonus.type === FeatureTypes.SPECIAL_ROUND && bonus.id === bonusId,
        )
        .get(0, {})
        .value() as IBonus;
      buyBonus({
        variables: {
          input: {
            id: bonus.id,
            coinValue: setCoinValue(),
            coinAmount: setCoinAmount(),
          },
        },
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default BuyFeature;
