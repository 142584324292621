import _ from 'lodash';
import * as PIXI from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import SlotMachine from '..';
import {
  ISongs,
  MAPPED_SYMBOLS,
  MAPPED_SYMBOLS_STOP_ANIMATIONS,
  MAPPED_SYMBOLS_STOP_ANIMATIONS_H_REN,
  MAPPED_SYMBOLS_STOP_ANIMATIONS_I_REN,
  SlotId,
  StopAnimationRen,
} from '../../config';
import { EventTypes } from '../../global.d';
import { setAnticipationLine, setGameMode, setIsAnticipation, setNextResult, setSlotConfig } from '../../gql/cache';
import { destroySpine } from '../../utils';
import Animation from '../animations/animation';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  ANTICIPATION_SLOTS_TINT,
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  REELS_AMOUNT,
  REEL_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  SLOT_SCALE,
  SLOT_WIDTH,
  Z_INDEX_SLOTS_STOP_DISPLAY,
  eventManager,
} from '../config';
import { Icon } from '../d';

export class SlotsStopDisplayContainer extends ViewContainer {
  private stopSymbolAnimations: Animation[] = [];

  private slotSprites: PIXI.Sprite[] = [];

  constructor(spinResult: Icon[]) {
    super();
    this.sortableChildren = true;
    this.width = GAME_CONTAINER_WIDTH;
    this.height = GAME_CONTAINER_HEIGHT;
    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      for (let j = 0; j < REELS_AMOUNT; j++) {
        const sprite = new PIXI.Sprite(PIXI.Texture.from(MAPPED_SYMBOLS[spinResult[i * REELS_AMOUNT + j].id]));
        sprite.width = SLOT_WIDTH * SLOT_SCALE;
        sprite.height = SLOT_HEIGHT * SLOT_SCALE;
        sprite.anchor.set(0.5, 0.5);
        sprite.x = REEL_WIDTH * j + REEL_WIDTH / 2;
        sprite.y = SLOT_HEIGHT * i + SLOT_HEIGHT / 2;
        this.addChild(sprite);
        this.slotSprites.push(sprite);
      }
    }
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.skipStopSymbolAnimations.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_START, this.onAnticipationAnimationStarts.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_END, this.resetSlotsTint.bind(this));
    eventManager.addListener(EventTypes.HIDE_STOP_SLOTS_DISPLAY, this.hideContainer.bind(this));
    eventManager.addListener(EventTypes.SHOW_STOP_SLOTS_DISPLAY, this.showSlotStops.bind(this));
    eventManager.addListener(EventTypes.REEL_STOPPED, this.onReelStopped.bind(this));
    eventManager.addListener(EventTypes.JINGLE_START, this.skipScatterAnnounce.bind(this));
    eventManager.addListener(EventTypes.SET_SLOTS_VISIBILITY, this.handleSetSlotsVisibility.bind(this));
    eventManager.addListener(EventTypes.SLOT_STOP_DISPLAY_HIDE, this.hideSlot.bind(this));
    this.zIndex = Z_INDEX_SLOTS_STOP_DISPLAY;
  }

  private skipScatterAnnounce(): void {
    this.stopSymbolAnimations.forEach((animation) => animation.skip());
  }

  private onAnticipationAnimationStarts(): void {
    this.slotSprites.forEach((slot, index) => {
      const slotId = setNextResult()!.bet.result.spinResult[index].id;

      const anticipationLines: number[] = [];

      setAnticipationLine().forEach((anticipationLine) => {
        setSlotConfig().lines[anticipationLine].forEach((icon) => {
          anticipationLines.push(icon);
        });
      });

      if (anticipationLines.some((id) => id === index)) {
        slot.zIndex = 3;
      } else {
        slot.tint = ANTICIPATION_SLOTS_TINT;
      }
    });
  }

  private resetSlotsTint(): void {
    this.slotSprites.forEach((slot) => {
      slot.tint = 0xffffff;
    });
  }

  private onReelStopped(reelId: number): void {
    eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, setNextResult()!.bet.result.spinResult, false, reelId);
    this.startOnSymbolsStopAnimations(reelId);

    if (reelId === 1) {
      setIsAnticipation(false);
    }
  }

  private startOnSymbolsStopAnimations(reelId: number): void {
    // TODO: Refactor
    if (reelId === 0) this.stopSymbolAnimations = [];

    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      const slotId = setNextResult()?.bet.result.spinResult[i * REELS_AMOUNT + reelId].id;
      if (slotId === SlotId.H || slotId === SlotId.I) {
        let animationData: StopAnimationRen;
        if (slotId === SlotId.H) {
          animationData = MAPPED_SYMBOLS_STOP_ANIMATIONS_H_REN[setGameMode()];
        } else {
          animationData = MAPPED_SYMBOLS_STOP_ANIMATIONS_I_REN[setGameMode()];
        }

        if (!animationData || !animationData.src || !animationData.animation) throw Error('INVALID SPINE DATA');
        const animation = new SpineAnimation({}, PIXI.Loader.shared.resources[animationData.src].spineData);
        const dummy = Tween.createDelayAnimation(1000);
        dummy.addOnStart(() => {
          animation.getSpine().zIndex = 100;
          animation.spine.y = SLOT_HEIGHT / 2 + SLOT_HEIGHT * i;
          animation.spine.x = REEL_WIDTH / 2 + REEL_WIDTH * reelId;
          this.addChild(animation.getSpine());
          animation.setAnimation(animationData.animation!, false);
          this.slotSprites[i * REELS_AMOUNT + reelId].visible = false;
        });

        dummy.addOnComplete(() => {
          destroySpine(animation);
          this.removeChild(animation.spine);
          this.slotSprites[i * REELS_AMOUNT + reelId].visible = true;

          if (!SlotMachine.getInstance().hasWin()) {
            eventManager.emit(EventTypes.CREATE_RESPIN_MESSAGE);
          }
        });

        dummy.addOnSkip(() => {
          destroySpine(animation);
          this.removeChild(animation.spine);
          this.slotSprites[i * REELS_AMOUNT + reelId].visible = true;
        });
        dummy.addOnChange(() => {
          if (setIsAnticipation()) {
            animation.spine.tint = ANTICIPATION_SLOTS_TINT;
          }
          if (!setIsAnticipation()) {
            animation.spine.tint = 0xffffff;
          }
        });
        this.stopSymbolAnimations.push(dummy);
        AudioHowl.play({ type: ISongs.RP_Stop });
        dummy.start();
      } else {
        if (slotId && MAPPED_SYMBOLS_STOP_ANIMATIONS[slotId]) {
          const animationData = MAPPED_SYMBOLS_STOP_ANIMATIONS[slotId];
          if (!animationData || !animationData.src || !animationData.animation) throw Error('INVALID SPINE DATA');
          const animation = new SpineAnimation({}, PIXI.Loader.shared.resources[animationData.src].spineData);
          const dummy = Tween.createDelayAnimation(1000);
          dummy.addOnStart(() => {
            animation.spine.y = SLOT_HEIGHT / 2 + SLOT_HEIGHT * i;
            animation.spine.x = REEL_WIDTH / 2 + REEL_WIDTH * reelId;
            this.addChild(animation.getSpine());
            animation.setAnimation(animationData.animation!, false);
            this.slotSprites[i * REELS_AMOUNT + reelId].visible = false;
          });

          dummy.addOnComplete(() => {
            destroySpine(animation);
            if (reelId === 1) {
              setIsAnticipation(false);
            }
            this.removeChild(animation.spine);
            this.slotSprites[i * REELS_AMOUNT + reelId].visible = true;
          });

          dummy.addOnSkip(() => {
            destroySpine(animation);
            setIsAnticipation(false);
            this.removeChild(animation.spine);
            this.slotSprites[i * REELS_AMOUNT + reelId].visible = true;
          });

          dummy.addOnChange(() => {
            const anticipationLines: number[] = [];
            setAnticipationLine().forEach((anticipationLine) => {
              setSlotConfig().lines[anticipationLine].forEach((icon, index) => {
                if (index != 1) anticipationLines.push(icon);
              });
            });
            if (anticipationLines.some((id) => id === reelId + i * REELS_AMOUNT) || reelId === 1) {
            } else {
              animation.spine.tint = ANTICIPATION_SLOTS_TINT;
            }

            if (!setIsAnticipation()) {
              animation.spine.tint = 0xffffff;
            }
          });
          this.stopSymbolAnimations.push(dummy);
          dummy.start();
        }
      }
    }
  }

  private skipStopSymbolAnimations(): void {
    this.stopSymbolAnimations.forEach((animation) => animation.skip());
    this.stopSymbolAnimations = [];
  }

  private handleSetSlotsVisibility(slots: number[], visible: boolean): void {
    for (let i = 0; i < slots.length; i++) {
      this.slotSprites[slots[i]].visible = visible;
    }
  }

  private showSlotStops(spinResult: Icon[], constructorFlg: boolean, reelId?: number): void {
    this.visible = true;

    if (constructorFlg === true) return;

    if (reelId !== undefined) {
      for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
        this.slotSprites[i * REELS_AMOUNT + reelId].texture = PIXI.Texture.from(
          MAPPED_SYMBOLS[spinResult[i * REELS_AMOUNT + reelId].id],
        );
        this.slotSprites[i * REELS_AMOUNT + reelId].visible = true;
      }
    } else {
      for (let i = 0; i < spinResult.length; i++) {
        this.slotSprites[i].texture = PIXI.Texture.from(MAPPED_SYMBOLS[spinResult[i].id]);
        this.slotSprites[i].visible = true;
      }
    }
  }

  private hideContainer(): void {
    this.visible = false;
    this.slotSprites.forEach((sprite) => (sprite.visible = false));
  }

  private hideSlot(slots: number): void {
    this.slotSprites[slots].visible = false;
    this.skipStopSymbolAnimations();
  }
}
