import { ResourceTypes } from '../resources.d';

export const introContents = [
  {
    img: ResourceTypes.intro01,
    txtKeyBottom: '',
  },
  {
    img: ResourceTypes.intro02,
    txtKeyBottom: 'introTitle.page2.Bottom',
  },
  {
    img: ResourceTypes.intro03,
    txtKeyBottom: 'introTitle.page3.Bottom',
  },
  {
    img: ResourceTypes.intro04,
    txtKeyBottom: 'introTitle.page4.Bottom',
  },
];
