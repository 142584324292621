import { ResourceTypes } from '../resources.d';

export const config = {
  failureRetries: 3,
  autoplay: {
    stopOnWinExceedsMinValue: 0,
    stopOnBalanceIncreaseMinValue: 0,
    stopOnBalanceDecreaseMinValue: 0,
    stopOnWinExceedsMaxValue: 500,
    stopOnBalanceIncreaseMaxValue: 5000,
    stopOnBalanceDecreaseMaxValue: 1000,
    defaultValues: {
      isStopOnAnyWin: false,
      isStopOnWinExceeds: false,
      isStopOnBalanceIncrease: false,
      isStopOnBalanceDecrease: false,
      singleWinValue: 25,
      increaseValue: 25,
      decreaseValue: 25,
    },
    autoSpinValues: [25, 50, 75, 100, 500],
    timeOut: 1000,
  },
  betSettings: {
    mockedBets: [25, 50, 75, 100],
    minBet: 25,
  },
  historyTable: {
    showBy: 20,
    head: {
      gameId: 'Game ID',
      date: 'date',
      // balanceStart: 'Start balance',
      bet: 'bet',
      win: 'win',
      // balanceEnd: 'End Balance',
      // detail: 'Detail',
    },
  },
  specialPayTable: [
    {
      slug: ResourceTypes.symbolAGold7,
      awardText: 'infoAGold7',
      awardText2: 'infoAGold7_2',
    },
    {
      slug: ResourceTypes.symbolBRed7,
      awardText: 'infoBRed7',
      awardText2: 'infoBRed7_2',
    },
    {
      slug: ResourceTypes.symbolCRespin,
      awardText: 'infoCRespin',
      awardText2: 'infoCRespin2',
    },
    {
      slug: ResourceTypes.symbolGArmsRe,
      awardText: 'infoDRespin',
      awardText2: 'infoDRespin2',
    },
  ],
  payTableData: [
    {
      slug: ResourceTypes.symbolDHero,
      combos: [{ pattern: 'x3', multiplier: 200 }],
    },
    {
      slug: ResourceTypes.symbolEHeroine,
      combos: [{ pattern: 'x3', multiplier: 150 }],
    },
    {
      slug: ResourceTypes.symbolFRival,
      combos: [{ pattern: 'x3', multiplier: 150 }],
    },
  ],
  payTableMixData: [
    {
      slug: [
        ResourceTypes.symbolDHero,
        ResourceTypes.symbolEHeroine,
        ResourceTypes.symbolFRival,
      ],
      combos: [{ pattern: 'x3', multiplier: 5 }],
      awardText: 'anyMixed',
    },
    {
      slug: [ResourceTypes.symbolGArms, ResourceTypes.symbolGArmsRe],
      combos: [{ pattern: 'x3', multiplier: 50 }],
      awardText: 'anyMixed',
    },
  ],
  payTableNormalData: [
    {
      slug: ResourceTypes.symbolHLow1,
      combos: [{ pattern: 'x3', multiplier: 20 }],
    },
    {
      slug: ResourceTypes.symbolILow2,
      combos: [{ pattern: 'x3', multiplier: 15 }],
    },
    {
      slug: ResourceTypes.symbolJLow3,
      combos: [{ pattern: 'x3', multiplier: 10 }],
    },
  ],
  miniPayTableData: [
    {
      slug: ResourceTypes.symbolDHero,
      combos: [{ pattern: 'x3', multiplier: 200 }],
    },
    {
      slug: ResourceTypes.symbolEHeroine,
      combos: [{ pattern: 'x3', multiplier: 150 }],
    },
    {
      slug: ResourceTypes.symbolFRival,
      combos: [{ pattern: 'x3', multiplier: 150 }],
    },
    {
      slug: ResourceTypes.symbolGArms,
      combos: [{ pattern: 'x3', multiplier: 50 }],
    },
    {
      slug: ResourceTypes.symbolGArmsRe,
      combos: [{ pattern: 'x3', multiplier: 50 }],
    },
    {
      slug: ResourceTypes.symbolHLow1,
      combos: [{ pattern: 'x3', multiplier: 20 }],
    },
    {
      slug: ResourceTypes.symbolILow2,
      combos: [{ pattern: 'x3', multiplier: 15 }],
    },
    {
      slug: ResourceTypes.symbolJLow3,
      combos: [{ pattern: 'x3', multiplier: 10 }],
    },
  ],
};

export enum SlotId {
  J = 'J', // G7
  K = 'K', // R7
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H', // RP
  I = 'I', // DRP
}

export enum SlotIdH {
  H_1 = 'H_1',
  H_2 = 'H_2',
  H_3 = 'H_3',
  H_4 = 'H_4',
}

export enum SlotIdI {
  I_1 = 'I_1',
  I_2 = 'I_2',
  I_3 = 'I_3',
  I_4 = 'I_4',
}
export enum ReelStopSndType {
  Normal,
  BonusStop1,
  BonusStop2,
  BattleBonusStop,
  HyperBattleBonusStop,
}

export const SC_REELS = [0, 2, 4];
export const ANTICIPATION_START_REELID = 3;
export const ANTICIPATION_START_SCCNT = 2;

export const BB_BGM_2 = 6;
