// eslint-disable-next-line
import * as PIXI from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs } from '../../config';
import {
  BattleBnsTitleProps,
  EventTypes,
  GameMode,
  MessageBattleBonusBannerProps,
  MessageWinBannerProps,
} from '../../global.d';
import { isBattleBonusMode, isBuyFeatureMode } from '../../utils';
import Tween from '../animations/tween';
import BattleBonusPanel from '../battleBonusPanel/battleBonusPanel';
import BattleStart from '../battleStart/battleStart';
import ViewContainer from '../components/container';
import {
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  LINES_NUMBER_CONTAINER_WIDTH,
  SHOW_LINES_NUMBER_CONTAINER,
  SLOTS_BACKGROUND_HEIGHT,
  SLOTS_BACKGROUND_WIDTH,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  Z_INDEX_GAME_VIEW,
  Z_INDEX_REELS_FRAME,
  eventManager,
} from '../config';
import { IGameContainer } from '../d';
import { MessageBattleBonusBanner } from '../messageBanner/messageBattleBonusBanner';
import { MessageWinBanner } from '../messageBanner/messageWinBanner';
import NearmissContainer from '../nearmissContainer/nearmissContainer';
import ReSpinMessage from '../reSpinMessage/reSpinMessage';
import ReelsFrame from '../reels/frame/reelsFrame';
import RespinStart from '../respinStart/respinStart';
import CoinsAnimationContainer from '../winAnimations/coinsAnimationContainer';

import GameReplay from './gameReplay';
import GameTitle from './gameTitle';

class GameView extends ViewContainer {
  public winSlotsContainer: PIXI.Container;

  public miniPayTableContainer: PIXI.Container;

  public reelsBackgroundContainer: PIXI.Container;

  public battleBnsUpperBackgroundContainer: PIXI.Container;

  public reelsContainer: PIXI.Container;

  public tintContainer: PIXI.Container;

  public slotsContainer: PIXI.Container;

  public winLabelContainer: PIXI.Container;

  public slotStopDisplayContainer: PIXI.Container;

  public winCountUpMessage: PIXI.Container;

  public gameTitle: PIXI.Container;

  public gameReplay: PIXI.Container;

  public coinsAnimationContainer: PIXI.Container;

  public frame: ReelsFrame;

  public baseGameCharaWindow: PIXI.Container;

  public battleBonusContainer: PIXI.Container;

  public battleBonusPanel: BattleBonusPanel | undefined;

  private nearmissRespin: NearmissContainer;

  private respin: RespinStart;

  private reSpinMessage: ReSpinMessage;

  private messageWinBanner: MessageWinBanner | undefined;

  private battleStart: BattleStart;

  constructor(props: IGameContainer) {
    super();
    this.sortableChildren = true;
    this.width = GAME_CONTAINER_WIDTH;
    this.height = GAME_CONTAINER_HEIGHT;
    this.slotsContainer = new PIXI.Container();
    this.slotsContainer.width = SLOTS_CONTAINER_WIDTH;
    this.slotsContainer.height = SLOTS_CONTAINER_HEIGHT;
    this.slotsContainer.x = SHOW_LINES_NUMBER_CONTAINER ? LINES_NUMBER_CONTAINER_WIDTH : 0;
    this.slotsContainer.sortableChildren = true;
    this.slotsContainer.scale.set(1, 1);
    this.slotsContainer.interactive = true;
    this.slotsContainer.zIndex = Z_INDEX_GAME_VIEW;
    this.gameTitle = new GameTitle();
    this.gameReplay = new GameReplay();
    this.coinsAnimationContainer = new CoinsAnimationContainer();
    this.winLabelContainer = props.winLabelContainer;
    this.winSlotsContainer = props.winSlotsContainer;
    this.winSlotsContainer.y = this.slotsContainer.y;
    this.slotStopDisplayContainer = props.slotStopDisplayContainer;
    this.miniPayTableContainer = props.miniPayTableContainer;
    this.tintContainer = props.tintContainer;
    this.reelsBackgroundContainer = props.reelsBackgroundContainer;
    this.battleBnsUpperBackgroundContainer = props.battleBnsUpperBackgroundContainer;
    this.reelsContainer = props.reelsContainer;
    this.winCountUpMessage = props.winCountUpMessage;
    this.baseGameCharaWindow = props.baseGameCharaWindow;
    this.battleBonusContainer = props.battleBonusContainer;

    this.slotsContainer.addChild(this.tintContainer);
    this.slotsContainer.addChild(this.reelsContainer);
    this.slotsContainer.mask = new PIXI.Graphics()
      .beginFill(0xffffff)
      .drawRect(
        (SLOTS_CONTAINER_WIDTH - SLOTS_BACKGROUND_WIDTH) / 2,
        (SLOTS_CONTAINER_HEIGHT - SLOTS_BACKGROUND_HEIGHT) / 2,
        SLOTS_BACKGROUND_WIDTH,
        SLOTS_BACKGROUND_HEIGHT,
      )
      .endFill();
    this.slotsContainer.addChild(this.slotsContainer.mask);
    this.addChild(this.reelsBackgroundContainer);
    this.addChild(this.battleBnsUpperBackgroundContainer);
    this.addChild(this.slotsContainer);
    this.addChild(this.slotStopDisplayContainer);
    this.addChild(this.miniPayTableContainer);
    this.frame = new ReelsFrame();
    this.addChild(this.baseGameCharaWindow);
    this.addChild(this.battleBonusContainer);
    this.battleStart = new BattleStart();
    this.addChild(this.battleStart);
    this.addChild(this.frame);
    this.addChild(this.winSlotsContainer);
    this.addChild(this.gameTitle);
    this.addChild(this.gameReplay);
    this.addChild(this.coinsAnimationContainer);
    this.addChild(this.winLabelContainer);
    this.addChild(this.winCountUpMessage);

    this.nearmissRespin = new NearmissContainer();
    this.respin = new RespinStart();
    this.reSpinMessage = new ReSpinMessage();
    this.addChild(this.nearmissRespin);
    this.addChild(this.respin);
    this.addChild(this.reSpinMessage);

    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));

    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));

    eventManager.addListener(EventTypes.CREATE_MESSAGE_BANNER, this.createFreeSpinsMessage.bind(this));
    eventManager.addListener(EventTypes.CREATE_WIN_MESSAGE_BANNER, this.createWinMessage.bind(this));

    eventManager.addListener(EventTypes.CREATE_BATTLE_BNS_TITLE, this.createBattleBnsSetTitle.bind(this));
    eventManager.addListener(EventTypes.REMOVE_BATTLE_BNS_TITLE, this.removeBattleBnsSetTitle.bind(this));
  }

  private getPositionOfFreeSpinPanelInScene(): number {
    const position = this.children.findIndex((child) => child instanceof BattleBonusPanel);

    return position !== -1 ? position + 1 : 5;
  }

  private createFreeSpinsMessage(props: MessageBattleBonusBannerProps): void {
    this.addChild(new MessageBattleBonusBanner(props).init());
    AudioHowl.play({ type: ISongs.BB_Banner });
  }

  private createWinMessage(props: MessageWinBannerProps): void {
    const totalWinDelay = Tween.createDelayAnimation(
      // mappedAudioSprites[ISongs.TotalWinBanner].duration,
      1000,
    );

    totalWinDelay.start();
    this.messageWinBanner = new MessageWinBanner({
      ...props,
      callback: () => {
        totalWinDelay.skip();
        if (props.callback) props.callback();
        // this.frame.zIndex = 9;
      },
    }).init();
    this.messageWinBanner.visible = false;
    this.addChild(this.messageWinBanner);
    this.messageWinBanner.zIndex = Z_INDEX_REELS_FRAME - 1;
    this.messageWinBanner.updateTransform();
    this.messageWinBanner.visible = true;
  }

  private removeBattleBnsSetTitle(): void {
    if (this.battleBonusPanel) {
      this.removeChild(this.battleBonusPanel);
      this.battleBonusPanel?.destroy({
        children: true,
      });
      this.battleBonusPanel = undefined;
    }
  }

  private createBattleBnsSetTitle(props: BattleBnsTitleProps): void {
    this.battleBonusPanel = new BattleBonusPanel(props);
    this.addChildAt(this.battleBonusPanel, this.getPositionOfFreeSpinPanelInScene());
  }

  private onModeChange(settings: { mode: GameMode }): void {
    if (isBuyFeatureMode(settings.mode)) {
      this.miniPayTableContainer.visible = false;
    } else {
      if (isBattleBonusMode(settings.mode)) {
        this.slotsContainer.visible = false;
        this.miniPayTableContainer.visible = false;
      } else {
        this.slotsContainer.visible = true;
        this.miniPayTableContainer.visible = true;
      }
    }
  }

  private resize(
    width: number,
    height: number,
    x: number,
    y: number,
    scale: number,
    pivotX: number,
    pivotY: number,
  ): void {
    this.scale.set(scale);
    this.pivot.set(pivotX, pivotY);
  }
}

export default GameView;
