import { ResourceTypes } from '../../resources.d';

import {
  buyFeatureBtnTitleDisableStyle,
  buyFeatureBtnTitleStyle,
  totalCostTextAmountDisableStyle,
  totalCostTextAmountStyle,
} from './textStyles';

export enum BtnType {
  BbBtn,
  HbbBtn,
}

export const BUY_FEATURE_BB_BTN = [ResourceTypes.buyFeatureBbBtn, ResourceTypes.buyFeatureBbBtnNotActive];

export const BUY_FEATURE_HBB_BTN = [ResourceTypes.buyFeatureHbbBtn, ResourceTypes.buyFeatureHbbBtnNotActive];

export const BUY_FEATURE_BTN_TITLE_TEXT_STYLE = [buyFeatureBtnTitleStyle, buyFeatureBtnTitleDisableStyle];

export const BUY_FEATURE_COST_TEXT_STYLE = [totalCostTextAmountStyle, totalCostTextAmountDisableStyle];
