import { useQuery } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { config } from '../../config';
import { IUserBalance } from '../../global.d';
import { setCoinValue, setSlotConfig } from '../../gql/cache';
import { getBetAmountGql, getUserGql } from '../../gql/query';
import { ResourceTypes } from '../../resources.d';
import { countCoins, formatNumber, showCurrency } from '../../utils';
import Resources from '../../utils/resources';

import { IPaytableData } from './d';
import styles from './info.module.scss';

const hasTranslate = (slug: ResourceTypes): boolean => {
  return false;
};

const isIconName = (slug: ResourceTypes): boolean => {
  // TODO remove it!
  if (slug === ResourceTypes.symbolGArms || slug === ResourceTypes.symbolGArmsRe) {
    return true;
  }
  return false;
};

const calcMultiplier = (multiplier: number, betAmount: number, slug?: ResourceTypes): number => {
  // if (slug === ResourceTypes.scatter)
  //   return (
  //     countCoins({ totalAmount: betAmount, coinValue: setCoinValue() }) *
  //     multiplier
  //   );
  return (
    countCoins({
      totalAmount: betAmount / setSlotConfig().lineSet.coinAmountMultiplier,
      coinValue: setCoinValue(),
    }) * multiplier
  );
};

const PaytableComponent: React.FC = () => {
  const { t } = useTranslation();
  const { data: userData } = useQuery<{ user: IUserBalance }>(getUserGql);
  const { data: dataBet } = useQuery<{
    betAmount: number;
  }>(getBetAmountGql);

  const {
    user: { balance },
  } = userData!;

  const { betAmount } = dataBet!;

  const specialIcon = config.specialPayTable;
  const baseData: IPaytableData[] = config.payTableData;
  const baseMixData = config.payTableMixData;
  const baseNormalData: IPaytableData[] = config.payTableNormalData;

  const { currency } = balance;

  return (
    <section>
      <div className={styles['paytable-list']}>
        {specialIcon.map(({ slug, awardText, awardText2 }) => (
          <div key={slug} className={styles['paytable-list__item']}>
            <div className={styles.img}>
              <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
              <div className={styles['paytable-list__title']}>
                <p
                  className={`${styles.p} ${styles['symbol-name']}`}
                  dangerouslySetInnerHTML={{
                    __html: t(`infoPayTable_${slug}`),
                  }}
                />
              </div>
            </div>
            <div className={styles.content}>
              <p
                className={styles.p_1}
                dangerouslySetInnerHTML={{
                  __html: t(awardText),
                }}
              />
              <p
                className={styles.p_2}
                dangerouslySetInnerHTML={{
                  __html: t(awardText2),
                }}
              />
            </div>
          </div>
        ))}
        {baseData.map(({ combos, slug }) => (
          <div key={slug} className={styles['paytable-list__item']}>
            <div className={styles.img}>
              <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
              <div className={styles['paytable-list__title']}>
                <p
                  className={`${styles.p} ${styles['symbol-name']}`}
                  dangerouslySetInnerHTML={{
                    __html: t(`infoPayTable_${slug}`),
                  }}
                />
              </div>
            </div>
            <div className={styles.content}>
              {combos.map((i) => (
                <div key={i.pattern}>
                  <span className={styles.multiplier}>{t(`${i.pattern}`)} </span>
                  {formatNumber(currency, calcMultiplier(i.multiplier, betAmount, slug), showCurrency(currency))}
                </div>
              ))}
            </div>
          </div>
        ))}
        {baseMixData.map(({ slug, combos, awardText }) => (
          <div key={slug[0]} className={styles['paytable-list__mix_item']}>
            <p className={styles.p}>{t(awardText)}</p>
            <div className={styles.combo}>
              {slug.map((symbol, i) => (
                <div key={i} className={styles.img}>
                  <img draggable="false" src={Resources.getSource(symbol)} alt={symbol} key={`combo-${i}`} />
                  {isIconName(symbol) && (
                    <div className={styles['paytable-list__title']}>
                      <p
                        className={`${styles.p} ${styles['symbol-name']}`}
                        dangerouslySetInnerHTML={{
                          __html: t(`infoPayTable_${symbol}`),
                        }}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
            <>
              {
                <div className={styles.content}>
                  {combos.map((i) => (
                    <div key={i.pattern}>
                      <span className={styles.multiplier}>{t(`${i.pattern}`)} </span>
                      {formatNumber(currency, calcMultiplier(i.multiplier, betAmount), showCurrency(currency))}
                    </div>
                  ))}
                </div>
              }
            </>
          </div>
        ))}
        {baseNormalData.map(({ combos, slug }) => (
          <div key={slug} className={styles['paytable-list__item']}>
            <div className={styles.img}>
              <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
            </div>
            <div className={styles.content}>
              {combos.map((i) => (
                <div key={slug + i.pattern}>
                  <span className={styles.multiplier}>{t(`${i.pattern}`)} </span>
                  {formatNumber(currency, calcMultiplier(i.multiplier, betAmount, slug), showCurrency(currency))}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default PaytableComponent;
