import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../../global.d';
import { ResourceTypes } from '../../../resources.d';
import { isBattleBonusMode } from '../../../utils';
import ViewContainer from '../../components/container';
import { REELS_AMOUNT, REEL_WIDTH, SLOT_WIDTH, Z_INDEX_REELS_BACKGROUND, eventManager } from '../../config';

class ReelsBackgroundContainer extends ViewContainer {
  private reelBacks: PIXI.Sprite[] = [];

  private reelContainer: PIXI.Container[] = [];

  constructor() {
    super();

    this.reelContainer = this.initReelBackground();

    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));

    this.zIndex = Z_INDEX_REELS_BACKGROUND;
  }

  private onModeChange(settings: { mode: GameMode }): void {
    if (isBattleBonusMode(settings.mode)) {
      this.visible = false;
    } else {
      this.visible = true;
    }
  }

  private initReelBackground(): PIXI.Container[] {
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const container = new PIXI.Container();
      const texture = PIXI.Texture.from(ResourceTypes.reelBack);

      const light = new PIXI.Sprite(texture);
      this.reelBacks.push(light);
      container.y = 0;
      container.x = i * REEL_WIDTH + (REEL_WIDTH - SLOT_WIDTH) / 2;
      container.addChild(light);
      this.reelContainer.push(container);
      this.addChild(container);
    }
    return this.reelContainer;
  }
}

export default ReelsBackgroundContainer;
