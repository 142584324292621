import { EventTypes } from '../../global.d';
import ViewContainer from '../components/container';
import { Z_INDEX_RESPIN_START, eventManager } from '../config';

import ArmsReChange from './armsReChange';

class RespinStart extends ViewContainer {
  private armsReChange: ArmsReChange;

  constructor() {
    super();
    this.armsReChange = new ArmsReChange();
    this.sortableChildren = true;
    this.addChild(this.armsReChange);

    eventManager.addListener(EventTypes.RESPIN_START, this.respinAnimationStart.bind(this));
    this.zIndex = Z_INDEX_RESPIN_START;
  }

  public respinAnimationStart(): void {}
}
export default RespinStart;
