import { EventTypes, GameMode } from '../../global.d';
import ViewContainer from '../components/container';
import { Z_INDEX_GAME_TITLE, eventManager } from '../config';

class GameTitle extends ViewContainer {
  constructor() {
    super();

    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
    this.zIndex = Z_INDEX_GAME_TITLE;
  }

  private onModeChange(settings: { mode: GameMode }) {}
}

export default GameTitle;
