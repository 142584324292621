import * as PIXI from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import SlotMachine from '..';
import { AnimationType, RntISongs } from '../../anticipation/table';
import { ISongs } from '../../config';
import { EventTypes, GameMode, ReelAnticipation } from '../../global.d';
import { setGameMode, setIsBigWinAnim, setReelAnticipation } from '../../gql/cache';
import { destroySpine, isBattleBonusMode, isBuyFeatureMode, isReSpinMode } from '../../utils';
import SpineAnimation from '../animations/spine';
import { BASE_GAME_CHARA_WINDOW_IDLE } from '../battleBonus/config';
import {
  BASE_GAME_CHARA_WINDOW_POS_X,
  BASE_GAME_CHARA_WINDOW_POS_Y,
  SlotMachineState,
  Z_INDEX_BASE_GAME_CHARA_WINDOW,
  eventManager,
} from '../config';

class BaseGameCharaWindow extends PIXI.Container {
  private baseCharacterWindow: SpineAnimation | undefined;

  private currentSpinAnimation: string | undefined;

  private prevSpinAnimation: string | undefined;

  constructor() {
    super();

    this.initBaseCharacterWindowAnimation();
    this.addChild(this.baseCharacterWindow!.spine);

    this.currentSpinAnimation = 'idle';
    this.prevSpinAnimation = 'undefined';

    eventManager.on(EventTypes.SLOT_MACHINE_STATE_CHANGE, this.onSlotMachineStateChange.bind(this));

    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_START, this.onAnticipationStart.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_END, this.onAnticipationEnd.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));

    eventManager.addListener(EventTypes.CHARA_WINDOW_ANIMATION, this.startSpinAnimation.bind(this));

    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));
    this.zIndex = Z_INDEX_BASE_GAME_CHARA_WINDOW;
  }

  private initBaseCharacterWindowAnimation(): void {
    this.baseCharacterWindow = new SpineAnimation({}, PIXI.Loader.shared.resources.Basegame_characterwindow.spineData!);
    this.baseCharacterWindow!.getSpine().pivot.set(0.5, 0.5);

    this.baseCharacterWindow?.getSpine().position.set(BASE_GAME_CHARA_WINDOW_POS_X, BASE_GAME_CHARA_WINDOW_POS_Y);

    this.baseCharacterWindow!.setAnimation('idle', true);
    // this.baseCharacterWindow!.spine.stateData.setMix('getmiss', 'idle', 0.1);
    // this.baseCharacterWindow!.spine.stateData.setMix(
    //   'idle',
    //   'characternearmiss',
    //   0.1,
    // );
    // this.baseCharacterWindow!.spine.stateData.setMix(
    //   'characternearmiss',
    //   'idle',
    //   0.1,
    // );
    this.baseCharacterWindow!.addOnComplete(() => {
      if (this.currentSpinAnimation === 'respinvibration_stop') {
        this.currentSpinAnimation = this.getSpinAnimation();
        if (this.currentSpinAnimation === 'idle') {
          this.currentSpinAnimation = BASE_GAME_CHARA_WINDOW_IDLE[1];
        } else {
          this.currentSpinAnimation = BASE_GAME_CHARA_WINDOW_IDLE[setGameMode()];
          if (this.currentSpinAnimation === 'idle') {
            this.currentSpinAnimation = BASE_GAME_CHARA_WINDOW_IDLE[1];
          }
        }
        if (!setIsBigWinAnim()) {
          AudioHowl.play({ type: ISongs.RP_SE_Loop });
        }
        this.baseCharacterWindow!.setAnimation(this.currentSpinAnimation, true);
      } else if (this.currentSpinAnimation === 'getmiss') {
        this.baseCharacterWindow!.setAnimation('idle', true);
        this.currentSpinAnimation = 'idle';
      } else if (
        (this.currentSpinAnimation === BASE_GAME_CHARA_WINDOW_IDLE[1] && setGameMode() === GameMode.REGULAR) ||
        this.currentSpinAnimation === 'characternearmiss' ||
        this.currentSpinAnimation === 'getbonus'
      ) {
        // without change
      } else if (this.currentSpinAnimation === 'respin_omen' || this.currentSpinAnimation === 'bonus_omen') {
        this.currentSpinAnimation = this.prevSpinAnimation;
        this.baseCharacterWindow!.setAnimation(this.currentSpinAnimation!, true);
        this.prevSpinAnimation = 'undefined';
      } else if (
        !isReSpinMode(setGameMode()) &&
        !isBuyFeatureMode(setGameMode()) &&
        this.baseCharacterWindow != undefined
      ) {
        AudioHowl.stop({ type: ISongs.RP_SE_Loop });
        this.baseCharacterWindow!.setAnimation('idle', true);
        this.currentSpinAnimation = 'idle';
      }
    });
    this.baseCharacterWindow!.start();
  }

  private onSlotMachineStateChange(state: SlotMachineState): void {
    if (isBattleBonusMode(setGameMode())) return;
    if (state === SlotMachineState.SPIN) {
      const newSpinAnimation = this.getSpinAnimation();
      if (this.currentSpinAnimation != newSpinAnimation) {
        this.currentSpinAnimation = newSpinAnimation;
        this.baseCharacterWindow!.setAnimation(this.currentSpinAnimation, true);
        this.baseCharacterWindow!.start();
      }
    } else if (state === SlotMachineState.STOP) {
      let newSpinAnimation = 'idle';
      if (SlotMachine.getInstance().getReSpinBonus()) {
        newSpinAnimation = 'respinvibration_stop';
      } else if (SlotMachine.getInstance().getFreeSpinBonus()) {
        AudioHowl.stop({ type: ISongs.RP_SE_Loop });
        newSpinAnimation = 'getbonus';
      } else if (
        (isReSpinMode(setGameMode()) && !SlotMachine.getInstance().getReSpinBonus()) ||
        this.currentSpinAnimation === BASE_GAME_CHARA_WINDOW_IDLE[1]
      ) {
        AudioHowl.stop({ type: ISongs.RP_SE_Loop });
        newSpinAnimation = 'getmiss';
      }
      if (this.currentSpinAnimation != newSpinAnimation) {
        this.currentSpinAnimation = newSpinAnimation;
        this.baseCharacterWindow!.setAnimation(this.currentSpinAnimation, false);
        this.baseCharacterWindow!.start();
      }
    } else if (state === SlotMachineState.IDLE) {
      // this.baseCharacterWindow!.setAnimation('idle', true);
    }
  }

  private getSpinAnimation(): string {
    let spinAnimation = BASE_GAME_CHARA_WINDOW_IDLE[0];
    if (
      this.currentSpinAnimation === 'respinvibration_stop' ||
      this.currentSpinAnimation === 'getbonus' ||
      this.currentSpinAnimation === 'getmiss'
    ) {
      spinAnimation = this.currentSpinAnimation;
    } else if (setGameMode() <= GameMode.RESPIN_4) {
      spinAnimation = BASE_GAME_CHARA_WINDOW_IDLE[setGameMode()];
    } else if (this.currentSpinAnimation === BASE_GAME_CHARA_WINDOW_IDLE[1]) {
      spinAnimation = this.currentSpinAnimation;
    }
    return spinAnimation;
  }

  private onAnticipationStart(): void {
    if (setReelAnticipation() === ReelAnticipation.BONUS) {
      this.currentSpinAnimation = 'characternearmiss';
      this.baseCharacterWindow!.setAnimation(this.currentSpinAnimation, true);
    } else if (setReelAnticipation() === ReelAnticipation.RESPIN && setGameMode() === GameMode.REGULAR) {
      this.currentSpinAnimation = BASE_GAME_CHARA_WINDOW_IDLE[1];
      this.baseCharacterWindow!.setAnimation(this.currentSpinAnimation, true);
      if (!setIsBigWinAnim()) {
        AudioHowl.play({ type: ISongs.RP_SE_Loop });
      }
    }
  }

  private onAnticipationEnd(): void {
    const newSpinAnimation = this.getSpinAnimation();
    if (this.getSpinAnimation() != newSpinAnimation) {
      this.currentSpinAnimation = newSpinAnimation;
      this.baseCharacterWindow!.setAnimation(this.currentSpinAnimation, true);
    }
  }

  private startSpinAnimation(animation: AnimationType, sound: RntISongs): void {
    this.prevSpinAnimation = this.currentSpinAnimation;
    this.currentSpinAnimation = animation;
    this.baseCharacterWindow!.setAnimation(this.currentSpinAnimation, false);
    this.baseCharacterWindow!.start();
    if (sound != undefined) {
      AudioHowl.play({ type: sound });
    }
  }

  private onChangeMode(settings: {
    mode: GameMode;
    // reelPositions: number[];
    // reelSetId: string;
    // isRetrigger?: boolean;
  }) {
    if (isBattleBonusMode(settings.mode)) {
      destroySpine(this.baseCharacterWindow!);
      this.baseCharacterWindow = undefined;
      this.currentSpinAnimation = undefined;
      this.visible = false;
    } else {
      if (this.currentSpinAnimation === undefined) {
        this.initBaseCharacterWindowAnimation();
        this.addChild(this.baseCharacterWindow!.spine);
        this.visible = true;
      }
    }
  }
}
export default BaseGameCharaWindow;
