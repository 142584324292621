export const BASE_GAME_CHARA_WINDOW_IDLE = [
  'idle',
  'respinvibration_idle',
  'respinvibration2_idle',
  'respinvibration3_idle',
  'respinvibration4_idle',
];

export enum BattleAnimTypes {
  RIVAL_ATTACK = 'Rival_Attack',
  HERO_ATTACK = 'HERO_Attack',
  HERO_ATTACK2 = 'HERO_Attack2',
  HERO_ATTACK3 = 'HERO_Attack3',
  HERO_ATTACK_SP = 'HERO_Attack_Sp',
}

export enum BattleRound6AnimTypes {
  RIVAL_ATTACK_SP_END = 'Rival_Attack_Sp_End',
  RIVAL_ATTACK_END = 'Rival_Attack_End',
  RIVAL_ATTACK_SP_CONTINUE = 'Rival_Attack_Sp_Continue',
  RIVAL_ATTACK_CONTINUE = 'Rival_Attack_Continue',
  RIVAL_ATTACK_SP_AVERT = 'Rival_Attack_Sp_Avert',
  RIVAL_ATTACK_AVERT = 'Rival_Attack_Avert',
  HERO_ATTACK = 'HERO_Attack',
  HERO_ATTACK_SP = 'HERO_Attack_Sp',
  SPECIAL_ENDING = 'Special_Ending',
}
