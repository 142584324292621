import * as PIXI from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs } from '../../config';
import { BgmSoundTypes, EventTypes } from '../../global.d';
import { setBattleBonusBgmStop, setIsBattleStartAnim, setIsNextBattleTitle } from '../../gql/cache';
import i18n from '../../i18next';
import { updateTextScale } from '../../utils';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import BgmControl from '../bgmControl/bgmControl';
import {
  BATTLE_BONUS_MAIN_POS_X,
  BATTLE_BONUS_MAIN_POS_Y,
  BATTLE_START_ANIM_TIME,
  Z_INDEX_BATTLE_START,
  eventManager,
} from '../config';

import { titleTextStyle } from './textStyles';

class BattleStart extends PIXI.Container {
  private battleStartAnim: SpineAnimation | undefined;

  private battleText: PIXI.Text;

  constructor() {
    super();

    this.battleText = new PIXI.Text(i18n.t<string>('battle'), titleTextStyle);
    this.battleText.resolution = 1;
    this.battleText.anchor.set(0.5, 0.5);

    updateTextScale(this.battleText, 900, 250);
    this.battleText.position.set(610, 330);

    this.zIndex = Z_INDEX_BATTLE_START;

    eventManager.addListener(EventTypes.BATTLE_START_ANIM_START, this.BattleStartAnimStart.bind(this));
    eventManager.addListener(EventTypes.BATTLE_START_ANIM_LOOP_START, this.BattleStartAnimLoopStart.bind(this));
    eventManager.addListener(EventTypes.BATTLE_BONUS_ANIM_START, this.BattleBonusBackEnd.bind(this));

    eventManager.addListener(EventTypes.BATTLE_BNS_START_TOGGLE_SPIN, () => this.battleStartAnim!.skip());
    this.visible = false;
  }

  private initBattleStartAnim(): void {
    if (this.battleStartAnim != undefined) {
      return;
    }
    this.battleStartAnim = new SpineAnimation({}, PIXI.Loader.shared.resources.Battle_Start.spineData!);
    this.battleStartAnim!.addOnStart(() => {
      this.battleStartAnim!.getSpine().pivot.set(BATTLE_BONUS_MAIN_POS_X, BATTLE_BONUS_MAIN_POS_Y);
      this.visible = true;
    });
    this.battleStartAnim!.addOnSkip(() => {
      if (this.visible) {
        setBattleBonusBgmStop(false);
        setIsBattleStartAnim(false);
        eventManager.emit(EventTypes.NEXT_FREE_SPINS_ROUND);
        setIsNextBattleTitle(false);
        BgmControl.playBgm(BgmSoundTypes.BB1);
        eventManager.emit(EventTypes.BATTLE_BONUS_INTRO_START);
      }
    });
    this.addChild(this.battleStartAnim!.spine);
    this.addChild(this.battleText);
  }

  private BattleBonusBackEnd(): void {
    this.visible = false;
  }

  private BattleStartAnimStart(battleNum: number): void {
    eventManager.emit(EventTypes.WIN_TITLE_DISABLE);
    if (battleNum != 1) {
      this.battleText.text = i18n.t<string>('battle') + ' ' + battleNum;
      this.BattleStartAnimSet();
      setIsNextBattleTitle(true);
    }
    AudioHowl.play({ type: ISongs.BB_Start });
    setIsBattleStartAnim(true);
    const removeDelay = Tween.createDelayAnimation(BATTLE_START_ANIM_TIME);
    removeDelay.addOnComplete(() => {
      this.battleStartAnim!.skip();
      setIsNextBattleTitle(false);
    });
    removeDelay.start();
  }

  private BattleStartAnimLoopStart(): void {
    this.battleText.text = i18n.t<string>('battle') + ' ' + 1;
    this.BattleStartAnimSet();
  }

  private BattleStartAnimSet(): void {
    this.initBattleStartAnim();
    this.battleStartAnim!.setAnimation('Battle_Start', true);
    this.battleStartAnim!.start();
  }
}
export default BattleStart;
