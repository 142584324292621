/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import AudioHowl from '@phoenix7dev/play-music';
import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';

import { config, ISongs } from '../../config';
import {
  EventTypes,
  GameMode,
  GraphQLErrorsType,
  ISettledBet,
  IUserBalance,
  lineSets,
} from '../../global.d';
import {
  setAutoSpinsAmount,
  setAutoSpinsLeft,
  setAutoSpinsStartBalance,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setCurrentBonusId,
  setGameHistory,
  setGameMode,
  setIsAutoSpins,
  setIsBattleStartAnim,
  setIsBuyFeaturePopupOpened,
  setIsContinueAutoSpinsAfterFeature,
  setIsFreeSpinsWin,
  setIsInTransition,
  setIsOpenedMessageBanner,
  setIsShowSoundToast,
  setIsSlotBusy,
  setIsSoundLoading,
  setIsSpinInProgress,
  setIsStopOnAnyWin,
  setIsStopOnBalanceDecrease,
  setIsStopOnBalanceIncrease,
  setIsStopOnFeatureWin,
  setIsStopOnWinExceeds,
  setLastRegularWinAmount,
  setRespinBusy,
  setSlotConfig,
  setStopOnBalanceDecrease,
  setStopOnBalanceIncrease,
  setStopOnWinExceeds,
  setWinAmount,
} from '../../gql/cache';
import { IConfig, ISlotConfig } from '../../gql/d';
import { activateUserBonus, placeBetGql } from '../../gql/mutation';
import {
  configGql,
  getAutoSpinsGql,
  getBetAmountGql,
  getGameModeGql,
  getProgressGql,
  getUserGql,
  isStoppedGql,
} from '../../gql/query';
import SlotMachine from '../../slotMachine';
import BgmControl from '../../slotMachine/bgmControl/bgmControl';
import { eventManager, SlotMachineState } from '../../slotMachine/config';
import {
  canPressSpin,
  formatNumber,
  isFreeSpinsMode,
  isReSpinMode,
  saveReelPosition,
  showCurrency,
} from '../../utils';
import { IPlaceBetInput } from './d';

const handleChangeRestriction = (): void => {
  BgmControl.handleChangeRestriction();
};
const Spin: React.FC = () => {
  const { data } = useQuery<IConfig>(configGql);
  const { isTurboSpin } = data!;
  const { data: dataBet } = useQuery<{ betAmount: number }>(getBetAmountGql);
  const { id: slotId, lineSet } = useReactiveVar<ISlotConfig>(setSlotConfig);
  const isFreeSpinsWin = useReactiveVar<boolean>(setIsFreeSpinsWin);
  const { data: userData } = useQuery<{ user: IUserBalance }>(getUserGql);
  const { data: dataProgress } = useQuery<{
    progress: { status: number; wasLoaded?: boolean };
  }>(getProgressGql);
  const { data: dataSlotStopped } = useQuery<{ isSlotStopped: boolean }>(
    isStoppedGql,
  );

  const { data: gameModeData } = useQuery<{
    gameMode: GameMode;
  }>(getGameModeGql);
  const { gameMode } = gameModeData!;
  const balanceAmount = userData?.user.balance.amount || 0;
  const winThreeTimes = useReactiveVar<boolean[]>(setGameHistory);

  const { progress } = dataProgress!;

  const { data: autoSpins } = useQuery<{
    isAutoSpins: boolean;
    autoSpinsLeft: number;
  }>(getAutoSpinsGql);
  const { isAutoSpins, autoSpinsLeft } = autoSpins!;

  const isFreeSpinModeOnTotalWinBannerStep: () => boolean = () =>
    isFreeSpinsMode(setGameMode()) &&
    !setCurrentBonus().isActive &&
    setCurrentBonus().rounds === setCurrentBonus().currentRound;

  const [fnGet, { client }] = useMutation<
    { placeBet: ISettledBet },
    { input: IPlaceBetInput }
  >(placeBetGql, {
    onError(error) {
      if (
        error.graphQLErrors.some(
          (err) =>
            err.extensions?.type === GraphQLErrorsType.INSUFFICIENT_FUNDS,
        )
      ) {
        eventManager.emit(EventTypes.RESET_SLOT_MACHINE);
        if (setIsAutoSpins()) setIsAutoSpins(false);
        eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
      }
    },

    async onCompleted({ placeBet }) {
      eventManager.emit(EventTypes.PLACE_BET_COMPLETED);
      client.writeQuery({
        query: getUserGql,
        data: {
          ...userData,
          user: {
            ...userData?.user,
            balance: placeBet.balance.placed,
          },
        },
      });
      SlotMachine.getInstance().setResult(placeBet);
      if (SlotMachine.getInstance().isStopped) {
        SlotMachine.getInstance().spin(isTurboSpin);
      }
      const callBack = () => {
        const win = placeBet.bet.result.winCoinAmount;
        const lastThreeSpins = [...setGameHistory().slice(1), !!win];
        const thirdWinInRow = _.reduce(
          lastThreeSpins,
          (acc, item) => acc && item,
        );
        setGameHistory(lastThreeSpins);

        if (
          placeBet.bet.coinAmount *
            placeBet.bet.lineSet.coinAmountMultiplier *
            5 <=
            win &&
          !thirdWinInRow
        ) {
        }
        client.writeQuery({
          query: getUserGql,
          data: {
            ...userData,
            user: {
              ...userData?.user,
              balance: placeBet.balance.settled,
            },
          },
        });
        saveReelPosition(placeBet.bet.result.reelPositions);
      };
      SlotMachine.getInstance().setStopCallback(callBack.bind(this));
    },
  });

  const setPlayBgm = () => {
    setIsShowSoundToast(false);
    BgmControl.playBgm();
  };

  const onSpin = useCallback(
    (isTurboSpin?: boolean) => {
      const spinState = SlotMachine.getInstance().state;
      SlotMachine.getInstance().spin(isTurboSpin);
      if (spinState === SlotMachineState.IDLE) {
        if (isFreeSpinsMode(setGameMode())) {
          eventManager.emit(EventTypes.BATTLE_BNS_START_TOGGLE_SPIN);
          return;
        }

        eventManager.emit(
          EventTypes.UPDATE_WIN_VALUE,
          formatNumber(setCurrency(), 0, showCurrency(setCurrency())),
        );
        setWinAmount(0);
        setLastRegularWinAmount(0);
        if (setIsAutoSpins()) setAutoSpinsLeft(setAutoSpinsLeft() - 1);
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: false,
          },
        });
        fnGet({
          variables: {
            input: {
              slotId,
              coinAmount: setCoinAmount(),
              coinValue: setCoinValue(),
              lineSetId: lineSets[GameMode.REGULAR],
            },
          },
        });
        setIsSpinInProgress(true);
        setIsSlotBusy(true);
        AudioHowl.stop({ type: ISongs.SFX_UI_Close });
        AudioHowl.play({ type: ISongs.SFX_UI_SpinStart });
        if (AudioHowl.isRestricted) {
          handleChangeRestriction();
        }
      } else {
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: true,
          },
        });
      }

      if (AudioHowl.isRestricted) {
        AudioHowl.changeRestriction(
          false,
          [],
          () => setIsSoundLoading(true),
          () => setPlayBgm(),
        );
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    [dataBet?.betAmount, fnGet, lineSet, slotId],
  );
  useEffect(() => {
    const freeSpin = () => {
      SlotMachine.getInstance().spin(isTurboSpin);
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: false,
        },
      });
      fnGet({
        variables: {
          input: {
            slotId,
            coinAmount: setCoinAmount(),
            coinValue: setCoinValue(),
            lineSetId: lineSets[GameMode.BATTLE_BONUS], // TODO
            userBonusId: setCurrentBonus().id,
          },
        },
      });
      setIsSpinInProgress(true);
      setIsSlotBusy(true);
    };
    const buyFeatureSpin = () => {
      setWinAmount(0);
      setLastRegularWinAmount(0);
      SlotMachine.getInstance().spin(isTurboSpin);
      fnGet({
        variables: {
          input: {
            slotId,
            coinAmount: setCoinAmount(),
            coinValue: setCoinValue(),
            lineSetId: setSlotConfig().lineSet.id,
            userBonusId: setCurrentBonusId(),
          },
        },
      });
      setIsSpinInProgress(true);
      setIsSlotBusy(true);
      AudioHowl.play({ type: ISongs.SFX_UI_SpinStart });
      if (AudioHowl.isRestricted) {
        BgmControl.handleChangeRestriction(false);
      }
    };

    const reSpin = () => {
      setWinAmount(0);
      eventManager.emit(
        EventTypes.UPDATE_WIN_VALUE,
        formatNumber(setCurrency(), 0, showCurrency(setCurrency())),
      );
      SlotMachine.getInstance().spin(isTurboSpin);
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: false,
        },
      });
      fnGet({
        variables: {
          input: {
            slotId,
            coinAmount: setCoinAmount(),
            coinValue: setCoinValue(),
            lineSetId: setSlotConfig().lineSet.id,
            userBonusId: setCurrentBonusId(),
          },
        },
      });
      setIsSpinInProgress(true);
      setIsSlotBusy(true);
    };
    eventManager.on(EventTypes.RESPIN, reSpin);
    eventManager.on(EventTypes.NEXT_FREE_SPINS_ROUND, freeSpin);
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, buyFeatureSpin);

    return () => {
      eventManager.removeListener(EventTypes.NEXT_FREE_SPINS_ROUND, freeSpin);
      eventManager.removeListener(
        EventTypes.START_BUY_FEATURE_ROUND,
        buyFeatureSpin,
      );
      eventManager.removeListener(EventTypes.RESPIN, reSpin);
    };
  }, [onSpin, isTurboSpin]);

  const checkAutoSpinSettings = useCallback(() => {
    if (setIsAutoSpins()) {
      const autoSpinsLeft = setAutoSpinsLeft() <= 0;
      const bonus = setIsStopOnFeatureWin() && setCurrentBonus().isActive;
      const stopOnWin =
        setIsStopOnAnyWin() &&
        (setLastRegularWinAmount() > 0 || setCurrentBonus().isActive);

      const stopOnWinExceeds =
        setIsStopOnWinExceeds() &&
        setLastRegularWinAmount() >= setStopOnWinExceeds();

      const balanceIncrease =
        setIsStopOnBalanceIncrease() &&
        balanceAmount &&
        setStopOnBalanceIncrease() * setCoinValue() <=
          balanceAmount - setAutoSpinsStartBalance();

      const balanceDecrease =
        setIsStopOnBalanceDecrease() &&
        balanceAmount &&
        setStopOnBalanceDecrease() * setCoinValue() <=
          setAutoSpinsStartBalance() - balanceAmount;

      if (setRespinBusy()) {
        return;
      }

      if (
        autoSpinsLeft ||
        bonus ||
        stopOnWin ||
        stopOnWinExceeds ||
        balanceIncrease ||
        balanceDecrease
      ) {
        setIsAutoSpins(false);
        eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
      } else {
        onSpin(isTurboSpin);
      }
    }
  }, [balanceAmount, onSpin, isTurboSpin]);

  useEffect(() => {
    if (isAutoSpins && setIsFreeSpinsWin()) {
      if (setIsStopOnFeatureWin()) {
        setIsContinueAutoSpinsAfterFeature(false);
        setAutoSpinsLeft(0);
      } else {
        setIsContinueAutoSpinsAfterFeature(true);
      }
      setIsAutoSpins(false);
    }
  }, [isFreeSpinsWin, setIsContinueAutoSpinsAfterFeature()]);

  const onSpinButtonClick = useCallback(() => {
    if (setGameMode() === GameMode.REGULAR && setIsFreeSpinsWin()) {
      return;
    }

    if (setIsOpenedMessageBanner()) {
      eventManager.emit(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
      return;
    }

    if (isAutoSpins) {
      setAutoSpinsLeft(0);
      setIsAutoSpins(false);
      if (!setIsSlotBusy()) {
        eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
      }
    } else {
      onSpin(isTurboSpin);
    }
  }, [isAutoSpins, isTurboSpin, onSpin]);

  const useHandleSpaceSpin = useCallback(
    (e: KeyboardEvent) => {
      if (e.keyCode === 32) {
        e.preventDefault();
        e.stopPropagation();

        if (!data?.isEnabledSpaceSpin) {
          return;
        }

        if (
          !canPressSpin({
            gameMode,
            isFreeSpinsWin: setIsFreeSpinsWin(),
            bonusCurrentRound: setCurrentBonus()?.currentRound || 0,
            isSpinInProgress: setIsSpinInProgress(),
            isSlotBusy: setIsSlotBusy(),
            isSlotStopped: dataSlotStopped?.isSlotStopped ?? false,
            isOpenedMessageBanner: setIsOpenedMessageBanner(),
            isBattleStartAnim: setIsBattleStartAnim(),
            isInTransition: setIsInTransition(),
            isBuyFeaturePopupOpened: setIsBuyFeaturePopupOpened(),
            isReSpinMode: setRespinBusy(),
          })
        ) {
          return;
        }

        if (setIsOpenedMessageBanner()) {
          eventManager.emit(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
          return;
        }

        if (isAutoSpins) {
          checkAutoSpinSettings();
          return;
        }
        if (progress?.wasLoaded && !isFreeSpinModeOnTotalWinBannerStep()) {
          onSpin(isTurboSpin);
        }
      }
    },
    [
      gameMode,
      isAutoSpins,
      dataSlotStopped?.isSlotStopped,
      data?.isEnabledSpaceSpin,
      progress?.wasLoaded,
      checkAutoSpinSettings,
      onSpin,
      isTurboSpin,
    ],
  );

  useEffect(() => {
    window.addEventListener('keydown', useHandleSpaceSpin);
    return () => window.removeEventListener('keydown', useHandleSpaceSpin);
  }, [useHandleSpaceSpin]);

  useEffect(() => {
    const play = _.reduce(winThreeTimes.slice(2), (acc, item) => acc && item);
    const stop = _.reduce(winThreeTimes, (acc, item) => acc || item);
    if (play) {
    }

    if (!stop) {
    }
  }, [winThreeTimes]);

  useEffect(() => {
    let id: NodeJS.Timeout;
    if (!setIsFreeSpinsWin() && setIsContinueAutoSpinsAfterFeature()) {
      setIsAutoSpins(true);
      setIsContinueAutoSpinsAfterFeature(false);
    }
    if (dataSlotStopped?.isSlotStopped) {
      let timeOut = config.autoplay.timeOut;
      if (isReSpinMode(setGameMode())) {
        timeOut = 4000;
      }
      id = setTimeout(
        () => {
          checkAutoSpinSettings();
        },
        setAutoSpinsLeft() === setAutoSpinsAmount() ? 0 : timeOut,
      );
    }
    return () => clearTimeout(id);
  }, [
    isAutoSpins,
    isFreeSpinsWin,
    checkAutoSpinSettings,
    dataSlotStopped?.isSlotStopped,
  ]);

  useEffect(() => {
    eventManager.on(EventTypes.TOGGLE_SPIN, () => {
      onSpinButtonClick();
    });

    return () => {
      eventManager.removeListener(EventTypes.TOGGLE_SPIN);
    };
  }, [onSpinButtonClick, isAutoSpins, isTurboSpin]);

  return null;
};

export default Spin;
