import { ITextStyle } from 'pixi.js';

const font = 'NotoSans-SemiCondensedBold';

export const titleTextStyle: Partial<ITextStyle> = {
  fontSize: 160,
  fontFamily: font,
  whiteSpace: 'normal',
  align: 'center',
  fill: ['#FBC41C', '#F5FF3C', '#FFFFFF', '#FF8737', '#F9FF11', '#FBC41C', '#f08418', '#9f6518', '#ffeb19'],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fillGradientType: 0,
  stroke: '#ffffff',
  strokeThickness: 8,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 6,
  letterSpacing: 0,
  fontWeight: 'bolder',
  lineJoin: 'round',
};
