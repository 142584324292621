import * as PIXI from 'pixi.js';

import { SLOTS_CONTAINER_HEIGHT, SLOTS_CONTAINER_WIDTH } from '../config';

export const RESPIN_MESSAGE_POSITION_X = SLOTS_CONTAINER_WIDTH / 2;
export const RESPIN_MESSAGE_POSITION_Y = SLOTS_CONTAINER_HEIGHT * 0.4;

export const RESPIN_MESSAGE_DELAY_DURATION = 1000;
export const RESPIN_MESSAGE_FADEOUT_DURATION = 500;

export const reSpinMessageStyle1 = new PIXI.TextStyle({
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 4,
  fill: ['#b3ffff', '#0078ff', '#007dd4', '#20edff', '#2d28ff', '#0600ff', '#5b53ff', '#00065b', '#ffffff'],
  fillGradientStops: [0.2, 0.38, 0.49, 0.51, 0.53, 0.59, 0.66, 0.78, 0.8],
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontWeight: 'bolder',
  fontSize: 256,
  stroke: '#ffffff',
  strokeThickness: 15,
  lineJoin: 'round',
});

export const reSpinMessageStyle2 = new PIXI.TextStyle({
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 4,
  fill: ['#f0fff0', '#37ff00', '#04db00', '#fff700', '#00a814', '#00d10e', '#00f529', '#005b06', '#ffffff'],
  fillGradientStops: [0.2, 0.38, 0.49, 0.51, 0.53, 0.59, 0.66, 0.78, 0.8],
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontWeight: 'bolder',
  fontSize: 256,
  stroke: '#ffffff',
  strokeThickness: 15,
  lineJoin: 'round',
});

export const reSpinMessageStyle3 = new PIXI.TextStyle({
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 4,
  fill: ['#fff0ff', '#fb3ceb', '#e029c8', '#fdff9e', '#a8009a', '#d100ca', '#cc00f5', '#5c0054', '#ffffff'],
  fillGradientStops: [0.2, 0.38, 0.49, 0.51, 0.53, 0.59, 0.66, 0.78, 0.8],
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontWeight: 'bolder',
  fontSize: 256,
  stroke: '#ffffff',
  strokeThickness: 15,
  lineJoin: 'round',
});

export const reSpinMessageStyle4 = new PIXI.TextStyle({
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 4,
  fill: ['#fff0f0', '#ff6161', '#e02929', '#ffea00', '#8f0000', '#d10000', '#f50000', '#5c0000', '#ffffff'],
  fillGradientStops: [0.2, 0.38, 0.49, 0.51, 0.53, 0.59, 0.66, 0.78, 0.8],
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontWeight: 'bolder',
  fontSize: 256,
  stroke: '#ffffff',
  strokeThickness: 15,
  lineJoin: 'round',
});
