import { EventTypes, ReelAnticipation } from '../../global.d';
import { setReelAnticipation } from '../../gql/cache';
import ViewContainer from '../components/container';
import { Z_INDEX_NEARMISS, eventManager } from '../config';

import NearmissBonus from './nearmissBonus';
import NearmissRespin from './nearmissRespin';

class NearmissContainer extends ViewContainer {
  private nearmissRespin: NearmissRespin;

  private nearmissBonus: NearmissBonus;

  constructor() {
    super();

    this.nearmissRespin = new NearmissRespin();
    this.nearmissBonus = new NearmissBonus();

    this.addChild(this.nearmissRespin, this.nearmissBonus);

    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_START, this.createNearmissAnimations.bind(this));
    this.zIndex = Z_INDEX_NEARMISS;
  }

  private createNearmissAnimations(): void {
    if (setReelAnticipation() === ReelAnticipation.RESPIN) {
      this.nearmissRespin.respinAnimationStart();
    } else if (setReelAnticipation() === ReelAnticipation.BONUS) {
      this.nearmissBonus.bonusAnimationStart();
    }
  }
}
export default NearmissContainer;
