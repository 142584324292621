const font = 'NotoSans-SemiCondensedBold';
const GRADIENT_GOLD_TEXT_COLOR_CONFIG = {
  fill: ['#FBC41C', '#F5FF3C', '#FFFFFF', '#FF8737', '#F9FF11', '#FBC41C', '#f08418', '#9f6518', '#ffeb19'],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fillGradientType: 0,
  stroke: '#222211',
  strokeThickness: 1,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 0,
  dropShadowDistance: 3,
  letterSpacing: 0,
};
const GRADIENT_GOLD_TITLE_COLOR_CONFIG = {
  fill: ['#fff70a', '#fcc200', '#fffb00', '#fcc200', '#b47508'],
  fillGradientStops: [0.2, 0.5, 0.6, 0.7],
  fillGradientType: 0,
  stroke: '#222211',
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 0,
  dropShadowDistance: 3,
  letterSpacing: 0,
};

const GRADIENT_BLUE_TITLE_COLOR_CONFIG = {
  fill: ['#9ff0ff', '#ffffff', '#9ff0ff', '#8fbfff', '#808f9f'],
  fillGradientStops: [0.2, 0.5, 0.6, 0.7],
  fillGradientType: 0,
  stroke: '#111166',
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 0,
  dropShadowDistance: 3,
  letterSpacing: 0,
};

const GRADIENT_DISABLE_TITLE_COLOR_CONFIG = {
  fill: ['#101010', '#383838', '#727272', '#383838', '#101010'],
  fillGradientStops: [0.2, 0.5, 0.6, 0.7],
  fillGradientType: 0,
  stroke: '#000000',
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 0,
  dropShadowDistance: 3,
  letterSpacing: 0,
};

export const buyFeatureTitleStyle = {
  fontSize: 60,
  fontFamily: font,
  ...GRADIENT_GOLD_TITLE_COLOR_CONFIG,
  strokeThickness: 2,
};

export const buyFeatureBtnTitleStyle = {
  fontSize: 30,
  fontFamily: font,
  ...GRADIENT_BLUE_TITLE_COLOR_CONFIG,
  strokeThickness: 2,
};

export const buyFeatureBtnTitleDisableStyle = {
  fontSize: 30,
  fontFamily: font,
  ...GRADIENT_DISABLE_TITLE_COLOR_CONFIG,
  strokeThickness: 2,
};

export const buyFeatureConfirmStyle = {
  fontSize: 50,
  fontFamily: font,
  fill: ['#ff0000', '#ffcf00', '#ff0000'],
  stroke: '#441111',
  strokeThickness: 4,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 0,
  dropShadowDistance: 3,
  letterSpacing: 0,
};

export const totalCostTextStyle = {
  fontSize: 50,
  fontFamily: font,
  ...GRADIENT_GOLD_TEXT_COLOR_CONFIG,
  strokeThickness: 4,
};

export const totalCostTextAmountStyle = {
  fontSize: 40,
  fontFamily: font,
  lineHeight: 65,
  ...GRADIENT_GOLD_TEXT_COLOR_CONFIG,
  strokeThickness: 5,
};

export const totalCostTextAmountDisableStyle = {
  fontSize: 40,
  fontFamily: font,
  lineHeight: 65,
  ...GRADIENT_DISABLE_TITLE_COLOR_CONFIG,
  strokeThickness: 5,
};

export const amountTextStyle = {
  fontSize: 40,
  fontFamily: font,
  lineHeight: 40,
  fill: '#f6e87e',
  stroke: '#111111',
  strokeThickness: 4,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 0,
  dropShadowDistance: 3,
  letterSpacing: 0,
  wordWrap: false,
  wordWrapWidth: 540,
  breakWords: true,
};

export const betValueStyle = {
  fontSize: 50,
  fontFamily: font,
  lineHeight: 50,
  fill: '#f6e87e',
  stroke: '#111111',
  strokeThickness: 4,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 0.75,
  dropShadowAngle: 1.0,
  dropShadowBlur: 2,
  dropShadowDistance: 3,
  letterSpacing: 2,
};

export const betValueStyleConfirm = {
  fontSize: 90,
  fontFamily: font,
  lineHeight: 50,
  ...GRADIENT_GOLD_TEXT_COLOR_CONFIG,
  strokeThickness: 7,
};
