import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../../global.d';
import { ResourceTypes } from '../../../resources.d';
import { isBattleBonusMode } from '../../../utils';
import ViewContainer from '../../components/container';
import {
  REELS_FRAME_ADJUSTMENT,
  SLOTS_BASE_GAME_ADJUST,
  SLOTS_BATTLE_GAME_ADJUST,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  Z_INDEX_REELS_FRAME,
  eventManager,
} from '../../config';

class ReelsFrame extends ViewContainer {
  private baseFrameTex = PIXI.Texture.from(ResourceTypes.reelFrameBase);

  private bbFrameTex = PIXI.Texture.from(ResourceTypes.reelFrameBb);

  private frame: PIXI.Sprite;

  constructor() {
    super();
    this.frame = this.initFrame();

    this.addChild(this.frame);

    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
    this.zIndex = Z_INDEX_REELS_FRAME;
  }

  private onModeChange(settings: { mode: GameMode }): void {
    if (isBattleBonusMode(settings.mode)) {
      this.frame.texture = this.bbFrameTex;
      this.frame.y = SLOTS_CONTAINER_HEIGHT + SLOTS_BATTLE_GAME_ADJUST;
    } else {
      this.frame.texture = this.baseFrameTex;
      this.frame.y = SLOTS_CONTAINER_HEIGHT + SLOTS_BASE_GAME_ADJUST;
    }
  }

  private initFrame(): PIXI.Sprite {
    const frame = new PIXI.Sprite(this.baseFrameTex);
    frame.anchor.set(0.5, 1);
    frame.position.set(SLOTS_CONTAINER_WIDTH / 2, SLOTS_CONTAINER_HEIGHT + REELS_FRAME_ADJUSTMENT);
    frame.zIndex = 10;
    this.setPosition(frame);
    frame.y = SLOTS_CONTAINER_HEIGHT + SLOTS_BASE_GAME_ADJUST;
    return frame;
  }

  private setPosition(frame: PIXI.Sprite): void {
    frame.anchor.set(0.5, 1);
    frame.position.set(
      SLOTS_CONTAINER_WIDTH / 2, //
      SLOTS_CONTAINER_HEIGHT + REELS_FRAME_ADJUSTMENT,
    );
    frame.zIndex = 10;
  }
}

export default ReelsFrame;
