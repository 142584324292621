import * as PIXI from 'pixi.js';

import { BattleRoundScenario, EventTypes, ISettledBet, Round6, Round12345, RoundType } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { Z_INDEX_BATTLE_BONUS_CONTAINER, eventManager } from '../config';

import BattleBonus from './battleBonus';
import BattleBonusBack from './battleBonusBack';
import BattleBonusCharaWindow from './battleBonusCharaWindow';
import BattleBonusR6 from './battleBonusR6';

export enum AttackType {
  NON = 'non',
  HERO = 'hero',
  RIVAL = 'rival',
}

type BattleAnimType = Record<
  number, // point
  {
    spineData: string;
    bbIcon: ResourceTypes;
    attackType: AttackType;
  }
>;

export const BattleAnim: BattleAnimType = {
  0: {
    spineData: 'Rivalwin',
    bbIcon: ResourceTypes.bBiconRivalattack,
    attackType: AttackType.RIVAL,
  },
  1: {
    spineData: 'Hero_attack',
    bbIcon: ResourceTypes.bBiconHeroattackLow,
    attackType: AttackType.HERO,
  },
  2: {
    spineData: 'Hero_attack2',
    bbIcon: ResourceTypes.bBiconHeroattackMed,
    attackType: AttackType.HERO,
  },
  3: {
    spineData: 'Hero_attack3',
    bbIcon: ResourceTypes.bBiconHeroattackHigh,
    attackType: AttackType.HERO,
  },
  10: {
    spineData: 'Hero_attack_Sp',
    bbIcon: ResourceTypes.bBiconHeroattackSp,
    attackType: AttackType.HERO,
  },
};

class BattleBonusContainer extends PIXI.Container {
  private battleBonus: BattleBonus;

  private battleBonusR6: BattleBonusR6;

  private battleBonusCharaWindow: BattleBonusCharaWindow;

  private battleBonusBack: BattleBonusBack;

  private nextResult: ISettledBet | undefined;

  constructor() {
    super();
    this.battleBonus = new BattleBonus();
    this.battleBonusR6 = new BattleBonusR6();
    this.battleBonusCharaWindow = new BattleBonusCharaWindow();
    this.battleBonusBack = new BattleBonusBack();
    this.nextResult = undefined;

    this.sortableChildren = true;

    this.addChild(this.battleBonus, this.battleBonusR6, this.battleBonusCharaWindow, this.battleBonusBack);

    eventManager.addListener(EventTypes.BATTLE_BONUS_WIN_AMOUNT, this.setBattleBnsWinAmount.bind(this));

    eventManager.addListener(EventTypes.BATTLE_BONUS_COUNT_UP_START, this.onBattleBonusCountUpStart.bind(this));
    this.zIndex = Z_INDEX_BATTLE_BONUS_CONTAINER;
  }

  private setBattleBnsWinAmount(result: ISettledBet, battleBonusRounds: RoundType) {
    let point = 0;
    let scenario = BattleRoundScenario.scenario_1;
    this.nextResult = result;
    if (battleBonusRounds.round % 6 !== 0) {
      point = (battleBonusRounds as Round12345).points;
      eventManager.emit(EventTypes.BATTLE_BONUS_RESULT, point);
    } else {
      scenario = (battleBonusRounds as Round6).scenario;
      eventManager.emit(EventTypes.BATTLE_BONUS_RESULT_END, scenario);
    }
  }

  private onBattleBonusCountUpStart(): void {
    if (this.nextResult!.bet.result.winCoinAmount > 0) {
      eventManager.emit(EventTypes.START_WIN_ANIMATION, this.nextResult!, false);
    }
  }
}
export default BattleBonusContainer;
