import AudioHowl from '@phoenix7dev/play-music';

import { ISongs, audioSpriteVolume, mappedAudioSprites } from '../../config';
import { BgmSoundTypes, EventTypes, GameMode } from '../../global.d';
import {
  setBattleBonusBgmStop,
  setBrokenGame,
  setCurrentBonus,
  setGameMode,
  setIsAutoSpins,
  setIsNextBattleTitle,
  setIsSpEnding,
  setIsSuspended,
} from '../../gql/cache';
import { SlotMachineState, eventManager } from '../../slotMachine/config';
import { getBattleBonusBB1, isFreeSpinsMode, isReSpinMode } from '../../utils';
import Animation from '../animations/animation';
import Tween from '../animations/tween';
import { BgSkin } from '../background/background';

type BgmType = Record<BgmSoundTypes, { intro: ISongs | undefined; base: ISongs }>;

export const bgmList: BgmType = {
  regular: { intro: undefined, base: ISongs.BGM_BG_Loop },
  rp1: { intro: undefined, base: ISongs.RP1_Loop },
  rp2: { intro: undefined, base: ISongs.RP2_Loop },
  rp3: { intro: undefined, base: ISongs.RP3_Loop },
  rp4: { intro: undefined, base: ISongs.RP4_Loop },
  bb1: { intro: ISongs.BGM_BB1_Intro, base: ISongs.BGM_BB1_Loop },
  bb2: { intro: undefined, base: ISongs.BGM_BB2_Loop },
};

class BgmControl {
  private bgmListIndex: BgmSoundTypes | undefined;

  private timer: number | undefined;

  private introBgmDelay: Animation | null = null;

  constructor() {
    this.bgmListIndex = undefined;
    this.timer = undefined;

    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
    eventManager.on(EventTypes.SLOT_MACHINE_STATE_CHANGE, this.onSlotMachineStateChange.bind(this));
  }

  private clearTimeout() {
    if (this.timer !== undefined) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
  }

  private onSlotMachineStateChange(state: SlotMachineState) {
    if (state === SlotMachineState.IDLE) {
      this.clearTimeout();
    } else if (state === SlotMachineState.SPIN) {
      this.clearTimeout();
    }
  }

  private onModeChange(settings: { mode: GameMode; background?: BgSkin }) {
    const { mode, background } = settings;
    let bgmTitle: BgmSoundTypes | undefined;

    if (isFreeSpinsMode(mode)) {
      if (setIsAutoSpins()) {
        if (getBattleBonusBB1()) {
          bgmTitle = BgmSoundTypes.BB1;
        } else {
          bgmTitle = BgmSoundTypes.BB2;
        }
      } else {
        bgmTitle = undefined;
      }
    } else if (isReSpinMode(mode)) {
      switch (mode) {
        case GameMode.RESPIN_1:
          bgmTitle = BgmSoundTypes.RP1;
          break;
        case GameMode.RESPIN_2:
          bgmTitle = BgmSoundTypes.RP2;
          break;
        case GameMode.RESPIN_3:
          bgmTitle = BgmSoundTypes.RP3;
          break;
        case GameMode.RESPIN_4:
          bgmTitle = BgmSoundTypes.RP4;
          break;
        default:
          bgmTitle = BgmSoundTypes.RP1;
          break;
      }
    } else {
      bgmTitle = BgmSoundTypes.BASE;
    }

    if (bgmTitle === undefined) {
      this.stopBgm();
    } else if (this.bgmListIndex != bgmTitle) {
      this.stopBgm();
      this.playBgm(bgmTitle);
    }
  }

  public setBaseBgm(): void {
    this.bgmListIndex = BgmSoundTypes.BASE;
  }

  private setBgmIndex(): void {
    if (isFreeSpinsMode(setGameMode())) {
      if (setCurrentBonus().currentRound % 6 === 5) {
        this.bgmListIndex = BgmSoundTypes.BB2;
      } else {
        this.bgmListIndex = BgmSoundTypes.BB1;
      }
    } else {
      switch (setGameMode()) {
        case GameMode.RESPIN_1:
          this.bgmListIndex = BgmSoundTypes.RP1;
          break;
        case GameMode.RESPIN_2:
          this.bgmListIndex = BgmSoundTypes.RP2;
          break;
        case GameMode.RESPIN_3:
          this.bgmListIndex = BgmSoundTypes.RP3;
          break;
        case GameMode.RESPIN_4:
          this.bgmListIndex = BgmSoundTypes.RP4;
          break;
        default:
          this.bgmListIndex = BgmSoundTypes.BASE;
      }
    }
  }

  public playBgm(bgmListIndex?: BgmSoundTypes): void {
    if (AudioHowl.isRestricted) {
      return;
    }
    if (bgmListIndex === this.bgmListIndex && bgmListIndex != undefined) {
      return;
    }
    if (setBattleBonusBgmStop()) {
      return;
    }

    if (setIsNextBattleTitle()) {
      return;
    }

    this.stopBgm();
    if (bgmListIndex === undefined) {
      this.setBgmIndex();
    } else {
      this.bgmListIndex = bgmListIndex;
    }
    if (setIsSpEnding()) {
      this.bgmListIndex = BgmSoundTypes.BASE;
    }
    if (this.bgmListIndex != undefined) {
      if (bgmList[this.bgmListIndex].intro === undefined) {
        AudioHowl.stop({ type: ISongs.BGM_BB1_Intro });
        AudioHowl.play({ type: bgmList[this.bgmListIndex].base });
      } else {
        this.introBgmDelay = Tween.createDelayAnimation(mappedAudioSprites[bgmList[this.bgmListIndex].intro!].duration);
        this.introBgmDelay.addOnComplete(() => {
          if (this.bgmListIndex != undefined && this.bgmListIndex != BgmSoundTypes.BB2) {
            AudioHowl.play({
              type: bgmList[this.bgmListIndex].base,
              stopPrev: true,
            });
          }
        });
        this.introBgmDelay.addOnSkip(() => {
          if (this.bgmListIndex != undefined) {
            AudioHowl.stop({ type: bgmList[this.bgmListIndex].base });
          }
        });

        AudioHowl.play({
          type: bgmList[this.bgmListIndex].intro!,
          stopPrev: true,
        });
        this.introBgmDelay.start();
      }
    }
  }

  public stopBgm(): void {
    if (this.bgmListIndex != undefined) {
      AudioHowl.stop({ type: bgmList[this.bgmListIndex].base });
    }
  }

  public fadeInBase(fadeTime: number): void {
    if (this.bgmListIndex != undefined) {
      AudioHowl.fadeIn(fadeTime, bgmList[this.bgmListIndex].base, audioSpriteVolume[bgmList[this.bgmListIndex].base]);
    }
  }

  public fadeOutAll(fadeTime: number): void {
    if (this.bgmListIndex != undefined) {
      AudioHowl.fadeOut(fadeTime, bgmList[this.bgmListIndex].base);
    }
  }

  public handleChangeRestriction(value = true): void {
    setIsSuspended(false);
    AudioHowl.unSuspend();
    if (setBrokenGame() || !value) {
      AudioHowl.changeRestriction(false, []);
      eventManager.emit(EventTypes.HANDLE_CHANGE_RESTRICTION);
      this.playBgm();
    } else {
      this.playBgm();
    }
  }
}

export default new BgmControl();
