import * as PIXI from 'pixi.js';

import { EventTypes } from '../../global.d';
import { destroySpine } from '../../utils';
import SpineAnimation from '../animations/spine';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

class NearmissBonus extends ViewContainer {
  private nearmissBonus: SpineAnimation | undefined;

  constructor() {
    super();
    this.sortableChildren = true;
    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_END, this.bonusAnimationStop.bind(this));
  }

  private initBonusAnimation(): void {
    this.nearmissBonus = new SpineAnimation({}, PIXI.Loader.shared.resources.Nearmiss_Bonus.spineData!);
    this.nearmissBonus.addOnStart(() => {
      this.addChild(this.nearmissBonus!.spine);
    });
    this.nearmissBonus.getSpine().pivot.set(0);
  }

  public bonusAnimationStart(): void {
    this.initBonusAnimation();
    this.nearmissBonus!.setAnimation('Nearmiss_Bonus', true);
    this.nearmissBonus?.getSpine().position.set(620, 350);
    this.nearmissBonus!.start();
  }

  private bonusAnimationStop(): void {
    if (this.nearmissBonus != undefined) {
      this.nearmissBonus!.end();
      destroySpine(this.nearmissBonus!);
    }
  }
}
export default NearmissBonus;
