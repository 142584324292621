import { useQuery } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';

import gameRules from '../../config/gameRules';
import { IUserBalance } from '../../global.d';
import { getBetsSetting } from '../../gql/fromFragment';
import { getUserGql } from '../../gql/query';
import { formatNumber } from '../../utils';

import styles from './info.module.scss';

const GameRules: React.FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'infoGameRules' });
  const { data: userData } = useQuery<{ user: IUserBalance }>(getUserGql);

  const {
    user: { balance },
  } = userData!;
  const betSettings = getBetsSetting();
  const { currency } = balance;
  const minBet = formatNumber(currency, betSettings.minBetCount);
  const maxBet = formatNumber(currency, betSettings.maxBetCount);
  const version = window.__ENV__?.APP_VERSION ?? 'develop';

  return (
    <div className={styles.gameRules}>
      <h1 className={styles.title}>{t('Title')}</h1>
      <section>
        <div className={`${styles.p} ${styles.left}`}>
          {gameRules.map((v) => (
            <div key={v.key} dangerouslySetInnerHTML={{ __html: t(v.key) }} />
          ))}
        </div>
        <div className={`${styles.p} ${styles.left}`}>
          <div
            dangerouslySetInnerHTML={{
              __html: t('Min', {
                minBet,
              }),
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: t('Max', {
                maxBet,
              }),
            }}
          />
        </div>
        <div className={`${styles.p} ${styles.left}`}>
          <div
            dangerouslySetInnerHTML={{
              __html: t('RTP', {
                rtp: '96.51%',
              }),
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: t('PtpHBB', {
                rptHbb: '96.90%',
              }),
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: t('PtpBB', {
                rtpBb: '96.59%',
              }),
            }}
          />
        </div>
        <div
          className={`${styles.p} ${styles.left}`}
          dangerouslySetInnerHTML={{
            __html: t('infoGameRulesVersion', {
              version,
            }),
          }}
        />
      </section>
    </div>
  );
};

export default GameRules;
