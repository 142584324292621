import * as PIXI from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import { setBattleBonusAnimSkip, setCurrentBonus, setGameMode } from '../../gql/cache';
import { isBattleBonusMode } from '../../utils';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import { BATTLE_BONUS_MAIN_POS_X, BATTLE_BONUS_MAIN_POS_Y, SlotMachineState, eventManager } from '../config';

import { BattleAnim } from './battleBonusContainer';
import { BattleAnimTypes } from './config';

type BattleAnimType = {
  animationName: string;
  attackSoundName: ISongs;
  attackSoundDelay: number;
  attackSoundName2: ISongs | undefined;
  attackSoundDelay2: number | undefined;
  damageSoundName: ISongs | undefined;
  damageSoundDelay: number | undefined;
  isCountUp: boolean | undefined;
  CountDelay: number | undefined;
};

type BattleAnimationType = Record<BattleAnimTypes, { animation: BattleAnimType[] }>;

export const BattleAttackAnimation: BattleAnimationType = {
  Rival_Attack: {
    animation: [
      {
        animationName: 'Rivalwin',
        attackSoundName: ISongs.BB_Rival,
        attackSoundDelay: 0,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: undefined,
        damageSoundDelay: undefined,
        isCountUp: undefined,
        CountDelay: undefined,
      },
      {
        animationName: 'Rival_attack',
        attackSoundName: ISongs.BB_Atk3,
        attackSoundDelay: 1200,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: ISongs.BB_Damage3,
        damageSoundDelay: 2000,
        isCountUp: false,
        CountDelay: undefined,
      },
    ],
  },
  HERO_Attack: {
    animation: [
      {
        animationName: 'Herowin',
        attackSoundName: ISongs.BB_Hero,
        attackSoundDelay: 0,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: undefined,
        damageSoundDelay: undefined,
        isCountUp: undefined,
        CountDelay: undefined,
      },
      {
        animationName: 'Hero_attack',
        attackSoundName: ISongs.BB_Atk1,
        attackSoundDelay: 1200,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: ISongs.BB_Damage1,
        damageSoundDelay: 1800,
        isCountUp: true,
        CountDelay: undefined,
      },
    ],
  },
  HERO_Attack2: {
    animation: [
      {
        animationName: 'Herowin',
        attackSoundName: ISongs.BB_Hero,
        attackSoundDelay: 0,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: undefined,
        damageSoundDelay: undefined,
        isCountUp: undefined,
        CountDelay: undefined,
      },
      {
        animationName: 'Hero_attack2',
        attackSoundName: ISongs.BB_Atk2,
        attackSoundDelay: 1200,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: ISongs.BB_Damage2,
        damageSoundDelay: 1600,
        isCountUp: true,
        CountDelay: undefined,
      },
    ],
  },

  HERO_Attack3: {
    animation: [
      {
        animationName: 'Herowin',
        attackSoundName: ISongs.BB_Hero,
        attackSoundDelay: 0,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: undefined,
        damageSoundDelay: undefined,
        isCountUp: undefined,
        CountDelay: undefined,
      },
      {
        animationName: 'Hero_attack3',
        attackSoundName: ISongs.BB_Atk3,
        attackSoundDelay: 1100,
        attackSoundName2: ISongs.BB_Atk2,
        attackSoundDelay2: 1500,
        damageSoundName: ISongs.BB_Damage3,
        damageSoundDelay: 2000,
        isCountUp: true,
        CountDelay: undefined,
      },
    ],
  },
  HERO_Attack_Sp: {
    animation: [
      {
        animationName: 'Herowin',
        attackSoundName: ISongs.BB_Hero,
        attackSoundDelay: 0,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: undefined,
        damageSoundDelay: undefined,
        isCountUp: undefined,
        CountDelay: undefined,
      },
      {
        animationName: 'Hero_attack_Sp',
        attackSoundName: ISongs.BB_Atk4,
        attackSoundDelay: 0,
        attackSoundName2: undefined,
        attackSoundDelay2: undefined,
        damageSoundName: ISongs.BB_Damage3,
        damageSoundDelay: 1800,
        isCountUp: true,
        CountDelay: 1300,
      },
    ],
  },
};

class BattleBonus extends PIXI.Container {
  private battleBonusAnim: SpineAnimation | undefined;

  private battleBonusResultAnimationData: string;

  private attackIndex: number;

  private isResult: boolean;

  private battleType: BattleAnimTypes;

  private point: number;

  private isSlotStopped: boolean;

  private introLoop: boolean;

  private animationFix: boolean;

  private countUpFix: boolean;

  constructor() {
    super();
    this.initBattleBonusAnim();
    this.battleBonusResultAnimationData = '';
    this.attackIndex = 0;
    this.isResult = false;
    this.introLoop = true;

    this.battleType = BattleAnimTypes.RIVAL_ATTACK;
    this.point = 0;
    this.isSlotStopped = false;
    this.animationFix = false;
    this.countUpFix = false;

    this.addChild(this.battleBonusAnim!.spine);

    eventManager.addListener(EventTypes.BATTLE_BONUS_INTRO_START, this.battleBonusAnimStart.bind(this));

    eventManager.addListener(EventTypes.BATTLE_BONUS_RESULT, this.battleBonusResult.bind(this));

    eventManager.addListener(EventTypes.BATTLE_BONUS_RESULT_END, this.battleBonusResultEnd.bind(this));

    eventManager.addListener(EventTypes.BATTLE_BONUS_R6_INTRO_START, this.battleBonusR6AnimStart.bind(this));

    eventManager.on(EventTypes.SLOT_MACHINE_STATE_CHANGE, this.onSlotMachineStateChange.bind(this));

    eventManager.addListener(EventTypes.HANDLE_UPDATE_BATTLE_BNS_GET, () => {
      if (this.battleBonusAnim != undefined && this.battleBonusAnim.getSpine().visible) {
        this.countUpFix = true;
        this.chkFix();
      }
    });

    eventManager.on(EventTypes.SET_IS_SLOTS_STOPPED, (isSlotStopped: boolean) => {
      if (this.visible) {
        if (isBattleBonusMode(setGameMode()) && isSlotStopped) {
          this.isSlotStopped = true;
          this.introLoop = false;
          setBattleBonusAnimSkip(true);
          this.chkSkipAnim();
          eventManager.emit(EventTypes.BATTLE_BONUS_ANIM_SKIP);
        }
      }
    });
  }

  private initBattleBonusAnim(): void {
    this.battleBonusAnim = new SpineAnimation({}, PIXI.Loader.shared.resources.BattleBonus.spineData!);
    this.battleBonusAnim.getSpine().visible = false;

    this.battleBonusAnim!.addOnStart(() => {
      const delay = Tween.createDelayAnimation(0);
      delay.addOnComplete(() => {
        this.battleBonusAnim!.getSpine().visible = true;
        this.visible = true;
        eventManager.emit(EventTypes.BATTLE_BONUS_ANIM_START);
      });
      delay.start();
    });

    this.battleBonusAnim!.addOnComplete(() => {
      // console.log(
      //   'addOnComplete !this.isResult',
      //   !this.isResult,
      //   'this.introLoop',
      //   this.introLoop,
      //   'this.battleBonusResultAnimationData',
      //   this.battleBonusResultAnimationData,
      //   '!this.isResult || this.introLoop',
      //   !this.isResult || this.introLoop,
      // );
      if (!this.isResult || this.introLoop) {
        this.battleBonusAnim!.setAnimation('Intro_loop', false);
        this.battleBonusResultAnimationData = 'Intro_loop';
        this.introLoop = false;
        this.battleBonusAnim!.start();
      } else {
        AudioHowl.stop({ type: ISongs.BB_Push });
        // console.log(
        //   'this.battleType',
        //   this.battleType,
        //   'length',
        //   BattleAttackAnimation[this.battleType].animation.length - 1,
        //   'attackIndex',
        //   this.attackIndex,
        // );
        if (BattleAttackAnimation[this.battleType].animation.length - 1 >= this.attackIndex) {
          this.battleBonusResultAnimationData =
            BattleAttackAnimation[this.battleType].animation[this.attackIndex].animationName;
          this.battleBonusAnim!.setAnimation(this.battleBonusResultAnimationData, false);

          const soundDelay = Tween.createDelayAnimation(
            BattleAttackAnimation[this.battleType].animation[this.attackIndex].attackSoundDelay,
          );
          const sound = BattleAttackAnimation[this.battleType].animation[this.attackIndex].attackSoundName;
          const isCountUp = BattleAttackAnimation[this.battleType].animation[this.attackIndex].isCountUp;
          const CountUpDelay = BattleAttackAnimation[this.battleType].animation[this.attackIndex].CountDelay;
          soundDelay.addOnComplete(() => {
            AudioHowl.play({
              type: sound,
            });
            if (isCountUp === true) {
              if (CountUpDelay === undefined) {
                eventManager.emit(EventTypes.SET_STATE, SlotMachineState.JINGLE);
              } else {
                const CountUpDelayEvent = Tween.createDelayAnimation(CountUpDelay);
                CountUpDelayEvent.addOnComplete(() => {
                  eventManager.emit(EventTypes.SET_STATE, SlotMachineState.JINGLE);
                });
                CountUpDelayEvent.start();
              }
            }
          });

          const attackDelay = BattleAttackAnimation[this.battleType].animation[this.attackIndex].attackSoundDelay2;
          if (attackDelay != undefined) {
            this.phrasePlay(
              attackDelay,
              BattleAttackAnimation[this.battleType].animation[this.attackIndex].attackSoundName2!,
            );
          }

          const damageDelay = BattleAttackAnimation[this.battleType].animation[this.attackIndex].damageSoundDelay;
          if (damageDelay != undefined) {
            this.phrasePlay(
              damageDelay,
              BattleAttackAnimation[this.battleType].animation[this.attackIndex].damageSoundName!,
            );
          }
          soundDelay.start();
          this.battleBonusAnim!.start();

          this.attackIndex += 1;
        } else {
          if (this.battleType === BattleAnimTypes.RIVAL_ATTACK) {
            this.countUpFix = true;
          }
          this.animationFix = true;
          this.chkFix();
        }
      }
    });
    this.battleBonusAnim.getSpine().pivot.set(BATTLE_BONUS_MAIN_POS_X, BATTLE_BONUS_MAIN_POS_Y);
    this.visible = false;
  }

  private chkFix(): void {
    if (this.animationFix && this.countUpFix) {
      this.battleBonusIconSet();
      eventManager.emit(EventTypes.SET_STATE, SlotMachineState.IDLE);
      this.animationFix = false;
      this.countUpFix = false;
    }
  }

  private phrasePlay(delay: number, phrase: ISongs): void {
    const damageSoundDelay = Tween.createDelayAnimation(delay);
    damageSoundDelay.addOnComplete(() => {
      AudioHowl.play({
        type: phrase,
      });
    });
    damageSoundDelay.start();
  }

  private battleBonusAnimStart(): void {
    // console.log('battleBonusAnimStart', performance.now());
    eventManager.emit(EventTypes.WIN_TITLE_DISABLE);
    eventManager.emit(EventTypes.BATTLE_BONUS_BACK_END);

    this.animationFix = false;
    this.countUpFix = false;
    this.isResult = false;
    this.attackIndex = 0;
    this.introLoop = true;
    this.battleBonusAnim!.setAnimation('Intro_in', false);
    this.battleBonusAnim!.start();
    AudioHowl.play({ type: ISongs.BB_Push });
  }

  private battleBonusResult(point: number) {
    this.point = point;
    this.isResult = true;
    this.battleType = this.chgNumberToBattleAnimTypes(point);
    this.chkSkipAnim();
  }

  private chkSkipAnim(): void {
    if (this.isSlotStopped && this.isResult) {
      this.isSlotStopped = false;
      if (this.attackIndex > 0) {
        return;
      }
      this.attackIndex = 1;
      this.battleBonusResultAnimationData =
        BattleAttackAnimation[this.battleType].animation[this.attackIndex].animationName;

      this.battleBonusAnim!.setAnimation(this.battleBonusResultAnimationData, false);
      AudioHowl.stop({ type: ISongs.BB_Push });

      const soundDelay = Tween.createDelayAnimation(
        BattleAttackAnimation[this.battleType].animation[this.attackIndex].attackSoundDelay,
      );
      const sound = BattleAttackAnimation[this.battleType].animation[this.attackIndex].attackSoundName;
      const isCountUp = BattleAttackAnimation[this.battleType].animation[this.attackIndex].isCountUp;
      const CountUpDelay = BattleAttackAnimation[this.battleType].animation[this.attackIndex].CountDelay;
      soundDelay.addOnComplete(() => {
        AudioHowl.play({
          type: sound,
        });
        if (isCountUp) {
          if (CountUpDelay === undefined) {
            eventManager.emit(EventTypes.SET_STATE, SlotMachineState.JINGLE);
          } else {
            const CountUpDelayEvent = Tween.createDelayAnimation(CountUpDelay);
            CountUpDelayEvent.addOnComplete(() => {
              eventManager.emit(EventTypes.SET_STATE, SlotMachineState.JINGLE);
            });
            CountUpDelayEvent.start();
          }
        }
      });

      const damageDelay = BattleAttackAnimation[this.battleType].animation[this.attackIndex].damageSoundDelay;
      if (damageDelay != undefined) {
        this.phrasePlay(
          damageDelay,
          BattleAttackAnimation[this.battleType].animation[this.attackIndex].damageSoundName!,
        );
      }

      soundDelay.start();

      if (isCountUp != undefined) {
        if (this.battleType === BattleAnimTypes.RIVAL_ATTACK) {
          this.countUpFix = true;
        }
        this.chkFix();
      }

      this.battleBonusAnim!.start();
      this.attackIndex += 1;
    }
  }

  private battleBonusResultEnd(): void {
    this.battleBonusAnim!.getSpine().visible = false;
    this.visible = false;
  }

  private battleBonusR6AnimStart(): void {
    this.battleBonusAnim!.getSpine().visible = false;
    this.visible = false;
  }

  private onSlotMachineStateChange(state: SlotMachineState): void {
    if (state === SlotMachineState.SPIN) {
      this.isSlotStopped = false;
      setBattleBonusAnimSkip(false);
    } else if (state === SlotMachineState.IDLE) {
      setBattleBonusAnimSkip(false);
    }
  }

  private chgNumberToBattleAnimTypes(point: number): BattleAnimTypes {
    let battleAnimType = BattleAnimTypes.RIVAL_ATTACK;

    switch (point) {
      case 0:
        battleAnimType = BattleAnimTypes.RIVAL_ATTACK;
        break;
      case 1:
        battleAnimType = BattleAnimTypes.HERO_ATTACK;
        break;
      case 2:
        battleAnimType = BattleAnimTypes.HERO_ATTACK2;
        break;
      case 3:
        battleAnimType = BattleAnimTypes.HERO_ATTACK3;
        break;
      case 10:
        battleAnimType = BattleAnimTypes.HERO_ATTACK_SP;
        break;
      default:
        battleAnimType = BattleAnimTypes.RIVAL_ATTACK;
        break;
    }

    return battleAnimType;
  }

  private battleBonusIconSet(): void {
    const bbIcon = BattleAnim[this.point].bbIcon;
    const round = setCurrentBonus().currentRound;
    if (round < 5) {
      eventManager.emit(EventTypes.BATTLE_BONUS_SET_ICON, round, bbIcon);
    }
  }
}
export default BattleBonus;
