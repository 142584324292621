import { useQuery, useReactiveVar } from '@apollo/client';
import * as PIXI from 'pixi.js';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AudioHowl from '@phoenix7dev/play-music';
import { Toast } from '@phoenix7dev/shared-components';

import { EventTypes, GameMode, reelSets } from '../../global.d';
import {
  setBrokenGameReelSet,
  setCurrentBonus,
  setIsRevokeThrowingError,
  setIsShowSoundToast,
  setIsSlotBusy,
  setIsSoundLoading,
  setIsSoundOn,
  setIsSpinInProgress,
  setIsSuspended,
  setIsTimeoutErrorMessage,
  setPrevReelsPosition,
  setProgress,
  setReplayBet,
  setSkipIntroScreen,
  setSlotConfig,
  setStressful,
} from '../../gql/cache';
import { IConfig } from '../../gql/d';
import { configGql } from '../../gql/query';
import i18n from '../../i18next';
import SlotMachine from '../../slotMachine';
import Animator from '../../slotMachine/animations/animator';
import BgmControl from '../../slotMachine/bgmControl/bgmControl';
import { eventManager } from '../../slotMachine/config';
import { queryParams } from '../../utils';
import { wrap } from '../../utils/helper';
import AutoPlaySettingsMenu from '../AutoPlaySettings/AutoPlaySettingsMenu';
import BetSettingsMenu from '../BetSettings/BetSettingsMenu';
import BuyFeature from '../BuyFeature';
import Clock from '../Clock';
import EventListener from '../EventListener';
import IntroScreen from '../IntroScreen/introScreen';
import Menu from '../MenuButton';
import ReplaySpin from '../ReplaySpinButton';
import Spin from '../SpinButton';
import TitleName from '../TitleName';

import styles from './gameScreen.module.scss';
import { preTextureTransfer } from './preTextureTransfer';
import { restoreBuyFeature } from './restoreBuyFeature';

window.PIXI = PIXI;

const handleChangeRestriction = (): void => {
  BgmControl.handleChangeRestriction();
};
const GameScreen: React.FC = () => {
  const { t } = useTranslation();
  const { data } = useQuery<IConfig>(configGql);
  const { isSoundOn, isShowSuspendedToast } = data!;
  const [isShowContent, setShowContent] = useState(false);
  const isLoading = useReactiveVar(setIsSoundLoading);
  const pixiContainerRef = useRef<HTMLDivElement | null>(null);
  const progress = useReactiveVar(setProgress);
  const isReplay = queryParams.has('replayBetId');

  useEffect(() => {
    AudioHowl.mute(isSoundOn);
  }, [isSoundOn]);

  useEffect(() => {
    restoreBuyFeature();
    const application = new PIXI.Application({
      resolution: window.devicePixelRatio || 1,
      autoDensity: true,
    });
    preTextureTransfer(application);
    application.renderer.once(EventTypes.POST_RENDER, () => {
      eventManager.emit(EventTypes.POST_RENDER);
    });
    const animator = new Animator(application);
    pixiContainerRef.current?.appendChild(application.view);

    const handleResize = (): void => {
      const parent = pixiContainerRef.current!;
      const width = parent.clientWidth;
      const height = parent.clientHeight;
      application.renderer.resize(width, height);
      eventManager.emit(EventTypes.RESIZE, width, height);
    };

    const resize = (): void => {
      const userAgent = navigator.userAgent;
      // resize fix for Chrome browsers on Ios devices
      if (userAgent.includes('CriOS') && (userAgent.includes('iPhone') || userAgent.includes('iPad'))) {
        setTimeout(() => {
          handleResize();
        }, 50);
      } else {
        handleResize();
      }
    };
    // workaround, coz loadScreen file is locked
    if (setCurrentBonus().id != '' && setReplayBet()) {
      setBrokenGameReelSet(reelSets[GameMode.REGULAR]);
      setPrevReelsPosition([0, 0, 0]);
    }

    if (!setSkipIntroScreen()) {
      IntroScreen.initIntroScreen(application);
      eventManager.addListener(EventTypes.HANDLE_DESTROY_INTRO_SCREEN, () => {
        SlotMachine.initSlotMachine(
          application,
          setSlotConfig(),
          wrap(setIsSpinInProgress, false),
          wrap(setIsSlotBusy, false),
        );
        eventManager.removeAllListeners(EventTypes.THROW_ERROR);
        eventManager.on(EventTypes.THROW_ERROR, () => {
          if (!setIsRevokeThrowingError()) {
            setIsRevokeThrowingError(true);
            setIsTimeoutErrorMessage(true);
            setStressful({
              show: true,
              type: 'network',
              message: i18n.t('errors.UNKNOWN.UNKNOWN'),
            });
          }
        });
        handleResize();
      });
    } else {
      SlotMachine.initSlotMachine(
        application,
        setSlotConfig(),
        wrap(setIsSpinInProgress, false),
        wrap(setIsSlotBusy, false),
      );
      eventManager.removeAllListeners(EventTypes.THROW_ERROR);
      eventManager.on(EventTypes.THROW_ERROR, () => {
        if (!setIsRevokeThrowingError()) {
          setIsRevokeThrowingError(true);
          setIsTimeoutErrorMessage(true);
          setStressful({
            show: true,
            type: 'network',
            message: i18n.t('errors.UNKNOWN.UNKNOWN'),
          });
        }
      });
      handleResize();
    }
    handleResize();

    window.addEventListener(EventTypes.RESIZE, resize);

    document.addEventListener('visibilitychange', function () {
      eventManager.emit(EventTypes.WINDOW_NOT_ACTIVE);
    });

    return () => window.removeEventListener(EventTypes.RESIZE, resize);
  }, []);

  useEffect(() => {
    setShowContent(true);
  }, []);

  return (
    <>
      <div ref={pixiContainerRef} className={`${styles.canvas} ${progress.wasLoaded ? '' : styles.intro}`} />
      <EventListener />
      <Clock />
      <TitleName />
      <BuyFeature />
      <AutoPlaySettingsMenu />
      <Menu />
      <BetSettingsMenu />
      {isReplay ? <ReplaySpin /> : <Spin />}
      {AudioHowl.isRestricted &&
        !AudioHowl.restrictionChangedOnIntroScreen &&
        !AudioHowl.hideRestrictionModal &&
        !isShowSuspendedToast &&
        setIsSoundOn() && (
          <Toast
            title={t('soundWarningTitle')}
            text={t('soundWarningText')}
            btnText={t('soundWarningBtn')}
            isLoading={isLoading}
            handleClick={() => {
              if (!AudioHowl.isInitialized) {
                eventManager.emit(EventTypes.ENABLE_SOUND_LOADER);
              }
              AudioHowl.changeRestriction(
                false,
                [],
                () => setIsSoundLoading(true),
                () => {
                  setIsShowSoundToast(false);
                  handleChangeRestriction();
                },
              );
            }}
          />
        )}
      {isShowSuspendedToast && setIsSoundOn() && (
        <Toast
          title={t('suspendedWarningTitle')}
          text={t('suspendedWarningText')}
          btnText={t('suspendedWarningBtn')}
          handleClick={() => {
            setIsSuspended(false);
            AudioHowl.unSuspend();
            handleChangeRestriction();
          }}
        />
      )}
    </>
  );
};

export default GameScreen;
