import { ISongs } from '../config';

// 4.3.1
export const backMode1 = [[100, 100, 100]]; // 50% mode_1
export const backMode2 = [[50, 100, 100]]; // 66% mode_2
export const backMode3 = [[50, 100, 100]]; // 75% mode_3
export const backMode4 = [[50, 80, 100]]; // 80% mode_4
export const backMode5 = [[40, 70, 100]]; // 90% mode_5

// 5.2.3 Selection for displaying RESPIN agitation
export const baseGameInsideRespin = [[50, 100]]; // Base game RESPIN symbol will appear
export const baseGameOutsideRespin = [[100, 100]]; // Base game RESPIN symbol stops below the reel frame

// 5.5 Animation selection table
export enum AnimationType {
  NON = 'No anticipation',
  PHOENIX = 'phoenix',
  SNEEZE = 'respin_omen',
  PRINCESS = 'bonus_omen',
}

export type RntISongs = ISongs | undefined;

export const baseGameSevenNotRespinOnReel = [[75, 75, 100, 100]]; //  左右でボーナスが非テンパイ、RPが枠内に出現する Seven symbols not to ready, RESPIN stops on reel frame
export const baseGameSevenNotRespinBelowReel = [[75, 75, 100, 100]]; // 左右でボーナスが非テンパイ、RPが枠下に停止する Seven symbols not to ready, RESPIN stops below reel frame
export const baseGameSevenReadyRespinStopOnReel = [[75, 75, 100, 100]]; // 左右でボーナスがテンパイ、RPが枠内に出現する Seven symbols ready, RESPIN stops on reel frame
// Seven symbols ready, Seven and RESPIN does not win
// export const baseGameSevenWinMode345 = [[0, 100, 100, 100]]; // 左右でボーナスがテンパイ、赤7 or 金7揃い、かつモード 3, 4, 5 Red Seven win. phoenix全振り
export const baseGameSevenWinMode345 = [[40, 70, 75, 100]]; // 左右でボーナスがテンパイ、赤7 or 金7揃い、かつモード 3, 4, 5 Gold or Red Seven win.
export const baseGameSevenWinMode12 = [[65, 65, 70, 100]]; // 左右でボーナスがテンパイ、赤7 or 金7揃い、かつモード 1, 2 Gold or Red Seven win.
export const baseGameRedSevenReadyStopBelowReel = [[80, 80, 80, 100]]; // 赤7テンパイで揃わず、かつ赤7が枠内 or 枠下に停止する Red Seven ready, and Red Seven stops below reel frame.
export const baseGameGoldSevenReadyStopBelowReel = [[100, 100, 100, 100]]; // 金7テンパイで揃わず、かつ金7が枠内 or 枠下に停止する Gold Seven ready, and Gold Seven stops below reel frame.
export const respinLevel4SevenWinMode345 = [[70, 100, 100, 100]]; // リスピン中 リスピン4段階目で赤7 or 金7揃い、かつモード 3, 4, 5 RESPIN level 4, Gold Seven win.
