import { ISongs } from '../config';
import { BattleBonusUpperColor } from '../global.d';
import {
  AnimationType,
  baseGameInsideRespin,
  baseGameOutsideRespin,
  RntISongs,
} from './table';
import { getRandomFromUUID, getResultFromTable } from './util';

// 4.3.1  BattleRoundMode.mode_1
export function getBattleBonusUpperAreaColor(
  table: number[],
  uuid: string,
): BattleBonusUpperColor {
  const rand = getRandomFromUUID(uuid, 100);
  const result = getResultFromTable(table, rand);
  let resultColor = BattleBonusUpperColor.RED;
  switch (result) {
    case 0:
      resultColor = BattleBonusUpperColor.RED;
      break;
    case 1:
      resultColor = BattleBonusUpperColor.PURPLE;
      break;
    case 2:
      resultColor = BattleBonusUpperColor.RAINBOW;
      break;
  }
  return resultColor;
}

// 5.2.3 Selection for displaying RESPIN agitation
export function getOutSideRespin(uuid: string): boolean {
  const rand = getRandomFromUUID(uuid, 100);
  const result = getResultFromTable(baseGameOutsideRespin[0], rand);
  return result === 0 ? true : false;
}

export function getInSideRespin(uuid: string): boolean {
  const rand = getRandomFromUUID(uuid, 100);
  const result = getResultFromTable(baseGameInsideRespin[0], rand);
  return result === 0 ? true : false;
}

export function conversionAnimationType(
  num: number,
): [AnimationType, RntISongs] {
  let animType = AnimationType.NON;
  let soundPhrase: RntISongs = undefined;
  switch (num) {
    case 0:
      animType = AnimationType.NON;
      break;
    case 1:
      animType = AnimationType.PHOENIX;
      soundPhrase = ISongs.Yocho_1;
      break;
    case 2:
      animType = AnimationType.SNEEZE;
      soundPhrase = ISongs.Yocho_2;
      break;
    case 3:
      animType = AnimationType.PRINCESS;
      soundPhrase = ISongs.Yocho_3;
      break;
    default:
      animType = AnimationType.NON;
      break;
  }

  return [animType, soundPhrase];
}

// 5.5 Animation selection table
export function getAnimationSelectionTableLot(
  uuid: string,
  table: number[],
): [AnimationType, RntISongs] {
  const rand = getRandomFromUUID(uuid, 100);
  const result = getResultFromTable(table, rand);
  return conversionAnimationType(result);
}
