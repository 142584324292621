import { GAME_CONTAINER_WIDTH } from '../config';

export const BANNER_POSITION_X = GAME_CONTAINER_WIDTH / 2;
export const BANNER_POSITION_Y = 10;

// WIN BANNER
export const MESSAGE_WIN_BANNER_TITLE_Y = 100;
export const MESSAGE_WIN_BANNER_WIN_AMOUNT_Y = 250;
export const MESSAGE_WIN_BANNER_BATTLE_AMOUNT_Y = 400;
export const MESSAGE_WIN_BANNER_BONUS_STREAK_Y = 600;
export const MESSAGE_WIN_BANNER_BONUS_STREAK_MARGIN = 45;

export const MESSAGE_WIN_BANNER_WIDTH_MAX = 1130;
