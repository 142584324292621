import _ from 'lodash';
import * as PIXI from 'pixi.js';

import { MAPPED_SYMBOLS, SlotId } from '../../config';
import { EventTypes } from '../../global.d';
import { setBetAmount, setCoinAmount, setCurrency, setIsMiniPayTable } from '../../gql/cache';
import { formatNumber, isScatter, normalizeCoins, showCurrency } from '../../utils';
import {
  PAY_TABLE_BACKGROUND_COLOR,
  PAY_TABLE_HEIGHT,
  PAY_TABLE_WIDTH,
  REELS_AMOUNT,
  REEL_WIDTH,
  SLOT_HEIGHT,
  SLOT_SCALE,
  SLOT_WIDTH,
  eventManager,
  miniPayTableNumberStyle,
  miniPayTableTextStyle,
} from '../config';
import { Combos, Icon, IconCombo } from '../d';

class MiniPayTable extends PIXI.Container {
  private id: number;

  private isLast: boolean;

  private currency = '';

  public iconId: SlotId;

  public combos: Combos;

  public rect: PIXI.Graphics;

  public multipliers: PIXI.Text;

  public multipliersValue: PIXI.Text;

  public slot: PIXI.Sprite;

  constructor(id: number, icon: Icon, combos: Combos) {
    super();
    this.id = id;
    this.isLast = id % REELS_AMOUNT === REELS_AMOUNT - 1;
    this.x = this.isLast ? -REEL_WIDTH : 0;
    this.y = 0;
    this.iconId = icon.id;
    this.visible = false;
    this.sortableChildren = true;
    this.combos = _.cloneDeep(combos)?.reverse();
    this.rect = this.initRect();
    this.addChild(this.rect);
    this.slot = this.initSlot();
    this.addChild(this.slot);
    this.multipliers = this.initMultipliers();
    this.addChild(this.multipliers);
    this.multipliersValue = this.initMultipliersValue();
    this.addChild(this.multipliersValue);
    this.currency = setCurrency();
    this.setPayTableData(icon.id, combos);
    this.initSubscriptions();
  }

  private initSubscriptions = (): void => {
    eventManager.addListener(EventTypes.SHOW_PAY_TABLE, (i: number) => this.showPayTable(i));
    eventManager.addListener(EventTypes.DISABLE_ALL_MINI_PAY_TABLES, this.handleHide);
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.handleHide);
    eventManager.addListener(EventTypes.UPDATE_BET, () => this.handleChangeBetAmount());
  };

  private handleHide = (): void => {
    this.visible = false;
  };

  private initRect = (): PIXI.Graphics => {
    const rect = new PIXI.Graphics();
    rect.x = REEL_WIDTH / 2;
    rect.beginFill(PAY_TABLE_BACKGROUND_COLOR);
    rect.alpha = 0.75;
    rect.drawRoundedRect(
      this.isLast ? 0 : 0,
      (SLOT_HEIGHT - PAY_TABLE_HEIGHT) / 2,
      PAY_TABLE_WIDTH,
      PAY_TABLE_HEIGHT,
      15,
    );
    rect.zIndex = 2;

    return rect;
  };

  private initSlot = (): PIXI.Sprite => {
    const slotPosition = (REEL_WIDTH - SLOT_WIDTH) / 2 + SLOT_WIDTH / 2;
    const slot = new PIXI.Sprite();
    slot.anchor.set(0.5, 0.5);
    slot.width = SLOT_WIDTH * SLOT_SCALE;
    slot.height = SLOT_HEIGHT * SLOT_SCALE;
    slot.x = this.isLast ? REEL_WIDTH + slotPosition : slotPosition;
    slot.y = SLOT_HEIGHT / 2;
    slot.zIndex = 3;

    return slot;
  };

  private initMultipliers = (): PIXI.Text => {
    const multipliers = new PIXI.Text('', {
      wordWrapWidth: SLOT_WIDTH,
      ...miniPayTableNumberStyle,
    });
    multipliers.resolution = 1;
    multipliers.zIndex = 3;

    return multipliers;
  };

  private initMultipliersValue = (): PIXI.Text => {
    const multipliersValue = new PIXI.Text('', {
      wordWrapWidth: SLOT_WIDTH,
      ...miniPayTableTextStyle,
    });
    multipliersValue.resolution = 1;
    multipliersValue.zIndex = 3;
    return multipliersValue;
  };

  private calcMultiplier(multiplier: number): number {
    if (isScatter(this.iconId)) {
      return normalizeCoins(setBetAmount() * multiplier);
    }
    return normalizeCoins(setCoinAmount() * multiplier);
  }

  private calcLineHeight(): number {
    if (this.combos && this.combos!.length < 4) return 30;
    return 30;
  }

  private calcTextYPosition(height: number): number {
    return SLOT_HEIGHT / 2 - height / 2;
  }

  private getCombosNumbers(): string {
    return this.combos?.reduce((acc: string, curr: IconCombo) => `${acc} ${curr.pattern}: \n`, '') || '';
  }

  private getCombos(): string {
    return (
      this.combos?.reduce(
        (acc: string, curr: IconCombo) =>
          `${acc} ${formatNumber(this.currency, this.calcMultiplier(curr.multiplier), showCurrency(this.currency))}\n`,
        '',
      ) || ''
    );
  }

  private handleChangeBetAmount(): void {
    this.multipliersValue.text = this.getCombos();
  }

  public setPayTableData(iconId: SlotId, combos: Combos): void {
    this.iconId = iconId;
    this.combos = combos;
    this.slot.texture = PIXI.Texture.from(MAPPED_SYMBOLS[iconId]);
    this.slot.width = this.slot.texture.width;
    this.slot.height = this.slot.texture.height;
    this.multipliers.text = this.getCombosNumbers();
    this.multipliers.style.lineHeight = this.calcLineHeight();
    this.multipliers.y = this.calcTextYPosition(this.multipliers.height);
    this.multipliers.x = this.isLast ? 230 : SLOT_WIDTH + 20;
    this.multipliersValue.text = this.getCombos();
    this.multipliersValue.style.lineHeight = this.calcLineHeight();
    this.multipliersValue.y = this.calcTextYPosition(this.multipliersValue.height);
    this.multipliersValue.x = this.multipliers.x + this.multipliers.width + 20;
  }

  public showPayTable(uniqueId: number): void | undefined {
    if (!setIsMiniPayTable() || !this.combos?.length) return;
    if (uniqueId !== this.id) {
      this.visible = false;
      return;
    }

    this.visible = !this.visible;
  }
}

export default MiniPayTable;
