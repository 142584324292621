import { TextStyleSet } from 'pixi-tagged-text/dist/types';

export const introStylesInfo: TextStyleSet = {
  default: {
    strokeThickness: 5,
    // dropShadow: true,
    // dropShadowBlur: 2,
    // dropShadowColor: 0x333333,
    fill: 0xffffff,
    fontSize: 25,
    lineHeight: 25,
    // letterSpacing: 1,
    wordWrap: true,
    fontWeight: '700',
    fontFamily: 'Arial',
    fontStyle: 'normal',
    align: 'center',
  },
  span: {
    fill: 0xffff19,
  },
};
