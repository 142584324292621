import * as PIXI from 'pixi.js';

import { BaseMessageBannerProps, EventTypes } from '../../global.d';
import { setIsOpenedMessageBanner } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

import { BANNER_POSITION_X, BANNER_POSITION_Y } from './config';

export class BaseMessageBanner extends ViewContainer {
  protected banner?: PIXI.Sprite;

  protected props: BaseMessageBannerProps;

  protected bindedHandleDestroy = this.handleDestroy.bind(this);

  constructor(props: BaseMessageBannerProps) {
    super();

    this.position.set(BANNER_POSITION_X, BANNER_POSITION_Y);
    this.visible = true;
    this.interactive = true;

    setIsOpenedMessageBanner(true);

    this.props = props;
  }

  protected init(): BaseMessageBanner {
    this.banner = this.initBanner();
    this.addChild(this.banner);

    this.on('click', () => {
      if (this.props.callback) {
        this.props.callback();
        this.props.callback = undefined;
      }
      if (!this.props.preventDefaultDestroy) this.handleDestroy();
    });
    this.on('touchstart', () => {
      if (this.props.callback) {
        this.props.callback();
        this.props.callback = undefined;
      }
      if (!this.props.preventDefaultDestroy) this.handleDestroy();
    });
    eventManager.addListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, () => {
      if (this.props.callback) {
        this.props.callback();
        this.props.callback = undefined;
      }
      if (!this.props.preventDefaultDestroy) this.handleDestroy();
    });
    eventManager.addListener(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER, this.bindedHandleDestroy);

    if (this.props.onInitCallback) {
      this.props.onInitCallback();
    }
    return this;
  }

  protected initBanner(): PIXI.Sprite {
    const banner = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.messageBannerBb));
    banner.anchor.set(0.5, 0);
    return banner;
  }

  public handleDestroy(): void {
    setIsOpenedMessageBanner(false);
    eventManager.removeListener(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER, this.bindedHandleDestroy);
    eventManager.removeAllListeners(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
    this.destroy({ children: true });
  }
}
