import * as PIXI from 'pixi.js';

import { EventTypes } from '../../global.d';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { Z_INDEX_BATTLE_BONUS_BACK, eventManager } from '../config';

class BattleBonusBack extends ViewContainer {
  private battleBonusBack: SpineAnimation | undefined;

  private battleBonusBackR6: SpineAnimation | undefined;

  constructor() {
    super();
    this.initBattleBonusBackAnimation();
    this.initBattleBonusBackR6Animation();
    this.sortableChildren = true;
    eventManager.addListener(EventTypes.BATTLE_BONUS_BACK_START, this.battleBonusBackStart.bind(this));
    eventManager.addListener(EventTypes.BATTLE_BONUS_BACK_END, this.battleBonusBackEnd.bind(this));
    eventManager.addListener(EventTypes.BATTLE_BONUS_SP_BACK_START, this.battleBonusSpBackStart.bind(this));
    eventManager.addListener(EventTypes.BATTLE_BONUS_SP_BACK_END, this.battleBonusSpBackEnd.bind(this));
    eventManager.addListener(EventTypes.BATTLE_BONUS_BACK_R6_START, this.battleBonusBackR6Start.bind(this));
    eventManager.addListener(EventTypes.BATTLE_BONUS_BACK_R6_END, this.battleBonusBackR6End.bind(this));
    this.zIndex = Z_INDEX_BATTLE_BONUS_BACK;
  }

  private initBattleBonusBackAnimation(): void {
    this.battleBonusBack = new SpineAnimation({}, PIXI.Loader.shared.resources.BattleBonus_back.spineData!);
    this.battleBonusBack.addOnStart(() => {
      const delay = Tween.createDelayAnimation(0);
      delay.addOnComplete(() => {
        this.battleBonusBack!.getSpine().visible = true;
        this.addChild(this.battleBonusBack!.spine);
      });
      delay.start();
    });
    this.battleBonusBack.getSpine().pivot.set(0);
  }

  private initBattleBonusBackR6Animation(): void {
    this.battleBonusBackR6 = new SpineAnimation({}, PIXI.Loader.shared.resources.BattleBonus_R6_back.spineData!);
    this.battleBonusBackR6.addOnStart(() => {
      this.addChild(this.battleBonusBackR6!.spine);
      this.battleBonusBackR6!.getSpine().visible = true;
    });
    this.battleBonusBackR6.getSpine().pivot.set(0);
  }

  private battleBonusBackStart(isHeroAttack: boolean): void {
    this.battleBonusBack!.setAnimation('Hero_attack_back', true);
    this.battleBonusBack?.getSpine().position.set(620, 350);
    const filter = new PIXI.filters.ColorMatrixFilter();
    if (isHeroAttack) {
      filter.hue(0, true);
    }
    if (!isHeroAttack) {
      filter.hue(160, true);
    }
    this.battleBonusBack!.getSpine().filters = [filter];
    this.battleBonusBack!.start();
    this.battleBonusBack!.getSpine().visible = true;
  }

  private battleBonusBackEnd(): void {
    this.battleBonusBack!.getSpine().visible = false;
  }

  private battleBonusSpBackStart(): void {
    this.battleBonusBack!.setAnimation('Sp_ED', false);
    this.battleBonusBack?.getSpine().position.set(620, 350);

    const filterChangeDelay = Tween.createDelayAnimation(660);
    filterChangeDelay.addOnComplete(() => {
      const filter = new PIXI.filters.ColorMatrixFilter();
      filter.hue(160, true);
      this.battleBonusBack!.getSpine().filters = [filter];
    });
    filterChangeDelay.start();

    const filter = new PIXI.filters.ColorMatrixFilter();
    filter.hue(0, true);
    this.battleBonusBack!.getSpine().filters = [filter];
    this.battleBonusBack!.start();
    this.battleBonusBack!.getSpine().visible = true;
  }

  private battleBonusSpBackEnd(): void {
    this.battleBonusBack!.getSpine().visible = false;
  }

  private battleBonusBackR6Start(anim: string): void {
    this.battleBonusBackR6!.setAnimation(anim, false);
    this.battleBonusBackR6?.getSpine().position.set(620, 350);
    this.battleBonusBackR6!.start();
  }

  private battleBonusBackR6End(): void {
    this.battleBonusBackR6!.getSpine().visible = false;
  }
}
export default BattleBonusBack;
