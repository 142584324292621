import { BonusStatus, EventTypes, GameMode, UserBonus, bonusIds } from '../../global.d';
import {
  setBetAmount,
  setCoinAmount,
  setGameMode,
  setIsBuyFeaturePopupOpened,
  setSkipIntroScreen,
  setSlotConfig,
} from '../../gql/cache';
import client from '../../gql/client';
import { getUserBonuses } from '../../gql/query';
import { eventManager } from '../../slotMachine/config';

export function restoreBuyFeature(): void {
  const activeUserBonusData = client.readQuery<{
    userBonuses: UserBonus[];
  }>({
    query: getUserBonuses,
    variables: {
      input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
    },
  });

  const buyFeatureBonus = activeUserBonusData?.userBonuses.find(
    (ub) =>
      ub.bonus.type === 'SPECIAL_ROUND' &&
      (ub.bonusId === bonusIds[GameMode.BUY_FEATURE_BATTLE_BONUS] ||
        ub.bonusId === bonusIds[GameMode.BUY_FEATURE_HYPER_BATTLE_BONUS]),
  );
  if (buyFeatureBonus) {
    const { coinAmountMultiplier } = setSlotConfig().lineSet;
    setCoinAmount(buyFeatureBonus.coinAmount);
    setBetAmount(coinAmountMultiplier * setCoinAmount());
    setGameMode(GameMode.REGULAR);
    setIsBuyFeaturePopupOpened(true);

    if (setSkipIntroScreen()) {
      eventManager.addListener(EventTypes.POST_RENDER, () => {
        window.setTimeout(() => {
          eventManager.emit(EventTypes.START_BUY_FEATURE_ROUND);
        });
      });
    } else {
      eventManager.addListener(EventTypes.HANDLE_DESTROY_INTRO_SCREEN, () => {
        window.setTimeout(() => {
          eventManager.emit(EventTypes.START_BUY_FEATURE_ROUND);
        });
      });
    }
  }
}
