import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { isFreeSpinsMode } from '../../utils';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

export type BgSkin = 'default' | 'BB';

class Background extends ViewContainer {
  private bgTextures: Record<BgSkin, PIXI.Texture> = {
    default: PIXI.Texture.from(ResourceTypes.backgroundBase),
    BB: PIXI.Texture.from(ResourceTypes.backgroundBb),
  };

  private bgSprite = new PIXI.Sprite();

  private currentSkin?: BgSkin;

  constructor(skin: BgSkin = 'default') {
    super();

    this.bgSprite.anchor.set(0.5);

    this.addChild(this.bgSprite);

    this.setSkin(skin);

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));
  }

  public setSkin(skinName: BgSkin) {
    if (this.currentSkin === skinName) return;
    this.currentSkin = skinName;

    this.bgSprite.texture = this.bgTextures[skinName];
  }

  private onChangeMode(settings: { mode: GameMode; background?: BgSkin }) {
    const { mode, background } = settings;
    if (isFreeSpinsMode(mode)) {
      this.setSkin('BB');
    } else {
      this.setSkin('default');
    }
  }

  private resize(width: number, height: number): void {
    this.x = width / 2;
    this.y = height / 2;

    const tex = this.bgSprite.texture;
    const bgAspectRatio = tex.width / tex.height;
    const aspectRatio = width / height;

    if (bgAspectRatio > aspectRatio) {
      this.bgSprite.scale.set(height / tex.height);
    } else {
      this.bgSprite.scale.set(width / tex.width);
    }
  }
}

export default Background;
