import * as PIXI from 'pixi.js';

import { getBattleBonusUpperAreaColor } from '../../../anticipation';
import { backMode1, backMode2, backMode3, backMode4, backMode5 } from '../../../anticipation/table';
import { BattleBonusUpperColor, BattleRoundMode, EventTypes, GameMode } from '../../../global.d';
import { setBattleBonusRounds, setNextResult } from '../../../gql/cache';
import { destroySpine, isBattleBonusMode } from '../../../utils';
import SpineAnimation from '../../animations/spine';
import ViewContainer from '../../components/container';
import {
  BATTLE_BNS_UPPER_BACKGROUND_HEIGHT,
  BATTLE_BNS_UPPER_BACKGROUND_POS_Y,
  BATTLE_BNS_UPPER_BACKGROUND_WIDTH,
  BATTLE_BNS_UPPER_COLOR_POS_X,
  BATTLE_BNS_UPPER_COLOR_POS_Y,
  SLOTS_BACKGROUND_WIDTH,
  SLOTS_CONTAINER_WIDTH,
  Z_INDEX_BATTLE_BNS_UPPER_BACKGROUND_CONTAINER,
  eventManager,
} from '../../config';

class BattleBnsUpperBackgroundContainer extends ViewContainer {
  private base: PIXI.Graphics;

  private battleBonusCharaBack: SpineAnimation | undefined;

  private backColor: BattleBonusUpperColor;

  public interval: ReturnType<typeof setTimeout> | null = null;

  public color: number;

  constructor() {
    super();

    this.color = 0;
    this.base = this.initBaseGraphic();
    this.backColor = BattleBonusUpperColor.RED;

    this.addChild(this.base);

    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
    this.zIndex = Z_INDEX_BATTLE_BNS_UPPER_BACKGROUND_CONTAINER;
  }

  private initBattleBonusCharaBackAnimation(): void {
    this.battleBonusCharaBack = new SpineAnimation({}, PIXI.Loader.shared.resources.Battle_Start.spineData!);
    this.addChild(this.battleBonusCharaBack!.spine);
    this.battleBonusCharaBack.getSpine().pivot.set(BATTLE_BNS_UPPER_COLOR_POS_X, BATTLE_BNS_UPPER_COLOR_POS_Y);
  }

  private onModeChange(settings: { mode: GameMode }): void {
    this.initBattleBonusCharaBackAnimation();
    if (isBattleBonusMode(settings.mode)) {
      this.visible = true;

      const battleMode = setBattleBonusRounds()[0].mode;
      let table = backMode1[0];
      if (battleMode === BattleRoundMode.mode_1) {
        table = backMode1[0];
      } else if (battleMode === BattleRoundMode.mode_2) {
        table = backMode2[0];
      } else if (battleMode === BattleRoundMode.mode_3) {
        table = backMode3[0];
      } else if (battleMode === BattleRoundMode.mode_4) {
        table = backMode4[0];
      } else if (battleMode === BattleRoundMode.mode_5) {
        table = backMode5[0];
      }

      if (setNextResult() === null) {
        this.backColor = BattleBonusUpperColor.RED;
      } else {
        this.backColor = getBattleBonusUpperAreaColor(table, setNextResult()!.bet.id);
      }
      this.battleBonusCharaBack!.setAnimation('Battle_top_red', true);
      if (this.backColor === BattleBonusUpperColor.PURPLE) {
        const filter = new PIXI.filters.ColorMatrixFilter();
        filter.hue(300, true);
        this.battleBonusCharaBack!.getSpine().filters = [filter];
      } else if (this.backColor === BattleBonusUpperColor.RAINBOW) {
        this.startRainbow(true);
      }
      this.battleBonusCharaBack!.start();

      this.battleBonusCharaBack!.getSpine().visible = true;
    } else {
      this.visible = false;
      this.startRainbow(false);
      this.removeChildren();
      destroySpine(this.battleBonusCharaBack!);
      this.battleBonusCharaBack = undefined;
    }
  }

  public startRainbow(visibility: boolean): void {
    this.visible = visibility;
    if (visibility) {
      this.interval = setInterval(() => this.backColorRainbow(), 300);
    } else if (this.interval != null) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  private backColorRainbow(): void {
    const filter = new PIXI.filters.ColorMatrixFilter();
    filter.hue(this.color, true);
    this.battleBonusCharaBack!.getSpine().filters = [filter];
    this.color += 60;
    if (this.color > 360) {
      this.color = 0;
    }
  }

  private initBaseGraphic(): PIXI.Graphics {
    const bg = new PIXI.Graphics()
      .beginFill(0x000000)
      .drawRect(
        (SLOTS_CONTAINER_WIDTH - SLOTS_BACKGROUND_WIDTH) / 2,
        BATTLE_BNS_UPPER_BACKGROUND_POS_Y,
        BATTLE_BNS_UPPER_BACKGROUND_WIDTH,
        BATTLE_BNS_UPPER_BACKGROUND_HEIGHT,
      )
      .endFill();
    return bg;
  }
}

export default BattleBnsUpperBackgroundContainer;
