import { BattleBnsTitleProps } from '../../global.d';
import ViewContainer from '../components/container';
import { SLOTS_CONTAINER_WIDTH, Z_INDEX_BATTLE_BONUS_PANEL } from '../config';

import { BattleBonusCounter } from './battleBonusCounter';
import { FREE_SPINS_COUNTER_POS_Y } from './config';

export class BattleBonusPanel extends ViewContainer {
  private battleBnsSetCounter: BattleBonusCounter;

  constructor(props: BattleBnsTitleProps) {
    super();

    this.battleBnsSetCounter = new BattleBonusCounter(props);
    this.battleBnsSetCounter.y = FREE_SPINS_COUNTER_POS_Y;

    this.addChild(this.battleBnsSetCounter);

    if (this.battleBnsSetCounter.width > 1200) this.battleBnsSetCounter.width = 1200;

    this.position.set(SLOTS_CONTAINER_WIDTH / 2, -50);
    this.zIndex = Z_INDEX_BATTLE_BONUS_PANEL;
  }

  public destroy(options?: { children?: boolean; texture?: boolean; baseTexture?: boolean }): void {
    super.destroy(options);
  }
}
export default BattleBonusPanel;
