import i18n from 'i18next';
import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs } from '../../config';
import { EventTypes, GameMode, bonusIds } from '../../global.d';
import { setIsBuyFeaturePopupOpened, setIsBuyFeaturePurchased, setIsHyperBattleBonus } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { updateTextScale } from '../../utils/utils';
import {
  FEATURE_POPUP_CONFIRM_BATTLE_TITLE_POS_X,
  FEATURE_POPUP_CONFIRM_BATTLE_TITLE_POS_Y,
  FEATURE_POPUP_CONFIRM_CANCEL_BTN_POS_Y,
  FEATURE_POPUP_CONFIRM_OK_BTN_POS_Y,
  FEATURE_POPUP_CONFIRM_POSITION_X,
  FEATURE_POPUP_CONFIRM_POSITION_Y,
  FEATURE_POPUP_CONFIRM_POS_X,
  FEATURE_POPUP_CONFIRM_POS_Y,
  FEATURE_POPUP_CONFIRM_TITLE_POS_X,
  FEATURE_POPUP_CONFIRM_TITLE_POS_Y,
  FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POS_Y,
  FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POS_Y,
  GAME_CONTAINER_WIDTH,
  Z_INDEX_BUY_FEATURE_POPUP_CONFIRM,
  eventManager,
} from '../config';

import { betValueStyleConfirm, buyFeatureConfirmStyle, totalCostTextStyle } from './textStyles';

class BuyFeaturePopupConfirm extends PIXI.Container {
  private popupBg: PIXI.Sprite;

  private coinAmount: number;

  private titleText: PIXI.Text;

  private titleBattleText: PIXI.Text;

  private titleHyperBattleText: PIXI.Text;

  private targetBnsIcon: PIXI.Sprite;

  private totalCostText: PIXI.Text;

  private totalCostValue: PIXI.Text;

  private okBtn: PIXI.Sprite;

  private cancelBtn: PIXI.Sprite;

  constructor() {
    super();
    this.x = FEATURE_POPUP_CONFIRM_POS_X;
    this.y = FEATURE_POPUP_CONFIRM_POS_Y;

    this.visible = false;
    this.coinAmount = 1;
    this.popupBg = this.initPopupBg();
    this.titleText = this.initTitle();
    this.titleBattleText = this.initBattleTitle();
    this.titleHyperBattleText = this.initHyperBattleTitle();
    this.targetBnsIcon = this.initTargetBnsIcon();
    this.totalCostText = this.initTotalCostText();
    this.totalCostValue = this.initTotalCostValue();
    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();
    this.interactive = true;
    this.init();
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => this.handleClose());
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, () => {
      this.handleClose();
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP);
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP_BG);
    });
    eventManager.on(
      EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP,
      (isBattleBonus: boolean, totalCost: string, coinAmount: number) => {
        this.handleOpen(isBattleBonus, totalCost, coinAmount);
      },
    );
    eventManager.on(EventTypes.FORCE_CLOSE_BUYFEATURE, () => {
      setIsBuyFeaturePopupOpened(false);
      this.handleClose();
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP);
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP_BG);
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    });

    this.zIndex = Z_INDEX_BUY_FEATURE_POPUP_CONFIRM;
  }

  private init(): void {
    this.addChild(this.popupBg);
    this.addChild(this.titleText);
    this.addChild(this.titleBattleText);
    this.addChild(this.titleHyperBattleText);
    this.addChild(this.targetBnsIcon);
    this.addChild(this.totalCostText);
    this.addChild(this.totalCostValue);
    this.addChild(this.okBtn);
    this.addChild(this.cancelBtn);
  }

  private initPopupBg(): PIXI.Sprite {
    const popupBg = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeaturePopupConfirm));
    popupBg.x = FEATURE_POPUP_CONFIRM_POSITION_X;
    popupBg.y = FEATURE_POPUP_CONFIRM_POSITION_Y;

    return popupBg;
  }

  private initTitle(): PIXI.Text {
    const title = new PIXI.Text(i18n.t<string>('BuyBattle_ConfirmTitle'), buyFeatureConfirmStyle);
    title.resolution = 1;
    title.x = FEATURE_POPUP_CONFIRM_TITLE_POS_X;
    title.y = FEATURE_POPUP_CONFIRM_TITLE_POS_Y;
    title.anchor.set(0.5, 0.5);
    updateTextScale(title, isMobile ? 820 : 650, isMobile ? 250 : 250);

    return title;
  }

  private initBattleTitle(): PIXI.Text {
    const title = new PIXI.Text(i18n.t<string>('BuyBattle_ConfirmTitle_Battle_Bns'), buyFeatureConfirmStyle);
    title.resolution = 1;
    title.x = FEATURE_POPUP_CONFIRM_BATTLE_TITLE_POS_X;
    title.y = FEATURE_POPUP_CONFIRM_BATTLE_TITLE_POS_Y;
    title.anchor.set(0.5, 0.5);
    updateTextScale(title, isMobile ? 820 : 650, isMobile ? 250 : 250);

    return title;
  }

  private initHyperBattleTitle(): PIXI.Text {
    const title = new PIXI.Text(i18n.t<string>('BuyBattle_ConfirmTitle_Hyper_Battle_Bns'), buyFeatureConfirmStyle);
    title.resolution = 1;
    title.x = FEATURE_POPUP_CONFIRM_BATTLE_TITLE_POS_X;
    title.y = FEATURE_POPUP_CONFIRM_BATTLE_TITLE_POS_Y;
    title.anchor.set(0.5, 0.5);
    updateTextScale(title, isMobile ? 820 : 650, isMobile ? 250 : 250);

    return title;
  }

  private initTargetBnsIcon(): PIXI.Sprite {
    const targetIcon = setIsHyperBattleBonus() ? ResourceTypes.buyFeatureGold7 : ResourceTypes.buyFeatureRed7;
    const targetBnsIcon = new PIXI.Sprite(PIXI.Texture.from(targetIcon));
    targetBnsIcon.x =
      FEATURE_POPUP_CONFIRM_POSITION_X + this.popupBg.texture.width / 2 - targetBnsIcon.texture.width / 2;
    targetBnsIcon.y = 150;

    return targetBnsIcon;
  }

  private initTotalCostText(): PIXI.Text {
    const totalCostText = new PIXI.Text(i18n.t<string>('BuyBattle_TotalCost'), totalCostTextStyle);
    totalCostText.resolution = 1;
    totalCostText.y = FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POS_Y;
    totalCostText.x = FEATURE_POPUP_CONFIRM_POSITION_X + this.popupBg.texture.width / 2 - totalCostText.width / 2;

    return totalCostText;
  }

  private initTotalCostValue(): PIXI.Text {
    const totalCostValue = new PIXI.Text('0', betValueStyleConfirm);
    totalCostValue.resolution = 1;
    totalCostValue.y = FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POS_Y;
    totalCostValue.x = FEATURE_POPUP_CONFIRM_POSITION_X + this.popupBg.texture.width / 2 - totalCostValue.width / 2;

    return totalCostValue;
  }

  private initCancelBtn(): PIXI.Sprite {
    const cancelBtn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtn));
    cancelBtn.y = FEATURE_POPUP_CONFIRM_CANCEL_BTN_POS_Y;
    cancelBtn.x = FEATURE_POPUP_CONFIRM_POSITION_X + this.popupBg.texture.width / 2 - cancelBtn.texture.width - 100;
    cancelBtn.interactive = true;
    cancelBtn.on('click', () => {
      this.handleClose();
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP_BG);
      AudioHowl.play({ type: ISongs.SFX_UI_Close });
    });
    cancelBtn.on('touchstart', () => {
      this.handleClose();
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP_BG);
      AudioHowl.play({ type: ISongs.SFX_UI_Close });
    });

    cancelBtn.addListener('mouseover', () => {
      AudioHowl.play({ type: ISongs.SFX_UI_Hover });
      this.cancelBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtnHover);
    });
    cancelBtn.addListener('mouseout', () => {
      this.cancelBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtn);
    });
    cancelBtn.addListener('mousedown', () => {
      this.cancelBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtnPressed);
    });
    cancelBtn.addListener('mouseup', () => {
      this.cancelBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtn);
    });

    return cancelBtn;
  }

  private initOkBtn(): PIXI.Sprite {
    const okBtn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureOkBtn));
    okBtn.y = FEATURE_POPUP_CONFIRM_OK_BTN_POS_Y;
    okBtn.x = FEATURE_POPUP_CONFIRM_POSITION_X + this.popupBg.texture.width / 2 + 100;

    okBtn.interactive = true;
    okBtn.on('click', () => this.handleClickOk());
    okBtn.on('touchstart', () => this.handleClickOk());
    okBtn.addListener('mouseover', () => {
      AudioHowl.play({ type: ISongs.SFX_UI_Hover });
      this.okBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureOkBtnHover);
    });
    okBtn.addListener('mouseout', () => {
      this.okBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureOkBtn);
    });
    okBtn.addListener('mousedown', () => {
      this.okBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureOkBtnPressed);
    });
    okBtn.addListener('mouseup', () => {
      this.okBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureOkBtn);
    });

    return okBtn;
  }

  private handleClickOk(): void {
    const bnsType = setIsHyperBattleBonus()
      ? GameMode.BUY_FEATURE_HYPER_BATTLE_BONUS
      : GameMode.BUY_FEATURE_BATTLE_BONUS;

    this.okBtn.interactive = false;
    this.cancelBtn.interactive = false;
    AudioHowl.play({ type: ISongs.BuyButton });
    eventManager.emit(EventTypes.HANDLE_BUY_BONUS, bonusIds[bnsType]);
    setIsBuyFeaturePurchased(true);
  }

  public handleOpen(isBattleBonus: boolean, totalCost: string, coinAmount: number): void {
    this.visible = true;
    if (isBattleBonus) {
      this.titleBattleText.visible = true;
      this.titleHyperBattleText.visible = false;
    } else {
      this.titleBattleText.visible = false;
      this.titleHyperBattleText.visible = true;
    }
    this.coinAmount = coinAmount;
    this.totalCostValue.text = totalCost;
    this.totalCostValue.x = GAME_CONTAINER_WIDTH / 2 - this.totalCostValue.width / 2;
    this.okBtn.interactive = true;
    this.cancelBtn.interactive = true;

    this.totalCostValue.x =
      FEATURE_POPUP_CONFIRM_POSITION_X + this.popupBg.texture.width / 2 - this.totalCostValue.width / 2;

    const targetIcon = setIsHyperBattleBonus() ? ResourceTypes.buyFeatureGold7 : ResourceTypes.buyFeatureRed7;
    this.targetBnsIcon.texture = PIXI.Texture.from(targetIcon);
  }

  public handleClose(): void {
    eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_CONFIRM_POPUP);
    this.visible = false;
  }
}

export default BuyFeaturePopupConfirm;
