export enum ResourceTypes {
  bBiconHeroattackHigh = 'bBiconHeroattackHigh',
  bBiconHeroattackLow = 'bBiconHeroattackLow',
  bBiconHeroattackMed = 'bBiconHeroattackMed',
  bBiconHeroattackSp = 'bBiconHeroattackSp',
  bBiconRivalattack = 'bBiconRivalattack',
  bBiconBlank = 'bBiconBlank',
  reelFrameBb = 'reelFrameBb',
  reelFrameBase = 'reelFrameBase',
  messageBannerBb = 'messageBannerBb',
  messageBannerBbIcon = 'messageBannerBbIcon',
  logo = 'logo',
  reelBack = 'reelBack',
  blurSymbolDHero = 'blurSymbolDHero',
  blurSymbolEHeroine = 'blurSymbolEHeroine',
  blurSymbolFRival = 'blurSymbolFRival',
  blurSymbolGArms = 'blurSymbolGArms',
  blurSymbolHLow1 = 'blurSymbolHLow1',
  blurSymbolILow2 = 'blurSymbolILow2',
  blurSymbolJLow3 = 'blurSymbolJLow3',
  symbolAGold7 = 'symbolAGold7',
  symbolBRed7 = 'symbolBRed7',
  symbolCRespin = 'symbolCRespin',
  symbolDHero = 'symbolDHero',
  symbolEHeroine = 'symbolEHeroine',
  symbolFRival = 'symbolFRival',
  symbolGArms = 'symbolGArms',
  symbolGArmsRe = 'symbolGArmsRe',
  symbolHLow1 = 'symbolHLow1',
  symbolILow2 = 'symbolILow2',
  symbolJLow3 = 'symbolJLow3',
  backgroundBb = 'backgroundBb',
  backgroundBase = 'backgroundBase',
  battleResultBg = 'battleResultBg',
  battleResultBgSp = 'battleResultBgSp',
  spEd = 'spEd',
  backdrop = 'backdrop',
  buttonCancel = 'buttonCancel',
  buttonHistory = 'buttonHistory',
  buttonInfo = 'buttonInfo',
  buttonLobby = 'buttonLobby',
  buttonOk = 'buttonOk',
  buttonSetting = 'buttonSetting',
  buyFeatureGold7 = 'buyFeatureGold7',
  buyFeatureRed7 = 'buyFeatureRed7',
  buyFeatureBtnHover = 'buyFeatureBtnHover',
  buyFeatureBtnNotActive = 'buyFeatureBtnNotActive',
  buyFeatureBtnPressed = 'buyFeatureBtnPressed',
  buyFeatureBtn = 'buyFeatureBtn',
  buyFeatureCancelBtnHover = 'buyFeatureCancelBtnHover',
  buyFeatureCancelBtnPressed = 'buyFeatureCancelBtnPressed',
  buyFeatureCancelBtn = 'buyFeatureCancelBtn',
  buyFeatureInput = 'buyFeatureInput',
  buyFeatureMinusBtnHover = 'buyFeatureMinusBtnHover',
  buyFeatureMinusBtnNotActive = 'buyFeatureMinusBtnNotActive',
  buyFeatureMinusBtnPressed = 'buyFeatureMinusBtnPressed',
  buyFeatureMinusBtn = 'buyFeatureMinusBtn',
  buyFeatureOkBtnHover = 'buyFeatureOkBtnHover',
  buyFeatureOkBtnNotActive = 'buyFeatureOkBtnNotActive',
  buyFeatureOkBtnPressed = 'buyFeatureOkBtnPressed',
  buyFeatureOkBtn = 'buyFeatureOkBtn',
  buyFeaturePlusBtnHover = 'buyFeaturePlusBtnHover',
  buyFeaturePlusBtnNotActive = 'buyFeaturePlusBtnNotActive',
  buyFeaturePlusBtnPressed = 'buyFeaturePlusBtnPressed',
  buyFeaturePlusBtn = 'buyFeaturePlusBtn',
  buyFeaturePopupConfirm = 'buyFeaturePopupConfirm',
  buyFeaturePopup = 'buyFeaturePopup',
  buyFeatureBbBtnHover = 'buyFeatureBbBtnHover',
  buyFeatureBbBtnNotActive = 'buyFeatureBbBtnNotActive',
  buyFeatureBbBtnPressed = 'buyFeatureBbBtnPressed',
  buyFeatureBbBtn = 'buyFeatureBbBtn',
  buyFeatureHbbBtnHover = 'buyFeatureHbbBtnHover',
  buyFeatureHbbBtnNotActive = 'buyFeatureHbbBtnNotActive',
  buyFeatureHbbBtnPressed = 'buyFeatureHbbBtnPressed',
  buyFeatureHbbBtn = 'buyFeatureHbbBtn',
  infoBtnAutoplayStop = 'infoBtnAutoplayStop',
  infoBtnAutoplay = 'infoBtnAutoplay',
  infoBtnBetSettings = 'infoBtnBetSettings',
  infoBtnInfo = 'infoBtnInfo',
  infoBtnMenu = 'infoBtnMenu',
  infoBtnSound = 'infoBtnSound',
  infoBtnSpin = 'infoBtnSpin',
  infoBtnStop = 'infoBtnStop',
  infoBtnTurbo = 'infoBtnTurbo',
  infoPaylines = 'infoPaylines',
  infoPaylines2 = 'infoPaylines2',
  intro01 = 'intro01',
  intro02 = 'intro02',
  intro03 = 'intro03',
  intro04 = 'intro04',
  introBg = 'introBg',
  textLogo = 'textLogo',
}
