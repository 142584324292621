import * as PIXI from 'pixi.js';

import { BattleBnsTitleProps, EventTypes } from '../../global.d';
import i18n from '../../i18next';
import Animation from '../animations/animation';
import { createZoomAnimation } from '../animations/helper';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

import { FREE_SPIN_ANIMATION_DELAY, FREE_SPIN_ANIMATION_LOOP, FREE_SPIN_ANIMATION_SCALE } from './config';
import { spinsStyle, textStyle } from './textStyles';

export class BattleBonusCounter extends ViewContainer {
  private textContainer: ViewContainer;

  private titleText: PIXI.Text;

  private setText: PIXI.Text;

  private pulsAnimation: Animation | null = null;

  constructor(props: BattleBnsTitleProps) {
    super();

    this.textContainer = new ViewContainer();
    this.titleText = this.initTitleText(props.text);
    this.setText = this.initSetText(props.currentSet);

    this.init();

    eventManager.addListener(EventTypes.HANDLE_UPDATE_BATTLE_BNS_TITLE, this.handleUpdate.bind(this));
  }

  private init(): void {
    this.textContainer.name = 'text';
    this.textContainer.addChild(this.titleText);
    this.textContainer.addChild(this.setText);
    this.textContainer.pivot.set(this.textContainer.width / 2, this.textContainer.height);
    this.addChild(this.textContainer);
  }

  private initTitleText(titleText: string): PIXI.Text {
    const text = new PIXI.Text(i18n.t<string>(titleText), textStyle);
    text.resolution = 1;
    return text;
  }

  private initSetText(currentSet: number): PIXI.Text {
    const spinsText = new PIXI.Text(this.formatSpins(currentSet), spinsStyle);
    spinsText.resolution = 1;
    spinsText.anchor.set(0.5, 0.5);
    spinsText.position.set(this.titleText.width + spinsText.width / 2, spinsText.height / 2);

    return spinsText;
  }

  private handleUpdate(currentSet: number, immediately = false): void {
    this.setText.text = this.formatSpins(currentSet);

    if (immediately) return;

    this.pulsAnimation = createZoomAnimation(
      this.setText,
      FREE_SPIN_ANIMATION_SCALE,
      FREE_SPIN_ANIMATION_DELAY,
      FREE_SPIN_ANIMATION_LOOP,
    );
    this.pulsAnimation?.start();
  }

  private formatSpins(currentSet: number): string {
    return `${currentSet}`;
  }

  public destroy(options?: { children?: boolean; texture?: boolean; baseTexture?: boolean }): void {
    super.destroy(options);
    eventManager.removeListener(EventTypes.HANDLE_UPDATE_BATTLE_BNS_TITLE);
  }
}
