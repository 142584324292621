import _ from 'lodash';

import { AudioSprite } from '@phoenix7dev/play-music/dist/d';

export enum ISongs {
  BGM_BG_Loop = '010_01_BGM_BG_Loop',
  BGM_BB1_Intro = '010_02_BGM_BB1_Intro',
  BGM_BB1_Loop = '010_02_BGM_BB1_Loop',
  BGM_BB2_Loop = '010_03_BGM_BB2_Loop',
  RP1_Loop = '010_04_RP1_Loop',
  RP2_Loop = '010_05_RP2_Loop',
  RP3_Loop = '010_06_RP3_Loop',
  RP4_Loop = '010_07_RP4_Loop',
  RP_Stop = '010_08_RP_Stop',
  RP_Change = '010_09_RP_Change',
  RP1_Start = '010_10_RP1_Start',
  RP2_Start = '010_11_RP2_Start',
  RP3_Start = '010_12_RP3_Start',
  RP4_Start = '010_13_RP4_Start',
  RP_SE_Loop = '010_14_RP_SE_Loop',
  BonusStop_1 = '010_15_BonusStop_1',
  BonusStop_2 = '010_16_BonusStop_2',
  BonusStop_BB = '010_17_BonusStop_BB',
  BonusStop_HBB = '010_18_BonusStop_HBB',
  BB_Trigger = '010_19_BB_Trigger',
  HBB_Trigger = '010_20_HBB_Trigger',
  LongSpin = '010_21_LongSpin',
  Win_Loop = '010_22_Win_Loop',
  Win_End = '010_23_Win_End',
  BB_Banner = '010_24_BB_Banner',
  BB_Start = '010_25_BB_Start',
  BB_Push = '010_26_BB_Push',
  BB_Hero = '010_27_BB_Hero',
  BB_Rival = '010_28_BB_Rival',
  BB_Atk1 = '010_29_BB_Atk1',
  BB_Atk2 = '010_30_BB_Atk2',
  BB_Atk3 = '010_31_BB_Atk3',
  BB_Atk4 = '010_32_BB_Atk4',
  BB_Atk5 = '010_32_BB_Atk5',
  BB_Atk6 = '010_32_BB_Atk6',
  BB_Damage1 = '010_33_BB_Damage1',
  BB_Damage2 = '010_33_BB_Damage2',
  BB_Damage3 = '010_33_BB_Damage3',
  BB_End = '010_34_BB_End',
  Yocho_1 = '010_35_Yocho_1',
  Yocho_2 = '010_36_Yocho_2',
  Yocho_3 = '010_37_Yocho_3',
  Yocho_Phoenix = '010_38_Yocho_Phoenix',
  BuyButton = '010_39_BuyButton',
  SFX_BuyFeature = 'SFX_BuyFeature',
  BGM_BigWinCountLoop = '010_40_BGM_Bigwin_count_Loop',
  SFX_SM_CountUp_End = 'SFX_SM_CountUp_End',
  SFX_SM_CountUp_Loop = 'SFX_SM_CountUp_Loop',
  SFX_UI_AutoSpin = 'SFX_UI_AutoSpin',
  SFX_UI_BetChange = 'SFX_UI_BetChange',
  SFX_UI_Close = 'SFX_UI_Close',
  SFX_UI_General = 'SFX_UI_General',
  SFX_UI_Hover = 'SFX_UI_Hover',
  SFX_UI_MaxBet = 'SFX_UI_MaxBet',
  SFX_UI_MenuOpen = 'SFX_UI_MenuOpen',
  SFX_UI_SpinStart = 'SFX_UI_SpinStart',
  SFX_UI_SpinStop = 'SFX_UI_SpinStop',
  SFX_Win_Big = 'SFX_Win_Big',
  SFX_Win_Epic = 'SFX_Win_Epic',
  SFX_Win_Great = 'SFX_Win_Great',
  SFX_Win_Mega = 'SFX_Win_Mega',
}

export const audioSprite: AudioSprite = {
  [ISongs.BGM_BG_Loop]: [0, 73043.51473922902, true],
  [ISongs.BGM_BB1_Intro]: [75000, 3311.519274376423],
  [ISongs.BGM_BB1_Loop]: [80000, 13150.725623582772, true],
  [ISongs.BGM_BB2_Loop]: [95000, 10666.712018140586, true],
  [ISongs.RP1_Loop]: [107000, 7111.111111111114, true],
  [ISongs.RP2_Loop]: [116000, 6400.04535147392, true],
  [ISongs.RP3_Loop]: [124000, 6000, true],
  [ISongs.RP4_Loop]: [131000, 6857.188208616776, true],
  [ISongs.RP_Stop]: [139000, 1278.390022675751],
  [ISongs.RP_Change]: [142000, 3312.40362811792],
  [ISongs.RP1_Start]: [147000, 1451.7687074829837],
  [ISongs.RP2_Start]: [150000, 1584.4444444444434],
  [ISongs.RP3_Start]: [153000, 1912.086167800453],
  [ISongs.RP4_Start]: [156000, 3007.8911564625914],
  [ISongs.RP_SE_Loop]: [161000, 900.0453514739206, true],
  [ISongs.BonusStop_1]: [163000, 1376.5079365079487],
  [ISongs.BonusStop_2]: [166000, 2056.213151927437],
  [ISongs.BonusStop_BB]: [170000, 2002.5623582766343],
  [ISongs.BonusStop_HBB]: [174000, 5095.804988662138],
  [ISongs.BB_Trigger]: [181000, 879.841269841279],
  [ISongs.HBB_Trigger]: [183000, 2139.0022675736873],
  [ISongs.LongSpin]: [187000, 2506.757369614519],
  [ISongs.Win_Loop]: [191000, 399.7505668934309, true],
  [ISongs.Win_End]: [193000, 933.4693877551103],
  [ISongs.BB_Banner]: [195000, 10560.000000000002],
  [ISongs.BB_Start]: [207000, 3390.5215419501073],
  [ISongs.BB_Push]: [211000, 2150.0453514739206, true],
  [ISongs.BB_Hero]: [215000, 2528.594104308382],
  [ISongs.BB_Rival]: [219000, 1860.6802721088513],
  [ISongs.BB_Atk1]: [222000, 615.6689342403752],
  [ISongs.BB_Atk2]: [224000, 1144.8752834467086],
  [ISongs.BB_Atk3]: [227000, 1642.8344671201671],
  [ISongs.BB_Atk4]: [230000, 4284.920634920638],
  [ISongs.BB_Atk5]: [236000, 3517.6870748299225],
  [ISongs.BB_Atk6]: [241000, 2576.893424036285],
  [ISongs.BB_Damage1]: [248000, 1301.1791383219986],
  [ISongs.BB_Damage2]: [251000, 1366.4172335600995],
  [ISongs.BB_Damage3]: [254000, 1605.5555555555543],
  [ISongs.BB_End]: [257000, 7636.39455782311],
  [ISongs.Yocho_1]: [266000, 1743.6054421768574],
  [ISongs.Yocho_2]: [269000, 655.3514739229058],
  [ISongs.Yocho_3]: [271000, 2431.9274376417184],
  [ISongs.Yocho_Phoenix]: [275000, 4033.33333333336],
  [ISongs.BuyButton]: [281000, 499.3197278911339],
  [ISongs.BGM_BigWinCountLoop]: [283000, 12972.970521541924, true],
  [ISongs.SFX_BuyFeature]: [297000, 666.6666666666856],
  [ISongs.SFX_SM_CountUp_End]: [299000, 933.4693877551103],
  [ISongs.SFX_SM_CountUp_Loop]: [301000, 399.7505668934309],
  [ISongs.SFX_UI_AutoSpin]: [303000, 320.47619047619946],
  [ISongs.SFX_UI_BetChange]: [305000, 32.08616780045759],
  [ISongs.SFX_UI_Close]: [307000, 200.68027210885475],
  [ISongs.SFX_UI_General]: [309000, 88.34467120180989],
  [ISongs.SFX_UI_Hover]: [311000, 151.76870748297233],
  [ISongs.SFX_UI_MaxBet]: [313000, 122.92517006801518],
  [ISongs.SFX_UI_MenuOpen]: [315000, 277.1428571428487],
  [ISongs.SFX_UI_SpinStart]: [317000, 249.59183673468033],
  [ISongs.SFX_UI_SpinStop]: [319000, 151.02040816327644],
  [ISongs.SFX_Win_Big]: [321000, 1622.3356009070358],
  [ISongs.SFX_Win_Epic]: [324000, 1862.2675736961583],
  [ISongs.SFX_Win_Great]: [327000, 2118.390022675726],
  [ISongs.SFX_Win_Mega]: [331000, 1696.1678004535088],
};

export const audioSpriteVolume: Record<ISongs, number> = {
  [ISongs.BGM_BG_Loop]: 0.3,
  [ISongs.BGM_BB1_Intro]: 0.3,
  [ISongs.BGM_BB1_Loop]: 0.3,
  [ISongs.BGM_BB2_Loop]: 0.3,
  [ISongs.RP1_Loop]: 0.3,
  [ISongs.RP2_Loop]: 0.3,
  [ISongs.RP3_Loop]: 0.3,
  [ISongs.RP4_Loop]: 0.3,
  [ISongs.RP_Stop]: 1.0,
  [ISongs.RP_Change]: 0.3,
  [ISongs.RP1_Start]: 0.9,
  [ISongs.RP2_Start]: 0.9,
  [ISongs.RP3_Start]: 0.9,
  [ISongs.RP4_Start]: 0.9,
  [ISongs.RP_SE_Loop]: 0.6,
  [ISongs.BonusStop_1]: 0.3,
  [ISongs.BonusStop_2]: 0.3,
  [ISongs.BonusStop_BB]: 0.3,
  [ISongs.BonusStop_HBB]: 0.3,
  [ISongs.BB_Trigger]: 0.9,
  [ISongs.HBB_Trigger]: 0.9,
  [ISongs.LongSpin]: 0.3,
  [ISongs.Win_Loop]: 0.3,
  [ISongs.Win_End]: 0.3,
  [ISongs.BB_Banner]: 0.3,
  [ISongs.BB_Start]: 0.3,
  [ISongs.BB_Push]: 0.3,
  [ISongs.BB_Hero]: 0.5,
  [ISongs.BB_Rival]: 0.9,
  [ISongs.BB_Atk1]: 0.6,
  [ISongs.BB_Atk2]: 0.6,
  [ISongs.BB_Atk3]: 0.6,
  [ISongs.BB_Atk4]: 0.6,
  [ISongs.BB_Atk5]: 0.6,
  [ISongs.BB_Atk6]: 0.6,
  [ISongs.BB_Damage1]: 0.9,
  [ISongs.BB_Damage2]: 0.9,
  [ISongs.BB_Damage3]: 0.6,
  [ISongs.BB_End]: 0.3,
  [ISongs.Yocho_1]: 0.3,
  [ISongs.Yocho_2]: 0.3,
  [ISongs.Yocho_3]: 0.3,
  [ISongs.Yocho_Phoenix]: 0.9,
  [ISongs.BuyButton]: 0.9,
  [ISongs.BGM_BigWinCountLoop]: 0.2,
  [ISongs.SFX_BuyFeature]: 0.3,
  [ISongs.SFX_SM_CountUp_End]: 0.3,
  [ISongs.SFX_SM_CountUp_Loop]: 0.3,
  [ISongs.SFX_UI_AutoSpin]: 0.3,
  [ISongs.SFX_UI_BetChange]: 0.3,
  [ISongs.SFX_UI_Close]: 0.3,
  [ISongs.SFX_UI_General]: 0.3,
  [ISongs.SFX_UI_Hover]: 0.3,
  [ISongs.SFX_UI_MaxBet]: 0.3,
  [ISongs.SFX_UI_MenuOpen]: 0.3,
  [ISongs.SFX_UI_SpinStart]: 0.3,
  [ISongs.SFX_UI_SpinStop]: 0.3,
  [ISongs.SFX_Win_Big]: 0.3,
  [ISongs.SFX_Win_Epic]: 0.3,
  [ISongs.SFX_Win_Great]: 0.3,
  [ISongs.SFX_Win_Mega]: 0.3,
};

export const audioSpriteGaps: { [key in ISongs]?: number } = {
  // [ISongs.Music_BigWin_End]: 2000,
};

export const mappedAudioSprites = _.reduce(
  audioSprite,
  (acc, value, key) => {
    _.set(acc, key, {
      start: value[0],
      duration: value[1] - (audioSpriteGaps[key as ISongs] || 0),
      loop: !!value[2],
    });
    return acc;
  },
  {},
) as {
  [key in ISongs]: { start: number; duration: number; loop: boolean };
};
