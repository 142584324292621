import * as PIXI from 'pixi.js';

import SlotMachine from '..';
import { EventTypes, GameMode } from '../../global.d';
import { setGameMode } from '../../gql/cache';
import { destroySpine, isReSpinMode } from '../../utils';
import SpineAnimation from '../animations/spine';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

class NearmissRespin extends ViewContainer {
  private nearmissRespin: SpineAnimation | undefined;

  constructor() {
    super();
    this.sortableChildren = true;
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.startSpin.bind(this));

    eventManager.addListener(EventTypes.REELS_STOPPED, this.onReelsStopped.bind(this));
  }

  private initRespinAnimation(): void {
    this.nearmissRespin = new SpineAnimation({}, PIXI.Loader.shared.resources.Nearmiss_Respin.spineData!);
    this.nearmissRespin.addOnStart(() => {
      this.addChild(this.nearmissRespin!.spine);
    });
    this.nearmissRespin.getSpine().pivot.set(0);
  }

  public respinAnimationStart(): void {
    if (this.nearmissRespin === undefined) {
      this.initRespinAnimation();
      this.nearmissRespin!.setAnimation(this.getNearmissSpain(), true);
      this.nearmissRespin!.getSpine().position.set(620, 350);
      this.nearmissRespin!.start();
    }
  }

  private startSpin(): void {
    if (isReSpinMode(setGameMode())) {
      if (this.nearmissRespin === undefined) {
        this.initRespinAnimation();
      }
      this.nearmissRespin!.setAnimation(this.getNearmissSpain(), true);
      this.nearmissRespin?.getSpine().position.set(620, 350);
      this.nearmissRespin!.start();
    }
  }

  private onReelsStopped(): void {
    if (!SlotMachine.getInstance().getReSpinBonus()) {
      if (this.nearmissRespin != undefined) {
        this.nearmissRespin!.end();
        destroySpine(this.nearmissRespin!);
        this.nearmissRespin = undefined;
      }
    }
  }

  private getNearmissSpain(): string {
    let rtn = 'Nearmiss_Respin';

    switch (setGameMode()) {
      case GameMode.RESPIN_1:
        rtn = 'Nearmiss_Respin';
        break;
      case GameMode.RESPIN_2:
        rtn = 'Nearmiss_Respin2';
        break;
      case GameMode.RESPIN_3:
        rtn = 'Nearmiss_Respin3';
        break;
      case GameMode.RESPIN_4:
        rtn = 'Nearmiss_Respin4';
        break;
      default:
        break;
    }

    return rtn;
  }
}
export default NearmissRespin;
