// eslint-disable-next-line
import * as PIXI from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import { destroySpine } from '../../utils';
import SpineAnimation from '../animations/spine';
import ViewContainer from '../components/container';
import { REEL_WIDTH, SLOT_HEIGHT, eventManager } from '../config';

class ArmsReChange extends ViewContainer {
  private armsReChange: SpineAnimation | undefined;

  constructor() {
    super();
    this.initArmsReChangeAnimation();
    this.sortableChildren = true;

    eventManager.addListener(EventTypes.ARMS_RE_CHANGE, this.armsReChangeStart.bind(this));
  }

  private initArmsReChangeAnimation(): void {
    this.armsReChange = new SpineAnimation({}, PIXI.Loader.shared.resources.symbol_all.spineData!);
    this.armsReChange.addOnStart(() => {
      this.addChild(this.armsReChange!.spine);
    });
    this.armsReChange.getSpine().pivot.set(0);
  }

  private armsReChangeStart(): void {
    this.initArmsReChangeAnimation();
    this.armsReChange!.setAnimation('Symbol_G_ArmsRe_change', false);
    this.armsReChange?.getSpine().position.set(REEL_WIDTH / 2 + REEL_WIDTH, SLOT_HEIGHT / 2 + SLOT_HEIGHT);

    this.armsReChange!.start();
    AudioHowl.play({ type: ISongs.RP_Change });
    this.armsReChange!.addOnComplete(() => {
      destroySpine(this.armsReChange!);
      // setTimeout(() => {
      //   eventManager.emit(EventTypes.CREATE_RESPIN_MESSAGE);
      // }, 0);
    });
  }
}
export default ArmsReChange;
